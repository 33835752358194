import client, {
    adminClient,
    authAdminClient,
    authClient,
    explorerClient,
} from 'helper/httpClient';
import {get, isNil} from 'lodash';
import {useAuthStore} from 'stores/authStore';
import {cleanObjectNilValue} from 'utils/clean';
import {useLocale} from './useLocale';
import {some} from 'lodash';
import {useUIStore} from 'stores/uiStore';
import {saleClient} from 'helper/httpClient';
import appConfig from 'config/app';

export const isPlatformAdminRole = (roles) => {
    return (
        roles.includes('PLATFORM_ADMIN') ||
        roles.includes('PLATFORM_DEALER') ||
        roles.includes('PLATFORM_SALE') ||
        roles.includes('PLATFORM_TREASURY') ||
        roles.includes('PLATFORM_SUPPORT') ||
        roles.includes('PLATFORM_FINANCE') ||
        roles.includes('PLATFORM_MARKETING') ||
        roles.includes('PLATFORM_RISK_AND_COMPLIANCE')||
        roles.includes('PLATFORM_READONLY')
    );
};

export const isUserRole = (roles) => {
    return (
        roles.includes('CLIENT_USER') ||
        roles.includes('CLIENT_COMPANY_ADMIN') ||
        roles.includes('CLIENT_COMPANY_OPERATOR') ||
        roles.includes('CLIENT_COMPANY_CONTACT') ||
        roles.includes('CLIENT_COMPANY_READONLY')
    );
};


export const useAuth = () => {
    const state = useAuthStore((state) => state);
    const {locale} = useLocale();
    const isAdmin = get(state, 'user.roles', []).includes('PLATFORM_ADMIN');
    const isDealer = get(state, 'user.roles', []).includes('PLATFORM_DEALER');
    const isSale = get(state, 'user.roles', []).includes('PLATFORM_SALE');
    const isTreasury = get(state, 'user.roles', []).includes(
        'PLATFORM_TREASURY',
    );
    const isSupport = get(state, 'user.roles', []).includes(
        'PLATFORM_SUPPORT',
    );
    const isFinance = get(state, 'user.roles', []).includes('PLATFORM_FINANCE');
    const isMarketing = get(state, 'user.roles', []).includes(
        'PLATFORM_MARKETING',
    );
    const isRiskAndCompliance = get(state, 'user.roles', []).includes(
        'PLATFORM_RISK_AND_COMPLIANCE',
    );
    const isPlatformReadOnly = get(state, 'user.roles', []).includes(
        'PLATFORM_READONLY',
    );
    const isUser = isUserRole(get(state, 'user.roles', []))
    const [setFirstLanguageCounter] = useUIStore((state) => [
        state.setFirstLanguageCounter,
    ]);
    return {
        ...state,
        id: get(state, 'user.id') || null,
        roles: get(state, 'user.roles') || [],
        clientRoles: get(state, 'user.roles', []),
        clientId: get(state, 'user.clientId') || null,
        isAdmin,
        isDealer,
        isSale,
        isUser,
        isTreasury,
        isSupport,
        isFinance,
        isMarketing,
        isRiskAndCompliance,
        isPlatformReadOnly,
        isAdminPage:
            isAdmin ||
            isDealer ||
            isSale ||
            isTreasury ||
            isSupport ||
            isFinance ||
            isMarketing ||
            isRiskAndCompliance ||
            isPlatformReadOnly,
        isLoggedIn: Boolean(get(state, 'token')),
        login: async ({username, password, issued, code, method}) => {
            try {
                let requestClient = authClient;
                if (isPlatformAdminRole(appConfig.appPermissionControl)) {
                    requestClient = authAdminClient;
                }
                const {result: auth} = await requestClient.post(
                    ['login'],
                    cleanObjectNilValue({
                        username,
                        password,
                        issued,
                        code,
                        method,
                    }),
                );

                if (auth?.issueId) {
                    return auth;
                }

                if (
                    !some(get(auth, 'user.roles', []), (rule) =>
                        appConfig.appPermissionControl.includes(rule),
                    )
                ) {
                    throw new Error('permissionDenied');
                }
                const isAdmin = isPlatformAdminRole(get(auth, 'user.roles'));
                const isUser = isUserRole(get(auth, 'user.roles'));

                if (isUser) {
                    client.onSending = (req) => {
                        req.set(
                            'Authorization',
                            `bearer ${get(auth, 'token')}`,
                        );
                    };
                    explorerClient.onSending = (req) => {
                        req.set(
                            'Authorization',
                            `bearer ${get(auth, 'token')}`,
                        );
                    };
                }
                if (isAdmin) {
                    adminClient.onSending = (req) => {
                        req.set(
                            'Authorization',
                            `Bearer ${get(auth, 'token')}`,
                        );
                    };
                }
                // if (isSale) {
                //     saleClient.onSending = (req) => {
                //         req.set(
                //             'Authorization',
                //             `bearer ${get(auth, 'token')}`,
                //         );
                //     };
                // }
                state.setToken(get(auth, 'token'));
                state.setUser(get(auth, 'user'));
                return auth;
            } catch (error) {
                throw error;
            }
        },
        logout: (noRedirect) => {
            state.logout();
            delete client.onSending;
            delete adminClient.onSending;
            setFirstLanguageCounter(1);
        },
        register: async ({
            region,
            email,
            phoneCode,
            phoneNo,
            type,
            password,
            referralReference,
        }) => {
            const {result} = await authClient.post(
                ['register'],
                cleanObjectNilValue({
                    region,
                    email,
                    phoneCode,
                    phoneNo,
                    type,
                    password,
                    referralReference,
                    locale,
                }),
            );
            return result;
        },
        init: () => {
            if (state.token) {
                client.onSending = (req) => {
                    req.set('Authorization', `bearer ${state.token}`);
                };
            }
        },
        verify: async ({method, issueId, code}) => {
            let requestClient = authClient;
            if (process.env.REACT_APP_PERMISSION_CONTROL) {
                if (isPlatformAdminRole(appConfig.appPermissionControl)) {
                    requestClient = authAdminClient;
                }
            }
            const {result: auth} = await requestClient.post(
                ['verify'],
                cleanObjectNilValue({
                    issueId,
                    code,
                    method,
                }),
            );
            if (process.env.REACT_APP_PERMISSION_CONTROL) {
                const permissions =
                    process.env.REACT_APP_PERMISSION_CONTROL.split(',');
                if (
                    !some(get(auth, 'user.roles', []), (rule) =>
                        permissions.includes(rule),
                    )
                ) {
                    throw new Error('permissionDenied');
                }
            }
            // const isSale = get(auth, 'user.roles', []).includes('SALE');
            if (isUserRole(get(auth, 'user.roles'))) {
                client.onSending = (req) => {
                    req.set('Authorization', `bearer ${get(auth, 'token')}`);
                };
                explorerClient.onSending = (req) => {
                    req.set('Authorization', `bearer ${get(auth, 'token')}`);
                };
            }
            if (isPlatformAdminRole(get(auth, 'user.roles'))) {
                adminClient.onSending = (req) => {
                    req.set('Authorization', `Bearer ${get(auth, 'token')}`);
                };
            }

            // if (isSale) {
            //     saleClient.onSending = (req) => {
            //         req.set('Authorization', `bearer ${get(auth, 'token')}`);
            //     };
            // }
            state.setToken(get(auth, 'token'));
            state.setUser(get(auth, 'user'));
            return auth;
        },
    };
};
