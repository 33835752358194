import { IconButton, useColorMode } from '@chakra-ui/react';
import React from 'react';
import {HiMoon, HiSun} from 'react-icons/hi';

const ColorModeBtn = (props) => {
    const {colorMode, toggleColorMode} = useColorMode();
    return (
        <IconButton
            aria-label="color mode"
            icon={colorMode === 'light' ? <HiMoon /> : <HiSun />}
            onClick={toggleColorMode}
            variant={'ghost'}
            border={'1px'}
            borderColor={'gray.100'}
        />
    );
};

export default ColorModeBtn;
