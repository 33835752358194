import {isNil} from 'lodash';
import create from 'zustand';
import {persist} from 'zustand/middleware';

export const useUIStore = create((set, get) => ({
    headerTitle: null,
    headerDesc: null,
    lastBreadcrumbText: null,
    unauthLogout: false,
    isOffline: false,
    showMobileDrawer: false,
    firstLanguageCounter: 1,
    editingClientKyc: false,
    liveMode: true,
    sideBarOpen: true,
    setSideBarOpen: (value) => {
        set(() => ({sideBarOpen: value}));
    },
    setFirstLanguageCounter: (value) => {
        set(() => ({firstLanguageCounter: value}));
    },
    setHeaderTitle: (value) => {
        set(() => ({headerTitle: value}));
    },
    clearHeaderTitle: (value) => {
        if (!isNil(get().headerTitle)) {
            set(() => ({headerTitle: null}));
        }
    },
    setHeaderDesc: (value) => {
        set(() => ({headerDesc: value}));
    },
    clearHeaderDesc: (value) => {
        if (!isNil(get().headerDesc)) {
            set(() => ({headerDesc: null}));
        }
    },
    clearLastBreadcrumbText: (value) => {
        if (!isNil(get().lastBreadcrumbText)) {
            set(() => ({lastBreadcrumbText: null}));
        }
    },
    setUnauthLogout: (value) => set(() => ({unauthLogout: value})),
    setIsOffline: (value) => set(() => ({isOffline: value})),
    setShowMobileDrawer: (value) => set(() => ({showMobileDrawer: value})),
    setEditingClientKyc: (value) => set(() => ({editingClientKyc: value})),
    setLiveMode: (value) => set(() => ({liveMode: value})),
    setLastBreadcrumbText: (value) => set(() => ({lastBreadcrumbText: value})),
}));
