import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useColorModeValueKey} from 'hooks/useColors';

// import zhCN from 'date-fns/locale/zh-CN';
// import zhTW from 'date-fns/locale/zh-TW';
// import enUS from 'date-fns/locale/en-US';
import DateField from './DateField';
import {parseDate, parseDateTime} from '@internationalized/date';
import {format} from 'date-fns';
import {isDate} from 'lodash';
import {
    Box,
    Button,
    HStack,
    IconButton,
    Popover,
    PopoverAnchor,
    PopoverBody,
    PopoverContent,
    Stack,
    Text,
    PopoverTrigger,
} from '@chakra-ui/react';
import {I18nProvider, useButton, useDatePicker, useDialog} from 'react-aria';
import {useDatePickerState} from 'react-stately';
import Calendar from './Calander';
import {AriaPopover} from './AriaPopover';
import {HiCalendar} from 'react-icons/hi2';

const Dialog = ({title, children, ...props}) => {
    const ref = React.useRef();
    const {dialogProps} = useDialog(props, ref);

    return (
        <div {...dialogProps} ref={ref}>
            {children}
        </div>
    );
};

const DateSelector = (props) => {
    const {
        isDisabled,
        value,
        onChangeValue,
        flex = 1,
        placeholder,
        minDate,
        maxDate,
        variant = 'outlined',
        InputLeftElement,
        granularity,
        h = '2rem',
    } = props;
    const {t, i18n} = useTranslation('app');
    const formaterCalanderDate = (date) => {
        return parseDateTime(format(date, "yyyy-MM-dd'T'HH:mm:ss"));
    };
    const ref = useRef();
    const buttonRef = useRef();
    const processedProps = {
        ...props,
        value: value ? formaterCalanderDate(value) : null,
        onChange: (date) => {
            if (date?.toDate()) {
                if (date.year >= 1) {
                    onChangeValue(date.toDate());
                }
            } else {
                onChangeValue(null);
            }
        },
        minValue:
            minDate && isDate(minDate) ? formaterCalanderDate(minDate) : null,
        maxValue:
            maxDate && isDate(maxDate) ? formaterCalanderDate(maxDate) : null,
    };
    const state = useDatePickerState({
        ...processedProps,
        shouldCloseOnSelect: false,
    });
    const {
        groupProps,
        labelProps,
        fieldProps,
        buttonProps: nButtonProps,
        dialogProps,
        calendarProps,
    } = useDatePicker(processedProps, state, ref);

    const {buttonProps} = useButton(nButtonProps, buttonRef);

    return (
        <HStack flex={flex}>
            <Popover
                placement="bottom-start"
                isOpen={state.isOpen}
                onClose={state.close}
                isLazy
                autoFocus={false}
            >
                <PopoverAnchor>
                    <Stack flex={1} ref={ref} h={h}>
                        <DateField
                            {...fieldProps}
                            InputRightElement={
                                <Box pr={1} mb={'2px'}>
                                    <IconButton
                                        {...buttonProps}
                                        size="xs"
                                        ref={buttonRef}
                                        isDisabled={isDisabled}
                                        icon={<HiCalendar boxSize={4} />}
                                    />
                                </Box>
                            }
                            InputLeftElement={InputLeftElement}
                            variant={variant}
                            isDisabled={isDisabled}
                        />
                    </Stack>
                </PopoverAnchor>
                <PopoverContent
                    width={{base: 320}}
                    py={2}
                    overflow={'hidden'}
                    boxShadow={'lg'}
                    borderColor={useColorModeValueKey('border')}
                >
                    <PopoverBody>
                        <I18nProvider locale={i18n.language}>
                            <Calendar
                                {...calendarProps}
                                isOpen={state.isOpen}
                            />
                        </I18nProvider>
                        <HStack
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mt={2}
                        >
                            <Button
                                variant={'ghost'}
                                size={'xs'}
                                onClick={state.close}
                            >
                                {t('close')}
                            </Button>
                            <Button
                                variant={'ghost'}
                                size={'xs'}
                                onClick={() => {
                                    state.setValue(null);
                                    onChangeValue(null);
                                }}
                            >
                                {t('clear')}
                            </Button>
                        </HStack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>

            {/* {state.isOpen && (
                <AriaPopover
                    triggerRef={ref}
                    state={state}
                    placement="bottom start"
                >
                    <Dialog {...dialogProps}>
                        <I18nProvider locale={i18n.language}>
                            <Calendar {...calendarProps} />
                        </I18nProvider>
                        <HStack
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            mt={2}
                        >
                            <Button
                                variant={'ghost'}
                                size={'xs'}
                                onClick={state.close}
                            >
                                {t('close')}
                            </Button>
                            <Button
                                variant={'ghost'}
                                size={'xs'}
                                onClick={() => {
                                    state.setValue(null);
                                    onChangeValue(null);
                                }}
                            >
                                {t('clear')}
                            </Button>
                        </HStack>
                    </Dialog>
                </AriaPopover>
            )} */}
        </HStack>
    );
};

export default DateSelector;
