import {useColorModeValue, useToken} from '@chakra-ui/react';
import {designSystemColors} from 'theme';
import tinycolor from 'tinycolor2';

export const useColorModeValueKey = (key) =>
    useColorModeValue(
        `light.${designSystemColors.light[key]}`,
        `dark.${designSystemColors.dark[key]}`,
    );

export const useReadableTextColor = (backgroundColor) => {
    const lightText = useToken(
        'colors',
        `light.${designSystemColors.light.text}`,
    );
    const darkText = useToken(
        'colors',
        `light.${designSystemColors.dark.text}`,
    );
    const lightReadability = tinycolor.readability(
        lightText,
        backgroundColor,
    );
    const darkReadability = tinycolor.readability(darkText, backgroundColor);
    return lightReadability > darkReadability ? lightText : darkText;
};
