import {
    Box,
    DarkMode,
    Divider,
    Flex,
    HStack,
    Heading,
    Icon,
    IconButton,
    LightMode,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import {useAutoLogout} from 'hooks/useAutoLogout';
import {useColorModeValueKey} from 'hooks/useColors';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet, useMatches} from 'react-router-dom';
import {useUIStore} from 'stores/uiStore';
import {HiOutlineMenu} from 'react-icons/hi';
import {get, last, split, trim} from 'lodash';
import LanguageBtn from 'components/LanguageBtn';
import ColorModeBtn from 'components/ColorModeBtn';
import NotificationBtn from 'components/NotificationBtn';
import ProfileBtn from 'components/ProfileBtn';
import CustomBreadcrumb from 'components/CustomBreadcrumb';
import {AppLogoArea, MobileNavDrawer, NavDrawer} from './NavBar';
import {CgArrowsExchangeAlt} from 'react-icons/cg';
import {designSystemColors} from 'theme';
import {motion} from 'framer-motion';

const MotionStack = motion(Stack);


const Header = () => {
    const {t} = useTranslation('app');
    const [headerTitle, headerDesc, showMobileDrawer, setShowMobileDrawer] =
        useUIStore((state) => [
            state.headerTitle,
            state.headerDesc,
            state.showMobileDrawer,
            state.setShowMobileDrawer,
        ]);
        
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    const grayColor = useColorModeValueKey('gray');
    const matches = useMatches();
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath, '/'), '/'));
    const defaultHeaderTitle = t(mainPath);

    return (
        <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            px={{base: 2, md: 4}}
            py={2}
            bg={useColorModeValueKey('navBg')}
            transition={'background-color 200ms linear'}
            zIndex={2}
            shadow={'sm'}
        >
            <HStack alignItems={'center'} position="relative" flex={1}>
                {isMobile ? (
                    <HStack>
                        <IconButton
                            onClick={() => setShowMobileDrawer(true)}
                            icon={<HiOutlineMenu size={'24px'} />}
                            variant={'ghost'}
                        />
                        <Heading
                            fontWeight={'600'}
                            fontSize={'lg'}
                            textAlign="center"
                            flex={1}
                        >
                            {headerTitle ? headerTitle : defaultHeaderTitle}
                        </Heading>
                    </HStack>
                ) : (
                    <Stack spacing={-0.5}>
                        <Heading fontWeight={'600'} fontSize={'lg'}>
                            {headerTitle ? headerTitle : defaultHeaderTitle}
                        </Heading>
                        {headerDesc ? (
                            <Text color={grayColor} fontSize="xs">
                                {headerDesc}
                            </Text>
                        ) : (
                            <CustomBreadcrumb />
                        )}
                    </Stack>
                )}
            </HStack>
            <HStack alignItems={'center'} spacing={2}>
                <NotificationBtn />
                {!isMobile && (
                    <HStack alignItems={'center'} spacing={2}>
                        <LanguageBtn />
                        <ColorModeBtn />
                    </HStack>
                )}
                {!isMobile && (
                    <Divider h={'36px'} orientation="vertical" pl={2} />
                )}
                <ProfileBtn />
            </HStack>
        </HStack>
    );
};

const NavBar = () => {
    const [sideBarOpen, setSideBarOpen] = useUIStore((state) => [
        state.sideBarOpen,
        state.setSideBarOpen,
    ]);
    return (
        <MotionStack
            animate={{
                width: sideBarOpen ? '240px' : '64px',
                minWidth: sideBarOpen ? '240px' : '64px',
            }}
            initial={false}
            bg={useColorModeValueKey('sideBarBg')}
            transition={'background-color width max-width 200ms linear'}
            spacing={2}
            zIndex={3}
            pt={1}
            shadow={'sideBar'}
            position={'relative'}
        >
            <AppLogoArea collapsed={sideBarOpen} />
            <NavDrawer />
            <IconButton
                position={'absolute'}
                top={0}
                right={-3}
                zIndex={3}
                size="xs"
                shadow={'lg'}
                rounded={'full'}
                bg={'light.bg.50'}
                _hover={{
                    bg: 'light.bg.100',
                }}
                icon={
                    <Icon
                        boxSize={'4'}
                        as={CgArrowsExchangeAlt}
                        color={designSystemColors.light.text}
                    />
                }
                onClick={() => setSideBarOpen(!sideBarOpen)}
            />
        </MotionStack>
    );
};

const AppLayout = () => {
    useAutoLogout();
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });
    return (
        <HStack alignItems={'stretch'} h={'100vh'} w="100vw" spacing={0}>
            {isMobile ? (
                <MobileNavDrawer />
            ) : (
                <DarkMode>
                    <NavBar />
                </DarkMode>
            )}
            <Stack
                flex={1}
                bg={useColorModeValueKey('bg')}
                transition={'background-color 200ms linear'}
                spacing={0}
            >
                <Header />
                <Box overflow={'scroll'} zIndex={1}>
                    <Stack
                        p={{base: 2, md: 4}}
                        py={4}
                        mx="auto"
                        maxW="1200px"
                        overflow={'scroll'}
                    >
                        <Outlet />
                    </Stack>
                </Box>
            </Stack>
        </HStack>
    );
};

export const StretchAppContnetArea = (props) => {
    return (
        <Stack m={{base: -2, md: -4}} my={-2}>
            {props.children}
        </Stack>
    );
};

export default AppLayout;
