import {useClient, useProfile} from 'hooks/datahook/auth';
import {useColorModeValueKey, useReadableTextColor} from 'hooks/useColors';
import {get} from 'lodash';
import BrochureDrawer from 'components/BrochureDrawer';
import React, {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {paths} from 'routes/path';
import {useUIStore} from 'stores/uiStore';
// import ApplyIcon from '../components/common/BaseIcon/ApplyIcon';
import {useAuth} from 'hooks/useAuth';
import {useClientPermissionSettings} from 'hooks/clientPermission';
import {
    Avatar,
    Box,
    HStack,
    Icon,
    IconButton,
    Stack,
    Text,
    Tooltip,
    useToken,
} from '@chakra-ui/react';

import {motion} from 'framer-motion';
import {HiStatusOffline, HiStatusOnline, HiQrcode} from 'react-icons/hi';
import {HiShieldCheck, HiShieldExclamation} from 'react-icons/hi2';

const MotionBox = motion(Box);

export const NavBarItem = (props) => {
    const {} = props;
    const {t} = useTranslation('app');
    const {data: profile} = useProfile();
    const {
        data: client,
        kycVerified,
        kycPending,
        kycRequired,
        isCompany,
        isIB,
    } = useClient();

    const {clientRoles, isBrokerAdminPage} = useAuth();
    const [liveMode, sideBarOpen] = useUIStore((state) => [
        state.liveMode,
        state.sideBarOpen,
    ]);
    const sideBarBg = useToken('colors', useColorModeValueKey('sideBarBg'));
    const successColor = useColorModeValueKey('success');
    const textColor = useReadableTextColor(sideBarBg);
    const warningColor = useColorModeValueKey('warning');
    const grayColor = useReadableTextColor(sideBarBg, 'gray');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openApply, setOpenApply] = useState(false);
    const [openHDWallet, setOpenHDWallet] = useState(false);
    const StatusIcon = kycVerified ? HiShieldCheck : HiShieldExclamation;
    // const showCrypto = showCryptoToUser(profile.id)
    const {getPermission} = useClientPermissionSettings({roles: clientRoles});
    const hasWritePermission =
        getPermission('referrals.invite', 'write') || !liveMode;
    return (
        <>
            <HStack px={3} alignItems={'flex-start'}>
                <Avatar
                    height={sideBarOpen ? '40px' : '40px'}
                    width={sideBarOpen ? '40px' : '40px'}
                    // borderRadius={'4'}
                    src={get(profile, 'avatar')}
                    name={get(client, 'shortName')}
                    onClick={() => navigate(paths.profile)}
                    cursor={'pointer'}
                />
                <MotionBox
                    spacing={0}
                    lineHeight={1.3}
                    minWidth={'170px'}
                    animate={
                        sideBarOpen
                            ? {
                                  opacity: 1,
                                  display: 'block',
                              }
                            : {
                                  opacity: 0,
                                  height: '40px',
                                  transitionEnd: {
                                      display: 'none',
                                  },
                              }
                    }
                    transition={'linear'}
                    initial={false}
                    tran
                >
                    <Text mt={0} color={textColor} noOfLines={2}>
                        {get(client, 'name')}
                    </Text>
                    <Tooltip label={get(client, 'reference')}>
                        <Text variant={'helper'} color={grayColor}>
                            Ref: {get(client, 'reference')}
                        </Text>
                    </Tooltip>
                    <HStack spacing={1} alignItems={'center'} mt={0} ml={-1}>
                        <Tooltip
                            label={t('kycStatusWith', {
                                status: t(get(client, 'kycStatus')),
                            })}
                            hasArrow
                        >
                            <IconButton
                                p={0}
                                size="xs"
                                variant={'unstyled'}
                                onClick={() =>
                                    kycRequired || kycPending
                                        ? navigate(
                                              isCompany
                                                  ? paths.kyb
                                                  : paths.kyc,
                                          )
                                        : navigate(paths.profile)
                                }
                                icon={
                                    <Icon
                                        as={StatusIcon}
                                        boxSize={4}
                                        color={
                                            kycVerified
                                                ? successColor
                                                : warningColor
                                        }
                                    ></Icon>
                                }
                            />
                        </Tooltip>
                        {hasWritePermission && (
                            <Tooltip label={t('brochure')} hasArrow>
                                <IconButton
                                    p={0}
                                    size="xs"
                                    variant={'unstyled'}
                                    onClick={() => setOpen(true)}
                                    icon={
                                        <Icon
                                            as={HiQrcode}
                                            boxSize={4}
                                            color={grayColor}
                                        />
                                    }
                                />
                            </Tooltip>
                        )}
                    </HStack>
                </MotionBox>
            </HStack>
            <BrochureDrawer
                open={open}
                onClose={() => setOpen(false)}
                profile={client}
            />
            {/* <ApplyIBDrawer
                open={openApply}
                onClose={() => setOpenApply(false)}
            /> */}
            {/* {showCrypto && (
                <ClientHDWalletDrawer
                    open={openHDWallet}
                    onClose={() => setOpenHDWallet(false)}
                />
            )} */}
        </>
    );
};
