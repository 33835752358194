export const allPermissions = [
    {
        key: 'dashboard',
        write: true,
        read: true,
        sub: [
            {
                key: 'overview',
                write: true,
                read: true,
            },
            {
                key: 'broker',
                write: true,
                read: true,
            },
            {
                key: 'settlements',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'platformSettings',
        write: true,
        read: true,
        sub: [
            {
                key: 'pspAndMasterAccounts',
                write: true,
                read: true,
            },
            {
                key: 'accessControl',
                write: true,
                read: true,
            },
            {
                key: 'whiteLabellingSettings',
                write: true,
                read: true,
            },
            {
                key: 'generalSettings',
                write: true,
                read: true,
            },
            {
                key: 'blackWhitelist',
                write: true,
                read: true,
            },
            {
                key: 'languages',
                write: true,
                read: true,
            },
            {
                key: 'faq',
                write: true,
                read: true,
            },
            {
                key: 'templates',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'messages',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'email',
                        write: true,
                        read: true,
                    },
                ],
            },
            // {
            //     key: 'currencies',
            //     write: true,
            //     read: true,
            //     sub: [
            //         {
            //             key: 'currencies',
            //             write: true,
            //             read: true,
            //         },
            //         {
            //             key: 'cutoffTime',
            //             write: true,
            //             read: true,
            //         },
            //     ],
            // },
            // {
            //     key: 'platformAccounts',
            //     write: true,
            //     read: true,
            //     sub: [
            //         {
            //             key: 'platformAccounts',
            //             write: true,
            //             read: true,
            //         },
            //         {
            //             key: 'dailyBalance',
            //             write: true,
            //             read: true,
            //         },
            //         {
            //             key: 'platformBalance',
            //             write: true,
            //             read: true,
            //         },
            //     ],
            // },
            // {
            //     key: 'feeSettings',
            //     write: true,
            //     read: true,
            //     sub: [
            //         {
            //             key: 'withdrawal',
            //             write: true,
            //             read: true,
            //         },
            //         {
            //             key: 'conversion',
            //             write: true,
            //             read: true,
            //         },
            //         {
            //             key: 'transfer',
            //             write: true,
            //             read: true,
            //         },
            //     ],
            // },
            // {
            //     key: 'cryptoSettings',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'commissionSettings',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'dealers',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'sales',
            //     write: true,
            //     read: true,
            //     sub: [
            //         {
            //             key: 'detail',
            //             write: true,
            //             read: true,
            //         },
            //     ],
            // },
            // {
            //     key: 'metadata',
            //     write: true,
            //     read: true,
            //     sub: [
            //         {
            //             key: 'platformMetadata',
            //             write: true,
            //             read: true,
            //         },
            //         {
            //             key: 'importSettings',
            //             write: true,
            //             read: true,
            //         },
            //     ],
            // },
            // {
            //     key: 'pspAndPlatformMasterAccounts',
            //     write: true,
            //     read: true,
            // },
        ],
    },
    {
        key: 'tasks',
        write: true,
        read: true,
        sub: [
            {
                key: 'brokerKybApprovals',
                write: true,
                read: true,
            },
            {
                key: 'clientsAmlReviews',
                write: true,
                read: true,
            },
            {
                key: 'brokerBeneficiariesApprovals',
                write: true,
                read: true,
            },
            {
                key: 'pendingSettlements',
                write: true,
                read: true,
            },
            {
                key: 'failedTransactions',
                write: true,
                read: true,
            },
            {
                key: 'cnyDistributions',
                write: true,
                read: true,
            },
            {
                key: 'unallocatedVaDeposits',
                write: true,
                read: true,
            },
            {
                key: 'transactionAlerts',
                write: true,
                read: true,
            },
            {
                key: 'apiKeyApprovals',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'offerings',
        write: true,
        read: true,
        sub: [
            {
                key: 'currencies',
                write: true,
                read: true,
            },
            {
                key: 'virtualBankAccounts',
                write: true,
                read: true,
            },
            {
                key: 'conversions',
                write: true,
                read: true,
            },
            {
                key: 'cryptoTopUps',
                write: true,
                read: true,
            },
            {
                key: 'globalPayments',
                write: true,
                read: true,
            },
            {
                key: 'whiteLabeling',
                write: true,
                read: true,
            },
            {
                key: 'psp',
                write: true,
                read: true,
            },
            {
                key: 'globalBankDatabase',
                write: true,
                read: true,
            },
            {
                key: 'ads',
                write: true,
                read: true,
            },
            {
                key: 'languages',
                write: true,
                read: true,
            },
            {
                key: 'faq',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'brokerFaq',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'clientFaq',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'templates',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'messages',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'email',
                        write: true,
                        read: true,
                    },
                ],
            },
        ],
    },
    {
        key: 'brokers',
        write: true,
        read: true,
        sub: [
            {
                key: 'brokerDetail',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'summary',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'generalInfo',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'profile',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'documents',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'notes',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'clients',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'users',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'accessLogs',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'usageAndBilling',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'theme',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'offerings',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'templates',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'regulations',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'ibCommission',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'apiAccess',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'interactions',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'riskSettings',
                        write: true,
                        read: true,
                    },
                ],
            },
        ],
    },
    {
        key: 'clientsHome',
        write: true,
        read: true,
        sub: [
            {
                key: 'overview',
                write: true,
                read: true,
            },
            {
                key: 'clients',
                write: true,
                read: true,
                sub: [
                    // {
                    //     key: 'toggleApi',
                    //     write: true,
                    //     read: true,
                    // },
                    {
                        key: 'clientDetail',
                        write: true,
                        read: true,
                        sub: [
                            {
                                key: 'summary',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'profile',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'accounts',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'topUpWallets',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'beneficiaries',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'referees',
                                write: true,
                                read: true,
                                sub: [
                                    {
                                        key: 'editIbLevel',
                                        write: true,
                                        read: true,
                                    },
                                ],
                            },
                            {
                                key: 'transactions',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'commissions',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'fee',
                                write: false,
                                read: true,
                            },
                            {
                                key: 'notes',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'sales',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'security',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'requestLogs',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'companyUser',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'interactions',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'riskAlerts',
                                write: true,
                                read: true,
                            },
                            {
                                key: 'functions',
                                write: true,
                                read: true,
                            },
                        ],
                    },
                    // {
                    //     key: 'offlineCreate',
                    //     write: true,
                    //     read: true,
                    // },
                    {
                        key: 'suspend',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'virtualAccounts',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'accountDetail',
                        write: true,
                        read: true,
                        sub: [
                            {
                                key: 'adjust',
                                write: true,
                                read: true,
                            },
                        ],
                    },
                ],
            },
            {
                key: 'demoAccounts',
                write: true,
                read: true,
            },
            {
                key: 'clientsProspect',
                write: true,
                read: true,
            },
            {
                key: 'KYCRequests',
                write: true,
                read: true,
            },
            {
                key: 'requestLogs',
                write: true,
                read: true,
            },
            {
                key: 'ibAdmin',
                write: true,
                read: true,
            },
            {
                key: 'beneficiaries',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'list',
                        write: true,
                        read: true,
                    },
                    // {
                    //     key: 'approval',
                    //     write: true,
                    //     read: true,
                    // },
                    {
                        key: 'suspend',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'topUpWallets',
                write: true,
                read: true,
            },
            {
                key: 'riskAlerts',
                write: true,
                read: true,
            },
            {
                key: 'notes',
                write: true,
                read: true,
            },
            {
                key: 'interactions',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'demoAccounts',
        write: true,
        read: true,
        sub: [
            {
                key: 'accountDetail',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'transactions',
        write: true,
        read: true,
        sub: [
            {
                key: 'transactions',
                write: true,
                read: true,
            },
            {
                key: 'distributions',
                write: true,
                read: true,
            },
            {
                key: 'ledgers',
                write: true,
                read: true,
            },
            {
                key: 'commissions',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'service',
        write: true,
        read: true,
        sub: [
            {
                key: 'serviceRequests',
                write: true,
                read: true,
            },
            {
                key: 'serviceHelps',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'riskAndCompliance',
        write: true,
        read: true,
        sub: [
            {
                key: 'overview',
                write: true,
                read: true,
            },
            {
                key: 'counterparties',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'counterparties',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'kycKybApprovals',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'beneficiaries',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'beneficiaries',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'beneficiaryApproval',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'riskAlerts',
                write: true,
                read: true,
                sub: [
                    {
                        key: 'singleLargeTransaction',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'overMonthlyLimit',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'undeclaredCurrencyUses',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'paymentToRiskyJurisdictions',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'paymentToRiskyBene',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'brokerModifyRiskLevel',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'rapidMovement',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'riskyBeneMaxPayment5Days',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'unusualValue',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'dormant',
                        write: true,
                        read: true,
                    },
                    {
                        key: 'unusualBeneficiary',
                        write: true,
                        read: true,
                    },
                ],
            },
            {
                key: 'settings',
                write: true,
                read: true,
            },
            {
                key: 'notifications',
                write: true,
                read: true,
            },
            {
                key: 'mccCode',
                write: true,
                read: true,
            },
            {
                key: 'country',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'treasury',
        write: true,
        read: true,
        sub: [
            {
                key: 'cashPositions',
                write: true,
                read: true,
            },
            {
                key: 'conversionDealing',
                write: true,
                read: true,
            },
            {
                key: 'unsettledTransfers',
                write: true,
                read: true,
            },
            {
                key: 'unsettledConversions',
                write: true,
                read: true,
            },
            {
                key: 'unsettledDeposits',
                write: true,
                read: true,
            },
            {
                key: 'pendingPayments',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'finance',
        write: true,
        read: true,
        sub: [
            {
                key: 'platformFinance',
                write: true,
                read: true,
            },
            {
                key: 'brokerFinance',
                write: true,
                read: true,
            },
            {
                key: 'clientFinance',
                write: true,
                read: true,
            },
            {
                key: 'pspReconsiliation',
                write: true,
                read: true,
            },
            {
                key: 'brokerReconsiliation',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'development',
        write: true,
        read: true,
        sub: [
            {
                key: 'apiKeys',
                write: true,
                read: true,
            },
            {
                key: 'apiLogs',
                write: true,
                read: true,
            },
            {
                key: 'apiReference',
                write: true,
                read: true,
            },
            {
                key: 'webhook',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'monitoring',
        write: true,
        read: true,
        sub: [
            {
                key: 'thirdPartiesAndStatus',
                write: true,
                read: true,
            },
            {
                key: 'scheduledJobs',
                write: true,
                read: true,
            },
            {
                key: 'server',
                write: true,
                read: true,
            },
            {
                key: 'externalLinks',
                write: true,
                read: true,
            },
        ],
    },
];

export const permissionSettings = {
    PLATFORM_ADMIN: allPermissions,
    PLATFORM_RISK_AND_COMPLIANCE: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'broker',
                    write: false,
                    read: true,
                },
                {
                    key: 'settlements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'platformSettings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'pspAndMasterAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'whiteLabellingSettings',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'brokerKybApprovals',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsAmlReviews',
                    write: true,
                    read: true,
                },
                {
                    key: 'brokerBeneficiariesApprovals',
                    write: true,
                    read: true,
                },
                {
                    key: 'pendingSettlements',
                    write: false,
                    read: true,
                },
                {
                    key: 'failedTransactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cnyDistributions',
                    write: false,
                    read: true,
                },
                {
                    key: 'unallocatedVaDeposits',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionAlerts',
                    write: true,
                    read: true,
                },
                {
                    key: 'apiKeyApprovals',
                    write: true,
                    read: true,
                }
            ],
        },
        {
            key: 'offerings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'currencies',
                    write: false,
                    read: true,
                },
                {
                    key: 'virtualBankAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoTopUps',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: false,
                    read: true,
                },
                {
                    key: 'whiteLabeling',
                    write: false,
                    read: true,
                },
                {
                    key: 'psp',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalBankDatabase',
                    write: false,
                    read: true,
                },
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'languages',
                    write: false,
                    read: true,
                },
                {
                    key: 'faq',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'brokerFaq',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clientFaq',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'templates',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'messages',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'email',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'brokers',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerDetail',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'summary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'generalInfo',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'profile',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'documents',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'notes',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clients',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'users',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'accessLogs',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'usageAndBilling',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'theme',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'offerings',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'templates',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'regulations',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'ibCommission',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiAccess',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'interactions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskSettings',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'functions',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: true,
                    read: true,
                },
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        // {
                        //     key: 'toggleApi',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'summary',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'accounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'topUpWallets',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'beneficiaries',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'referees',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'editIbLevel',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'commissions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fee',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'sales',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'requestLogs',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'companyUser',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'riskAlerts',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                        // {
                        //     key: 'offlineCreate',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'virtualAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'accountDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'adjust',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsProspect',
                    write: true,
                    read: true,
                },
                {
                    key: 'KYCRequests',
                    write: true,
                    read: true,
                },
                {
                    key: 'requestLogs',
                    write: true,
                    read: true,
                },
                {
                    key: 'ibAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'beneficiaries',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'list',
                            write: true,
                            read: true,
                        },
                        // {
                        //     key: 'approval',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'topUpWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'riskAlerts',
                    write: true,
                    read: true,
                },
                {
                    key: 'notes',
                    write: true,
                    read: true,
                },
                {
                    key: 'interactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: false,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'distributions',
                    write: false,
                    read: true,
                },
                {
                    key: 'ledgers',
                    write: false,
                    read: true,
                },
                {
                    key: 'commissions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'treasury',
            write: false,
            read: true,
            sub: [
                {
                    key: 'cashPositions',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversionDealing',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledTransfers',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledConversions',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledDeposits',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'riskAndCompliance',
            write: true,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'counterparties',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'counterparties',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'kycKybApprovals',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'beneficiaries',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'beneficiaries',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'beneficiaryApproval',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'riskAlerts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'singleLargeTransaction',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'overMonthlyLimit',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'undeclaredCurrencyUses',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyJurisdictions',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyBene',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'brokerModifyRiskLevel',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'rapidMovement',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'riskyBeneMaxPayment5Days',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'unusualValue',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'dormant',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'unusualBeneficiary',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'settings',
                    write: true,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: true,
                    read: true,
                },
                {
                    key: 'mccCode',
                    write: true,
                    read: true,
                },
                {
                    key: 'country',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'finance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'platformFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'pspReconsiliation',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerReconsiliation',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiKeys',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiLogs',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiReference',
                    write: false,
                    read: true,
                },
                {
                    key: 'webhook',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'monitoring',
            write: false,
            read: true,
            sub: [
                {
                    key: 'thirdPartiesAndStatus',
                    write: false,
                    read: true,
                },
                {
                    key: 'scheduledJobs',
                    write: false,
                    read: true,
                },
                {
                    key: 'externalLinks',
                    write: false,
                    read: true,
                },
            ],
        },
    ],
    PLATFORM_TREASURY: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'broker',
                    write: false,
                    read: true,
                },
                {
                    key: 'settlements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'platformSettings',
            write: true,
            read: true,
            sub: [
                {
                    key: 'pspAndMasterAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'whiteLabellingSettings',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'pendingSettlements',
                    write: true,
                    read: true,
                },
                {
                    key: 'failedTransactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'cnyDistributions',
                    write: true,
                    read: true,
                },
                {
                    key: 'unallocatedVaDeposits',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionAlerts',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: true,
            read: true,
            sub: [
                {
                    key: 'currencies',
                    write: false,
                    read: true,
                },
                {
                    key: 'virtualBankAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversions',
                    write: true,
                    read: true,
                },
                {
                    key: 'cryptoTopUps',
                    write: true,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: true,
                    read: true,
                },
                {
                    key: 'whiteLabeling',
                    write: true,
                    read: true,
                },
                {
                    key: 'psp',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalBankDatabase',
                    write: false,
                    read: true,
                },
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'languages',
                    write: false,
                    read: true,
                },
                {
                    key: 'faq',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'brokerFaq',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clientFaq',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'templates',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'messages',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'email',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'brokers',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerDetail',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'summary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'generalInfo',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'profile',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'documents',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'notes',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clients',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'users',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'accessLogs',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'usageAndBilling',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'theme',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'offerings',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'templates',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'regulations',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'ibCommission',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiAccess',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'interactions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskSettings',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: true,
                    read: true,
                },
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        // {
                        //     key: 'toggleApi',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'summary',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'accounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'topUpWallets',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'beneficiaries',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'referees',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'editIbLevel',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'commissions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fee',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'sales',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'requestLogs',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'companyUser',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'riskAlerts',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                        // {
                        //     key: 'offlineCreate',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'virtualAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'accountDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'adjust',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsProspect',
                    write: true,
                    read: true,
                },
                {
                    key: 'KYCRequests',
                    write: true,
                    read: true,
                },
                {
                    key: 'requestLogs',
                    write: true,
                    read: true,
                },
                {
                    key: 'ibAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'beneficiaries',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'list',
                            write: true,
                            read: true,
                        },
                        // {
                        //     key: 'approval',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'topUpWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'riskAlerts',
                    write: true,
                    read: true,
                },
                {
                    key: 'notes',
                    write: true,
                    read: true,
                },
                {
                    key: 'interactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'distributions',
                    write: true,
                    read: true,
                },
                {
                    key: 'ledgers',
                    write: true,
                    read: true,
                },
                {
                    key: 'commissions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'treasury',
            write: true,
            read: true,
            sub: [
                {
                    key: 'cashPositions',
                    write: true,
                    read: true,
                },
                {
                    key: 'conversionDealing',
                    write: true,
                    read: true,
                },
                {
                    key: 'unsettledTransfers',
                    write: true,
                    read: true,
                },
                {
                    key: 'unsettledConversions',
                    write: true,
                    read: true,
                },
                {
                    key: 'unsettledDeposits',
                    write: true,
                    read: true,
                },
                {
                    key: 'pendingPayments',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'riskAndCompliance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'counterparties',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'counterparties',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'kycKybApprovals',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'beneficiaries',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'beneficiaries',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'beneficiaryApproval',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'riskAlerts',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'singleLargeTransaction',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'overMonthlyLimit',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'undeclaredCurrencyUses',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyJurisdictions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyBene',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'brokerModifyRiskLevel',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'rapidMovement',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskyBeneMaxPayment5Days',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualValue',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'dormant',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualBeneficiary',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'settings',
                    write: false,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: false,
                    read: true,
                },
                {
                    key: 'mccCode',
                    write: false,
                    read: true,
                },
                {
                    key: 'country',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'finance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'platformFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'pspReconsiliation',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerReconsiliation',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiKeys',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiLogs',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiReference',
                    write: false,
                    read: true,
                },
                {
                    key: 'webhook',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'monitoring',
            write: true,
            read: true,
            sub: [
                {
                    key: 'thirdPartiesAndStatus',
                    write: true,
                    read: true,
                },
                {
                    key: 'scheduledJobs',
                    write: true,
                    read: true,
                },
                {
                    key: 'externalLinks',
                    write: true,
                    read: true,
                },
            ],
        },
    ],
    PLATFORM_BROKER_ADMIN: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'broker',
                    write: false,
                    read: true,
                },
                {
                    key: 'settlements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'platformSettings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'whiteLabellingSettings',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerKybApprovals',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsAmlReviews',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerBeneficiariesApprovals',
                    write: false,
                    read: true,
                },
                {
                    key: 'pendingSettlements',
                    write: false,
                    read: true,
                },
                {
                    key: 'failedTransactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cnyDistributions',
                    write: false,
                    read: true,
                },
                {
                    key: 'unallocatedVaDeposits',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionAlerts',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'currencies',
                    write: false,
                    read: true,
                },
                {
                    key: 'virtualBankAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoTopUps',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: false,
                    read: true,
                },
                {
                    key: 'whiteLabeling',
                    write: false,
                    read: true,
                },
                {
                    key: 'psp',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalBankDatabase',
                    write: false,
                    read: true,
                },
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'languages',
                    write: false,
                    read: true,
                },
                {
                    key: 'faq',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'brokerFaq',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clientFaq',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'templates',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'messages',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'email',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'brokers',
            write: true,
            read: true,
            sub: [
                {
                    key: 'brokerDetail',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'summary',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'generalInfo',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'profile',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'documents',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'notes',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'clients',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'users',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'accessLogs',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'usageAndBilling',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'theme',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'offerings',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'templates',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'regulations',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'ibCommission',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'apiAccess',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'interactions',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'riskSettings',
                            write: true,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: true,
                    read: true,
                },
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        // {
                        //     key: 'toggleApi',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'summary',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'accounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'topUpWallets',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'beneficiaries',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'referees',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'editIbLevel',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'commissions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fee',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'sales',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'requestLogs',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'companyUser',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'riskAlerts',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                        // {
                        //     key: 'offlineCreate',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'virtualAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'accountDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'adjust',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsProspect',
                    write: true,
                    read: true,
                },
                {
                    key: 'KYCRequests',
                    write: true,
                    read: true,
                },
                {
                    key: 'requestLogs',
                    write: true,
                    read: true,
                },
                {
                    key: 'ibAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'beneficiaries',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'list',
                            write: true,
                            read: true,
                        },
                        // {
                        //     key: 'approval',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'topUpWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'riskAlerts',
                    write: true,
                    read: true,
                },
                {
                    key: 'notes',
                    write: true,
                    read: true,
                },
                {
                    key: 'interactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: false,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'distributions',
                    write: false,
                    read: true,
                },
                {
                    key: 'ledgers',
                    write: false,
                    read: true,
                },
                {
                    key: 'commissions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'riskAndCompliance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'counterparties',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'counterparties',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'kycKybApprovals',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'beneficiaries',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'beneficiaries',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'beneficiaryApproval',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'riskAlerts',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'singleLargeTransaction',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'overMonthlyLimit',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'undeclaredCurrencyUses',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyJurisdictions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyBene',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'brokerModifyRiskLevel',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'rapidMovement',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskyBeneMaxPayment5Days',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualValue',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'dormant',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualBeneficiary',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'settings',
                    write: false,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: false,
                    read: true,
                },
                {
                    key: 'mccCode',
                    write: false,
                    read: true,
                },
                {
                    key: 'country',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'finance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerReconsiliation',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiKeys',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiLogs',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiReference',
                    write: false,
                    read: true,
                },
                {
                    key: 'webhook',
                    write: true,
                    read: true,
                },
            ],
        },
    ],
    PLATFORM_FINANCE: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'broker',
                    write: false,
                    read: true,
                },
                {
                    key: 'settlements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'platformSettings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'pspAndMasterAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'whiteLabellingSettings',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: true,
            read: true,
            sub: [
                {
                    key: 'failedTransactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'cnyDistributions',
                    write: true,
                    read: true,
                },
                {
                    key: 'unallocatedVaDeposits',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionAlerts',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: true,
            read: true,
            sub: [
                {
                    key: 'currencies',
                    write: false,
                    read: true,
                },
                {
                    key: 'virtualBankAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversions',
                    write: true,
                    read: true,
                },
                {
                    key: 'cryptoTopUps',
                    write: true,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: true,
                    read: true,
                },
                {
                    key: 'whiteLabeling',
                    write: true,
                    read: true,
                },
                {
                    key: 'psp',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalBankDatabase',
                    write: false,
                    read: true,
                },
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'languages',
                    write: false,
                    read: true,
                },
                {
                    key: 'faq',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'brokerFaq',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clientFaq',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'templates',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'messages',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'email',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'brokers',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerDetail',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'summary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'generalInfo',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'profile',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'documents',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'notes',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clients',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'users',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'accessLogs',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'usageAndBilling',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'theme',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'offerings',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'templates',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'regulations',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'ibCommission',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiAccess',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'interactions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskSettings',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'clients',
                    write: false,
                    read: true,
                    sub: [
                        // {
                        //     key: 'toggleApi',
                        //     write: false,
                        //     read: true,
                        // },
                        {
                            key: 'clientDetail',
                            write: false,
                            read: true,
                            sub: [
                                {
                                    key: 'summary',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'profile',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'accounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'topUpWallets',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'beneficiaries',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'referees',
                                    write: false,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'editIbLevel',
                                            write: false,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'transactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'commissions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'fee',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'sales',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'requestLogs',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'companyUser',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'riskAlerts',
                                    write: false,
                                    read: true,
                                },
                            ],
                        },
                        // {
                        //     key: 'offlineCreate',
                        //     write: false,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'virtualAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'accountDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'adjust',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'demoAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsProspect',
                    write: false,
                    read: true,
                },
                {
                    key: 'KYCRequests',
                    write: false,
                    read: true,
                },
                {
                    key: 'requestLogs',
                    write: false,
                    read: true,
                },
                {
                    key: 'ibAdmin',
                    write: false,
                    read: true,
                },
                {
                    key: 'beneficiaries',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'list',
                            write: false,
                            read: true,
                        },
                        // {
                        //     key: 'approval',
                        //     write: false,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'topUpWallets',
                    write: false,
                    read: true,
                },
                {
                    key: 'riskAlerts',
                    write: false,
                    read: true,
                },
                {
                    key: 'notes',
                    write: false,
                    read: true,
                },
                {
                    key: 'interactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'distributions',
                    write: true,
                    read: true,
                },
                {
                    key: 'ledgers',
                    write: true,
                    read: true,
                },
                {
                    key: 'commissions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'treasury',
            write: false,
            read: true,
            sub: [
                {
                    key: 'cashPositions',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversionDealing',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledTransfers',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledConversions',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledDeposits',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'riskAndCompliance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'counterparties',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'counterparties',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'kycKybApprovals',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'beneficiaries',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'beneficiaries',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'beneficiaryApproval',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'riskAlerts',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'singleLargeTransaction',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'overMonthlyLimit',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'undeclaredCurrencyUses',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyJurisdictions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyBene',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'brokerModifyRiskLevel',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'rapidMovement',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskyBeneMaxPayment5Days',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualValue',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'dormant',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualBeneficiary',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'settings',
                    write: false,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: false,
                    read: true,
                },
                {
                    key: 'mccCode',
                    write: false,
                    read: true,
                },
                {
                    key: 'country',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'finance',
            write: true,
            read: true,
            sub: [
                {
                    key: 'platformFinance',
                    write: true,
                    read: true,
                },
                {
                    key: 'brokerFinance',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientFinance',
                    write: true,
                    read: true,
                },
                {
                    key: 'pspReconsiliation',
                    write: true,
                    read: true,
                },
                {
                    key: 'brokerReconsiliation',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiKeys',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiLogs',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiReference',
                    write: false,
                    read: true,
                },
            ],
        },
    ],
    PLATFORM_MARKETING: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'settlements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'platformSettings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'whiteLabellingSettings',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: true,
            read: true,
            sub: [
                {
                    key: 'currencies',
                    write: false,
                    read: true,
                },
                {
                    key: 'virtualBankAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversions',
                    write: true,
                    read: true,
                },
                {
                    key: 'cryptoTopUps',
                    write: true,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: true,
                    read: true,
                },
                {
                    key: 'whiteLabeling',
                    write: true,
                    read: true,
                },
                {
                    key: 'psp',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalBankDatabase',
                    write: true,
                    read: true,
                },
                {
                    key: 'ads',
                    write: true,
                    read: true,
                },
                {
                    key: 'languages',
                    write: false,
                    read: true,
                },
                {
                    key: 'faq',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'brokerFaq',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clientFaq',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'templates',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'messages',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'email',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'brokers',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerDetail',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'summary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'generalInfo',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'profile',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'documents',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'notes',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clients',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'users',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'accessLogs',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'usageAndBilling',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'theme',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'offerings',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'templates',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'regulations',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'ibCommission',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiAccess',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'interactions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskSettings',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'clients',
                    write: false,
                    read: true,
                    sub: [
                        // {
                        //     key: 'toggleApi',
                        //     write: false,
                        //     read: true,
                        // },
                        {
                            key: 'clientDetail',
                            write: false,
                            read: true,
                            sub: [
                                {
                                    key: 'summary',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'profile',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'accounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'topUpWallets',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'beneficiaries',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'referees',
                                    write: false,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'editIbLevel',
                                            write: false,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'transactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'commissions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'fee',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'sales',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'requestLogs',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'companyUser',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'riskAlerts',
                                    write: false,
                                    read: true,
                                },
                            ],
                        },
                        // {
                        //     key: 'offlineCreate',
                        //     write: false,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'virtualAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'accountDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'adjust',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsProspect',
                    write: true,
                    read: true,
                },
                {
                    key: 'KYCRequests',
                    write: true,
                    read: true,
                },
                {
                    key: 'requestLogs',
                    write: true,
                    read: true,
                },
                {
                    key: 'ibAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'beneficiaries',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'list',
                            write: true,
                            read: true,
                        },
                        // {
                        //     key: 'approval',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'topUpWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'riskAlerts',
                    write: true,
                    read: true,
                },
                {
                    key: 'notes',
                    write: true,
                    read: true,
                },
                {
                    key: 'interactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiKeys',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiLogs',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiReference',
                    write: true,
                    read: true,
                },
            ],
        },
    ],
    PLATFORM_SUPPORT: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'broker',
                    write: false,
                    read: true,
                },
                {
                    key: 'settlements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'platformSettings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'whiteLabellingSettings',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerKybApprovals',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsAmlReviews',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerBeneficiariesApprovals',
                    write: false,
                    read: true,
                },
                {
                    key: 'pendingSettlements',
                    write: false,
                    read: true,
                },
                {
                    key: 'failedTransactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'cnyDistributions',
                    write: true,
                    read: true,
                },
                {
                    key: 'unallocatedVaDeposits',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactionAlerts',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'currencies',
                    write: false,
                    read: true,
                },
                {
                    key: 'virtualBankAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoTopUps',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: false,
                    read: true,
                },
                {
                    key: 'whiteLabeling',
                    write: false,
                    read: true,
                },
                {
                    key: 'psp',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalBankDatabase',
                    write: false,
                    read: true,
                },
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'languages',
                    write: false,
                    read: true,
                },
                {
                    key: 'faq',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'brokerFaq',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clientFaq',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'templates',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'messages',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'email',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'brokers',
            write: true,
            read: true,
            sub: [
                {
                    key: 'brokerDetail',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'summary',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'generalInfo',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'profile',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'documents',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'notes',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'clients',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'users',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'accessLogs',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'usageAndBilling',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'theme',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'offerings',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'templates',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'regulations',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'ibCommission',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'apiAccess',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'interactions',
                            write: true,
                            read: true,
                        },
                        {
                            key: 'riskSettings',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: true,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: true,
                    read: true,
                },
                {
                    key: 'clients',
                    write: true,
                    read: true,
                    sub: [
                        // {
                        //     key: 'toggleApi',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'clientDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'summary',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'profile',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'accounts',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'topUpWallets',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'beneficiaries',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'referees',
                                    write: true,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'editIbLevel',
                                            write: true,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'transactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'commissions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'fee',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'sales',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'requestLogs',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'companyUser',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: true,
                                    read: true,
                                },
                                {
                                    key: 'riskAlerts',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                        // {
                        //     key: 'offlineCreate',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'virtualAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'accountDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'adjust',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsProspect',
                    write: true,
                    read: true,
                },
                {
                    key: 'KYCRequests',
                    write: true,
                    read: true,
                },
                {
                    key: 'requestLogs',
                    write: true,
                    read: true,
                },
                {
                    key: 'ibAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'beneficiaries',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'list',
                            write: true,
                            read: true,
                        },
                        // {
                        //     key: 'approval',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'topUpWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'riskAlerts',
                    write: true,
                    read: true,
                },
                {
                    key: 'notes',
                    write: true,
                    read: true,
                },
                {
                    key: 'interactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'distributions',
                    write: true,
                    read: true,
                },
                {
                    key: 'ledgers',
                    write: true,
                    read: true,
                },
                {
                    key: 'commissions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'riskAndCompliance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'counterparties',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'counterparties',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'kycKybApprovals',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'beneficiaries',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'beneficiaries',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'beneficiaryApproval',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'riskAlerts',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'singleLargeTransaction',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'overMonthlyLimit',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'undeclaredCurrencyUses',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyJurisdictions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyBene',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'brokerModifyRiskLevel',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'rapidMovement',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskyBeneMaxPayment5Days',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualValue',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'dormant',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualBeneficiary',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'settings',
                    write: false,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: false,
                    read: true,
                },
                {
                    key: 'mccCode',
                    write: false,
                    read: true,
                },
                {
                    key: 'country',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'finance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'pspReconsiliation',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerReconsiliation',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiKeys',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiLogs',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiReference',
                    write: true,
                    read: true,
                },
                {
                    key: 'webhook',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'monitoring',
            write: false,
            read: true,
            sub: [
                {
                    key: 'thirdPartiesAndStatus',
                    write: false,
                    read: true,
                },
                {
                    key: 'scheduledJobs',
                    write: false,
                    read: true,
                },
                {
                    key: 'externalLinks',
                    write: false,
                    read: true,
                },
            ],
        },
    ],
    PLATFORM_READONLY: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'broker',
                    write: false,
                    read: true,
                },
                {
                    key: 'settlements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'platformSettings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'whiteLabellingSettings',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'tasks',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerKybApprovals',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientsAmlReviews',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerBeneficiariesApprovals',
                    write: false,
                    read: true,
                },
                {
                    key: 'pendingSettlements',
                    write: false,
                    read: true,
                },
                {
                    key: 'failedTransactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cnyDistributions',
                    write: false,
                    read: true,
                },
                {
                    key: 'unallocatedVaDeposits',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionAlerts',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'currencies',
                    write: false,
                    read: true,
                },
                {
                    key: 'virtualBankAccounts',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoTopUps',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: false,
                    read: true,
                },
                {
                    key: 'whiteLabeling',
                    write: false,
                    read: true,
                },
                {
                    key: 'psp',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalBankDatabase',
                    write: false,
                    read: true,
                },
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'languages',
                    write: false,
                    read: true,
                },
                {
                    key: 'faq',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'brokerFaq',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clientFaq',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'templates',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'messages',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'email',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'brokers',
            write: false,
            read: true,
            sub: [
                {
                    key: 'brokerDetail',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'summary',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'generalInfo',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'profile',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'documents',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'notes',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'clients',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'users',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'accessLogs',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'usageAndBilling',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'theme',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'offerings',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'templates',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'regulations',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'ibCommission',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'apiAccess',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'interactions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskSettings',
                            write: false,
                            read: true,
                        },
                    ],
                },
            ],
        },
        {
            key: 'clientsHome',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'clients',
                    write: false,
                    read: true,
                    sub: [
                        // {
                        //     key: 'toggleApi',
                        //     write: false,
                        //     read: true,
                        // },
                        {
                            key: 'clientDetail',
                            write: false,
                            read: true,
                            sub: [
                                {
                                    key: 'summary',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'profile',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'accounts',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'topUpWallets',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'beneficiaries',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'referees',
                                    write: false,
                                    read: true,
                                    sub: [
                                        {
                                            key: 'editIbLevel',
                                            write: false,
                                            read: true,
                                        },
                                    ],
                                },
                                {
                                    key: 'transactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'commissions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'fee',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'notes',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'sales',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'security',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'requestLogs',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'companyUser',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'interactions',
                                    write: false,
                                    read: true,
                                },
                                {
                                    key: 'riskAlerts',
                                    write: false,
                                    read: true,
                                },
                            ],
                        },
                        // {
                        //     key: 'offlineCreate',
                        //     write: false,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'virtualAccounts',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'accountDetail',
                            write: true,
                            read: true,
                            sub: [
                                {
                                    key: 'adjust',
                                    write: true,
                                    read: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    key: 'demoAccounts',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientsProspect',
                    write: true,
                    read: true,
                },
                {
                    key: 'KYCRequests',
                    write: true,
                    read: true,
                },
                {
                    key: 'requestLogs',
                    write: true,
                    read: true,
                },
                {
                    key: 'ibAdmin',
                    write: true,
                    read: true,
                },
                {
                    key: 'beneficiaries',
                    write: true,
                    read: true,
                    sub: [
                        {
                            key: 'list',
                            write: true,
                            read: true,
                        },
                        // {
                        //     key: 'approval',
                        //     write: true,
                        //     read: true,
                        // },
                        {
                            key: 'suspend',
                            write: true,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'topUpWallets',
                    write: true,
                    read: true,
                },
                {
                    key: 'riskAlerts',
                    write: true,
                    read: true,
                },
                {
                    key: 'notes',
                    write: true,
                    read: true,
                },
                {
                    key: 'interactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transactions',
            write: false,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'distributions',
                    write: false,
                    read: true,
                },
                {
                    key: 'ledgers',
                    write: false,
                    read: true,
                },
                {
                    key: 'commissions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'treasury',
            write: false,
            read: true,
            sub: [
                {
                    key: 'cashPositions',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversionDealing',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledTransfers',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledConversions',
                    write: false,
                    read: true,
                },
                {
                    key: 'unsettledDeposits',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'riskAndCompliance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'overview',
                    write: false,
                    read: true,
                },
                {
                    key: 'counterparties',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'counterparties',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'kycKybApprovals',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'beneficiaries',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'beneficiaries',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'beneficiaryApproval',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'riskAlerts',
                    write: false,
                    read: true,
                    sub: [
                        {
                            key: 'singleLargeTransaction',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'overMonthlyLimit',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'undeclaredCurrencyUses',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyJurisdictions',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'paymentToRiskyBene',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'brokerModifyRiskLevel',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'rapidMovement',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'riskyBeneMaxPayment5Days',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualValue',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'dormant',
                            write: false,
                            read: true,
                        },
                        {
                            key: 'unusualBeneficiary',
                            write: false,
                            read: true,
                        },
                    ],
                },
                {
                    key: 'settings',
                    write: false,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: false,
                    read: true,
                },
                {
                    key: 'mccCode',
                    write: false,
                    read: true,
                },
                {
                    key: 'country',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'finance',
            write: false,
            read: true,
            sub: [
                {
                    key: 'platformFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientFinance',
                    write: false,
                    read: true,
                },
                {
                    key: 'pspReconsiliation',
                    write: false,
                    read: true,
                },
                {
                    key: 'brokerReconsiliation',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'apiKeys',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiLogs',
                    write: false,
                    read: true,
                },
                {
                    key: 'apiReference',
                    write: false,
                    read: true,
                },
                {
                    key: 'webhook',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'monitoring',
            write: false,
            read: true,
            sub: [
                {
                    key: 'thirdPartiesAndStatus',
                    write: false,
                    read: true,
                },
                {
                    key: 'scheduledJobs',
                    write: false,
                    read: true,
                },
                {
                    key: 'externalLinks',
                    write: false,
                    read: true,
                },
            ],
        },
    ],
    // PLATFORM_DEALER: [
    //     {
    //         key: 'dashboard',
    //         write: true,
    //         read: true,
    //         sub: [
    //             {
    //                 key: 'overview',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'balance',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'settlements',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'broker',
    //                 write: true,
    //                 read: true
    //             }
    //         ],
    //     },
    //     {
    //         key: 'clientsHome',
    //         write: true,
    //         read: true,
    //         sub: [
    //             {
    //                 key: 'overview',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'clients',
    //                 write: true,
    //                 read: true,
    //                 sub: [
    //                     {
    //                         key: 'toggleApi',
    //                         write: true,
    //                         read: true,
    //                     },
    //                     {
    //                         key: 'clientDetail',
    //                         write: true,
    //                         read: true,
    //                         sub: [
    //                             {
    //                                 key: 'summary',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'profile',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'accounts',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'referees',
    //                                 write: true,
    //                                 read: true,
    //                                 sub: [
    //                                     {
    //                                         key: 'editIbLevel',
    //                                         write: true,
    //                                         read: true,
    //                                     },
    //                                 ],
    //                             },
    //                             {
    //                                 key: 'transactions',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'commissions',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'fee',
    //                                 write: false,
    //                                 read: false,
    //                             },
    //                             {
    //                                 key: 'notes',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'sales',
    //                                 write: false,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'security',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'requestLogs',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'companyUser',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'interactions',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                             {
    //                                 key: 'riskAlerts',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                         ],
    //                     },
    //                     {
    //                         key: 'offlineCreate',
    //                         write: true,
    //                         read: true,
    //                     },
    //                     {
    //                         key: 'suspend',
    //                         write: true,
    //                         read: true,
    //                     },
    //                 ],
    //             },
    //             {
    //                 key: 'virtualAccounts',
    //                 write: true,
    //                 read: true,
    //                 sub: [
    //                     {
    //                         key: 'accountDetail',
    //                         write: true,
    //                         read: true,
    //                         sub: [
    //                             {
    //                                 key: 'adjust',
    //                                 write: true,
    //                                 read: true,
    //                             },
    //                         ],
    //                     },
    //                 ],
    //             },
    //             {
    //                 key: 'clientsProspect',
    //                 write: false,
    //                 read: true,
    //             },
    //             {
    //                 key: 'KYCRequests',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'requestLogs',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'beneficiaries',
    //                 write: true,
    //                 read: true,
    //                 sub: [
    //                     {
    //                         key: 'list',
    //                         write: true,
    //                         read: true,
    //                     },
    //                     {
    //                         key: 'approval',
    //                         write: true,
    //                         read: true,
    //                     },
    //                     {
    //                         key: 'suspend',
    //                         write: true,
    //                         read: true,
    //                     },
    //                 ],
    //             },
    //             {
    //                 key: 'riskAlerts',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'notes',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'interactions',
    //                 write: true,
    //                 read: true,
    //             },
    //         ],
    //     },
    //     {
    //         key: 'tasks',
    //         write: true,
    //         read: true,
    //         sub: [
    //             {
    //                 key: 'brokerKybApprovals',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'ibRequests',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'KYCRequests',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'brokerBeneficiariesApprovals',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'clientsAmlReviews',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'failedTransactions',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'cnyDistributions',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'unallocatedVaDeposits',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'transactionAlerts',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'generalRequests',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'pendingSettlements',
    //                 write: true,
    //                 read: true,
    //             },
    //         ],
    //     },
    //     {
    //         key: 'demoAccounts',
    //         write: true,
    //         read: true,
    //         sub: [
    //             {
    //                 key: 'accountDetail',
    //                 write: false,
    //                 read: true,
    //             },
    //         ],
    //     },
    //     {
    //         key: 'transactions',
    //         write: true,
    //         read: true,
    //         sub: [
    //             {
    //                 key: 'transactions',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'distributions',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'ledgers',
    //                 write: true,
    //                 read: true,
    //             },
    //             {
    //                 key: 'commissions',
    //                 write: true,
    //                 read: true,
    //             },
    //         ],
    //     },
    //     {
    //         key: 'service',
    //         write: true,
    //         read: true,
    //     },
    //     {
    //         key: 'faq',
    //         write: true,
    //         read: true,
    //     },
    // ],
};

