import {formAnatomy as parts} from '@chakra-ui/anatomy';
import {
    createMultiStyleConfigHelpers,
} from '@chakra-ui/styled-system';
import {colorByMode} from 'theme/themeHelper';
import {defineStyle, defineStyleConfig} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(parts.keys);

const baseStyleHelperText = props=>defineStyle({
    fontSize: '2xs',
    mt: 1,
    color: colorByMode('gray', props.colorMode),
    _disabled: {
        opacity: 0.4,
    },
});

const baseStyle = definePartsStyle((props) => ({
    helperText: baseStyleHelperText(props),
}));

export const formTheme = defineMultiStyleConfig({
    baseStyle,
});
