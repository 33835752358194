// import UploadField from 'components/UploadField';
// import UploadField from 'components/web/UploadField';
import {useUploader} from 'hooks/datahook/common';
import React from 'react';
import FormWrapper from './FormWrapper';
import UploadField from 'components/UploadField';

const FileUploader = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        disabled,
        // uploaderType = 'image',
        onUpload = () => {},
        limitText,
        sizeLimit,
        accept,
        mutiple = false,
    } = props;
    const value = formik.values[name];
    const _onUpload = useUploader();

    return (
        <FormWrapper {...props}>
            <UploadField
                file={value}
                onChangeFile={(value) => formik.setFieldValue(name, value)}
                onUpload={_onUpload}
                // type={uploaderType}
                disabled={disabled}
                helperText={limitText}
                sizeLimit={sizeLimit}
                accept={accept}
                mutiple={mutiple}
                
            />
        </FormWrapper>
    );
};

// export default FileUploader;

export default React.memo(FileUploader, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.readOnly === next.readOnly &&
        prev.i18n === next.i18n
    );
});
