import { Box } from '@chakra-ui/react';
import {useLocation, Outlet} from 'react-router-dom';

export default function MainLayout() {
    return (
        <Box sx={{minHeight: 1}}>
            <Box sx={{height: '100vh'}}>
                <Outlet />
            </Box>
        </Box>
    );
}
