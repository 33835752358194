import {
    FormControl,
    FormHelperText,
    FormLabel,
    FormErrorMessage,
} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import {isFunction} from 'lodash';
import React from 'react';
import {WarningIcon} from '@chakra-ui/icons';

const FormWrapper = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        required = false,
        disabled,
        readOnly,
        helper,
        children,
    } = props;
    const renderLabel = isFunction(label) ? label(formik.values) : label;
    const renderTitle = isFunction(title) ? title(formik.values) : title;
    const darkColor = useColorModeValueKey('text');
    const grayColor = useColorModeValueKey('gray');
    return (
        <FormControl
            isInvalid={
                Boolean(formik.errors[name]) && Boolean(formik.touched[name])
            }
            isDisabled={disabled}
            isReadOnly={readOnly}
            isRequired={required}
            // lineHeight={1.2}
        >
            <FormLabel>{renderLabel || title}</FormLabel>
            {children}
            {helper && <FormHelperText>{helper}</FormHelperText>}
            <FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
        </FormControl>
    );
};

export default FormWrapper;
