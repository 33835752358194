import React from 'react';
import {useTranslation} from 'react-i18next';
import Selector from './Selector';
import { useCompanyIndustry } from 'hooks/datahook/common';

const IndustrySelector = (props) => {
    const {value, onChangeValue, ...other} = props;
    const {t} = useTranslation('app');
    const {localedIndustry} = useCompanyIndustry();
    return (
        <Selector
            value={value}
            onChangeValue={onChangeValue}
            options={localedIndustry}
            {...other}
        />
    );
};

export default IndustrySelector;
