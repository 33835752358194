import chroma from 'chroma-js';
import {get, split} from 'lodash';
import {designSystemColors} from 'theme';
import tinycolor from 'tinycolor2';

export const convertColorToCssVar = (color) => {
    return `var(--chakra-colors-${split(color, '.').join('-')})`;
};
export const colorByMode = (colorKey, colorMode) => {
    return colorMode === 'dark'
        ? convertColorToCssVar(`dark.${designSystemColors.dark[colorKey]}`)
        : convertColorToCssVar(`light.${designSystemColors.light[colorKey]}`);
};

export const generatePalette = (from, to) => {
    const palette = chroma.scale([from, to]).mode('hsl').colors(11);
    const p = {};
    [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950].forEach(
        (key, index) => {
            p[key] = palette[index];
        },
    );
    return p;
};


export const getReadableTextColor = ({theme, backgroundColor}) => {
    const lightText = get(
        theme,
        `colors.light.${designSystemColors.light.text}`,
    );
    const darkText = get(theme, `colors.dark.${designSystemColors.dark.text}`);
    const lightReadability = tinycolor.readability(lightText, backgroundColor);
    const darkReadability = tinycolor.readability(darkText, backgroundColor);
    if (Math.abs(lightReadability - darkReadability) < 0.3) {
        return darkText;
    }
    return lightReadability > darkReadability ? lightText : darkText;
};
