import {get} from 'lodash';

export const processEstimate = (rawData) => {
    return {
        transferNeedsFrom: get(rawData, 'fromCurrency', ''),
        transferNeedsTo: get(rawData, 'toCurrency', ''),
        transferNeeds: `${get(rawData, 'fromCurrency', '')}/${get(
            rawData,
            'toCurrency',
            '',
        )}`,
        transferAmount: Number(get(rawData, 'amount', '')),
        declaredCurrency: get(rawData, 'declaredCurrency', ''),
        estimateCurrencies: get(rawData, 'currencies', ''),
    };
};
