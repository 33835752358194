import React, {useState} from 'react';
import {chunk, flatten, get, isArray, isNil, split, takeRight} from 'lodash';
import FormViewWrapper from './FormViewWrapper';
// import File from 'components/common/BaseIcon/File';
// import Download from 'components/common/BaseIcon/Download';
import {Box, HStack, Link, Stack, Text, VStack} from '@chakra-ui/react';

const ArrayViewer = (props) => {
    const {data, i18n, index, name, CustomViewer,numOfCard=2} = props;
    const arrayData = get(data, name);
    return (
        <FormViewWrapper {...props}>
            {isNil(arrayData) ? (
                <Text fontSize="xs">{i18n('noData')}</Text>
            ) : (
                <Stack>
                    {chunk(arrayData, numOfCard).map((ck, i) => (
                        <HStack alignItems={'stretch'} key={i}>
                            {(ck.length < numOfCard
                                ? [
                                      ...ck,
                                      ...Array(numOfCard - ck.length).fill({
                                          type: null,
                                      }),
                                  ]
                                : ck
                            ).map((item, i2) =>
                                item.type === null ? (
                                    <Box px={2} py={1} flex={1} key={i2} />
                                ) : (
                                    <CustomViewer data={item} key={i2} />
                                ),
                            )}
                        </HStack>
                    ))}
                </Stack>
            )}
        </FormViewWrapper>
    );
};

export default ArrayViewer;
