import {useColorModeValueKey, useReadableTextColor} from 'hooks/useColors';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {matchPath, Outlet, useMatches, useNavigate} from 'react-router-dom';
import {navDrawerConfig, paths} from 'routes/path';
import {camelCase, get} from 'lodash';
import {isNil} from 'lodash';
import {useUIStore} from 'stores/uiStore';
import {formatDateTimes} from 'utils/formator';
import LanguageBtn from 'components/LanguageBtn';
import {split} from 'lodash';
import {last} from 'lodash';
import {useAuth} from 'hooks/useAuth';
import {useAdminProfile, useLoginLog, useProfile} from 'hooks/datahook/auth';
import {useAutoLogout} from 'hooks/useAutoLogout';
import {Helmet} from 'react-helmet-async';
import {useIsMobile} from 'hooks/useIsMobile';
import {
    Box,
    Divider,
    Heading,
    HStack,
    Icon,
    IconButton,
    Image,
    Stack,
    Text,
} from '@chakra-ui/react';
import {HiOutlineMenu} from 'react-icons/hi';
import {MobileNavDrawer, NavBar} from './NewNavBar';
import {HEADER_HEIGHT} from 'theme';
import {useClientPermissionSettings} from 'hooks/clientPermission';
import ProfileBtn from 'components/ProfileBtn';
import LogoutBtn from 'components/LogoutBtn';
import NotificationBtn from 'components/NotificationBtn';
import CustomBreadcrumb from 'components/CustomBreadcrumb';
import ColorModeBtn from 'components/ColorModeBtn';

export const Header = memo((props) => {
    const {profile} = useProfile();
    const {isUser, clientRoles} = useAuth();
    // console.log(profile);
    const {t} = useTranslation('app');
    const [headerTitle, headerDesc, showMobileDrawer, setShowMobileDrawer] =
        useUIStore((state) => [
            state.headerTitle,
            state.headerDesc,
            state.showMobileDrawer,
            state.setShowMobileDrawer,
        ]);
    const matches = useMatches();
    // console.log(matches);
    const matchPath = get(matches, '[1].pathname');
    const mainPath = last(split(matchPath, '/'));
    const isMobile = useIsMobile();

    const {data: loginLog, isLoading} = useLoginLog();
    const {getPermission} = useClientPermissionSettings({roles: clientRoles});
    const hasWritePermission = getPermission(
        'clientManagement.multiLanguage',
        'write',
    );
    const defaultDashboardHeaderDesc =
        isLoading || isNil(loginLog) || isNil(get(loginLog, 'createdAt'))
            ? null
            : `${t('lastLoginTime')} ${formatDateTimes(
                  get(loginLog, 'createdAt'),
              )}`;
    const whiteColor = useColorModeValueKey('white');
    const grayColor = useColorModeValueKey('gray');
    const grayerWhite = useColorModeValueKey('grayerWhite');
    const defaultHeaderDesc =
        matchPath === paths.dashboard ? defaultDashboardHeaderDesc : '  ';
    const defaultHeaderTitle =
        matchPath === paths.dashboard
            ? t('greatingWithName', {
                  name: get(profile, 'name') || t('newUser'),
              })
            : t(camelCase(mainPath));
    return (
        <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            px={{base: 2, md: 4}}
            py={2}
            bg={useColorModeValueKey('navBg')}
            transition={'background-color 200ms linear'}
            zIndex={2}
            shadow={'sm'}
            height={HEADER_HEIGHT}
        >
            <HStack alignItems={'center'} position="relative" flex={1}>
                {isMobile ? (
                    <HStack>
                        <IconButton
                            onClick={() => setShowMobileDrawer(true)}
                            icon={<HiOutlineMenu size={'24px'} />}
                            variant={'ghost'}
                        />
                        <Heading
                            fontWeight={'600'}
                            fontSize={'lg'}
                            textAlign="center"
                            flex={1}
                        >
                            {headerTitle ? headerTitle : defaultHeaderTitle}
                        </Heading>
                    </HStack>
                ) : (
                    <Stack spacing={0} alignItems={'flex-start'}>
                        <Heading fontWeight={'600'} fontSize={'lg'}>
                            {headerTitle || defaultHeaderTitle}
                        </Heading>
                        {headerDesc || defaultHeaderDesc ? (
                            <Text color={grayColor} fontSize="xs">
                                {headerDesc || defaultHeaderDesc}
                            </Text>
                        ) : (
                            <CustomBreadcrumb />
                        )}
                    </Stack>
                )}
            </HStack>
            <HStack alignItems={'center'} spacing={2}>
                <NotificationBtn />
                {/* {isUser && <PlatformCurrencyBtn />} */}
                {!isMobile && (
                    <HStack alignItems={'center'} spacing={2}>
                        <LanguageBtn small />
                        <ColorModeBtn />
                    </HStack>
                )}
                {!isMobile && (
                    <Divider h={'36px'} orientation="vertical" ml={1} />
                )}
                <ProfileBtn />
            </HStack>
        </HStack>
    );
});

const Zendesk = () => {
    return (
        <Helmet>
            <script
                id="ze-snippet"
                src="https://static.zdassets.com/ekr/snippet.js?key=123456"
                async
            ></script>
        </Helmet>
    );
};

const CRMLayout = () => {
    useAutoLogout();
    const {isUser} = useAuth();
    const [liveMode] = useUIStore((state) => [state.liveMode]);
    const {t} = useTranslation('app');
    const isMobile = useIsMobile();
    const primary = useColorModeValueKey('primary');
    const primaryReadableText = useReadableTextColor(primary);
    return (
        <HStack alignItems={'stretch'} h={'100vh'} w="100vw" spacing={0}>
            {/* <NavBar/> */}
            {isMobile ? <MobileNavDrawer /> : <NavBar />}
            {/* {isUser && <Zendesk />} */}
            <Stack
                flex={1}
                bg={useColorModeValueKey('bgAlt')}
                transition={'background-color 200ms linear'}
                spacing={0}
                display="flex"
                w="100%"
            >
                <Header />
                <Box overflow={'scroll'} flex={1} zIndex={1} w="100%">
                    <Box
                        px={{base: 4, md: 4}}
                        pt={4}
                        pb={{base: 6, md: 4}}
                        mx="auto"
                        maxW={isUser ? '1100px' : '100%'}
                        // overflow={'scroll'}
                        // h="100%"
                    >
                        <Outlet />
                    </Box>
                </Box>
            </Stack>
        </HStack>
    );
};

export default CRMLayout;
