// import CountryIcon from 'components/CountryIcon';
import { find, get } from 'lodash';
import React from 'react'
import { useTranslation } from 'react-i18next';
import FormViewWrapper from './FormViewWrapper';
import { HStack, Text } from '@chakra-ui/react';
import CountryIcon from 'components/CountryIcon';

const CountrySelectorViewer = (props) => {
    const {data, i18n, index, name, text, options, formater} = props;
    const v = get(data, name);
    const {t: countriesT} = useTranslation('countries');
    return (
        <FormViewWrapper {...props}>
            <HStack alignItems={'center'} spacing={2}>
                <CountryIcon country={v} size={24} />
                <Text fontWeight={'400'}>{countriesT(v) || ' '}</Text>
            </HStack>
        </FormViewWrapper>
    );
};


export default CountrySelectorViewer;

