import {
    createMultiStyleConfigHelpers,
    cssVar,
    defineStyle,
} from '@chakra-ui/styled-system';
import {colorByMode} from 'theme/themeHelper';
import {formErrorAnatomy as parts} from '@chakra-ui/anatomy';

const {definePartsStyle, defineMultiStyleConfig} =
    createMultiStyleConfigHelpers(parts.keys);

const baseStyleText = defineStyle({
    mt: 1,
    fontSize: 'xs',
});

const baseStyle = definePartsStyle({
    text: baseStyleText,
});

export const FormErrorMessageTheme = defineMultiStyleConfig({
    baseStyle
});
