import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import {colorByMode} from 'theme/themeHelper';

const baseStyle = defineStyle((props) => ({
    fontSize: 'xs',
    fontWeight: '400',
    color: colorByMode('text', props.colorMode),
    mb:1
}));

export const FormLabelTheme = defineStyleConfig({
    baseStyle,
});
