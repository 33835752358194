import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {useAuth} from 'hooks/useAuth';
import {api, getCurrentUserType, usePlatformSetting} from './common';
import {cleanObjectNilValue} from 'utils/clean';
import {useAdminProfile, useProfile} from './auth';
import {get, isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useUIStore} from 'stores/uiStore';
import { processUserProfile } from 'data/userProfile';
import {processPerson} from 'data/person';
import {companyUserPermissionSettings} from '../../constant/companyUserPermissions';

export const updatePassword_ = async ({oldPassword, password}) => {
    const {result} = await api().post(['password'], {
        oldPassword,
        password,
    });
    return result;
};

export const useUserSetting = () => {
    const {isUser} = useAuth();
    const swr = (isUser ? useProfile : useAdminProfile)();
    const {i18n} = useTranslation('app');
    const [firstLanguageCounter, setFirstLanguageCounter] = useUIStore(
        (state) => [state.firstLanguageCounter, state.setFirstLanguageCounter],
    );
    return {
        ...swr,
        data: get(swr, 'data.setting'),
        twoFactorEnabled: Boolean(get(swr, 'data.setting.twoFactor')),
        init: () => {
            if (
                !swr.isLoading &&
                !isEmpty(swr.data) &&
                firstLanguageCounter === 1
            ) {
                if (get(swr, 'data.setting.locale') !== i18n.language) {
                    i18n.changeLanguage(get(swr, 'data.setting.locale'));
                }
                setFirstLanguageCounter(0);
            }
        },
    };
};

export const useLanguageSetting = (options = {}) => {
    const {request = true} = options;
    const {id} = useAuth();
    const swr = useSWRTemplate({
        key: id && request ? ['language-setting', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['language']);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const useInviteCompanyUser = (options = {}) => {
    const {id} = useAuth();
    const swr = useSWRTemplate({
        key: id ? ['invite-company-user', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['company-user-invitation']);

            const registered = (result?.registered || []).map((item) => ({
                ...processUserProfile(get(item, ':user')),
                ...processPerson(item),
            }));
            const inviting = result?.inviting || [];
            return [...registered, ...inviting];
        },
        defaultValue: [],
    });
    return swr;
};

export const useCompanyUserAccessLog = (options = {}) => {
    const {id} = useAuth();
    const swr = useSWRPaginationTemplate({
        key: id ? ['company-user-access-log', id] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _id] = _key;
            const {result} = await api().get(
                ['access-log'],
                cleanObjectNilValue({
                    pageIndex: _pageIndex - 1,
                    pageSize: _pageSize,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map((item) => item),
                },
            };
        },
        defaultValue: [],
    });
    return swr;
};

export const updateUserSettings_ = async (payload) => {
    const userTypes = getCurrentUserType();
    const {result} = await api().post(
        [
            userTypes.isAdmin || userTypes.isDealer || userTypes.isTreasury
                ? 'owner-setting'
                : 'setting',
            'base',
        ],
        payload,
    );
    return result;
};

export const updateUserAvatar_ = async ({avatar}) => {
    const userTypes = getCurrentUserType();
    const {result} = await api().post(
        [
            userTypes.isAdmin || userTypes.isDealer || userTypes.isTreasury
                ? 'owner-setting'
                : 'setting',
            'avatar',
        ],
        {avatar},
    );
    return result;
};

export const useRegulationRegion = (options = {}) => {
    const {broker} = options;
    const swr = useSWRTemplate({
        key: ['settings-regulation-region', broker],
        request: async ([_, _broker]) => {
            const {result} = await api().get(['setting', 'regulation'], {
                broker: _broker,
            });
            return result.map((item) => ({
                ...item,
                isDefaultGlobal: Boolean(get(item, 'isDefaultGlobal')),
                isRestricted: Boolean(get(item, 'isRestricted')),
                visibleLevel: get(item, 'ibVisibleLevel') || 0,
                visibleDetailLevel: get(item, 'ibVisibleDetailLevel') || 0,
                ibPercent: Number(get(item, 'ibPercent')) || 0,
            }));
        },
        defaultValue: [],
    });
    return swr;
};

export const useBrokerRiskSettings = (options = {}) => {
    const {broker} = options;
    const swr = useSWRTemplate({
        key: ['broker-risk-settings', broker],
        request: async ([_, _broker]) => {
            const {result} = await api().get(['broker', 'risk-setting'], {
                brokerId: _broker,
            });
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const useCompanyUserRoles = () => {
    const swr = useSWRTemplate({
        key: ['company-user-roles'],
        request: async ([_]) => {
            // const {result} = await api().get(['role-type']);

            const result = [
                {
                    code: 'BROKER_COMPANY_ADMIN',
                    name: 'Broker Company Admin',
                    desc: null,
                    isDeleted: 0,
                    deletedAt: null,
                },
                {
                    code: 'BROKER_COMPANY_OPERATOR',
                    name: 'Broker Company Operator',
                    desc: null,
                    isDeleted: 0,
                    deletedAt: null,
                },
                {
                    code: 'BROKER_COMPANY_READONLY',
                    name: 'Broker Company Readonly',
                    desc: null,
                    isDeleted: 0,
                    deletedAt: null,
                },
                {
                    code: 'BROKER_COMPANY_DEVELOPER',
                    name: 'Broker Developer',
                    desc: null,
                    isDeleted: 0,
                    deletedAt: null,
                },
            ];

            return result.map((item) => {
                return {
                    ...item,
                    label: item?.code,
                    value: item?.code,
                };
            });
        },
        defaultValue: [],
    });
    return swr;
};

export const useRestrictedRegion = (options = {}) => {
    const {isSystem, broker, request = true} = options;
    const swr = useSWRTemplate({
        key: request ? ['risk-restricted-regions', isSystem, broker] : null,
        request: async (_key) => {
            const [_, _isSystem, _broker] = _key;
            const {result} = await api().get(
                ['setting', 'restricted-region'],
                cleanObjectNilValue({
                    isSystem: _isSystem,
                    broker: _broker,
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const createRiskRestrictedRegion_ = async (country) => {
    return await api().post(['setting', 'restricted-region'], {country});
};

export const deleteRiskRestrictedRegion_ = async (id) => {
    return await api().del(['setting', 'restricted-region', id]);
};

export const useRiskyJurisdiction = (options = {}) => {
    const {isSystem, broker, request = true} = options;
    const swr = useSWRTemplate({
        key: request ? ['risky-Jurisdiction', isSystem, broker] : null,
        request: async (_key) => {
            const [_, _isSystem, _broker] = _key;
            const {result} = await api().get(
                ['setting', 'risky-jurisdiction'],
                cleanObjectNilValue({
                    isSystem: _isSystem,
                    broker: _broker,
                }),
            );
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
    };
};

export const createRiskyJurisdiction_ = async (country) => {
    return await api().post(['setting', 'risky-jurisdiction'], {country});
};

export const deleteRiskyJurisdiction_ = async (id) => {
    return await api().del(['setting', 'risky-jurisdiction', id]);
};

export const useClientPermissionSettings = () => {
    const swr = useSWRTemplate({
        key: ['system-access'],
        request: async ([_]) => {
            // const {result} = await api().get(['access']);
            return companyUserPermissionSettings;
            // return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useCompanyOperatorCount = (options = {}) => {
    const swr = usePlatformSetting({code: 'MAX_COMPANY_OPERATOR_NUMBER'});
    return {
        ...swr,
        data: get(swr, 'data') ? Number(get(swr, 'data')) : 0,
    };
};

export const updateContact_ = async (id, payload) => {
    console.log(payload);
    const {result} = await api().put(
        ['contact', id],
        cleanObjectNilValue(payload),
    );
    return result;
};

export const updateSettingsEnable_ = async (payload) => {
    // const userTypes = getCurrentUserType();
    // const {result} = await api().post(
    //     [(userTypes.isUser || userTypes.isSale) ? 'setting' : 'owner-setting', 'enable-otp'],
    //     payload,
    // );
    const {result} = await api().post(['setting', 'enable-otp'], payload);
    return result;
};

export const inviteCompanyUser_ = async (payload) => {
    const {result} = await api().post(
        ['company-user-invitation'],
        cleanObjectNilValue(payload),
    );
    return result;
};

export const editCompanyUserStatus_ = async (id, payload) => {
    const {result} = await api().put(
        ['company-user-invitation', id],
        cleanObjectNilValue(payload),
    );
    return result;
};

export const createRegulationRegion_ = async (data) => {
    const {result} = await api().post(
        ['setting', 'regulation'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const updateRegulationRegion_ = async (id, data) => {
    const {result} = await api().put(
        ['setting', 'regulation', id],
        cleanObjectNilValue(data),
    );
    return result;
};

export const delRegulationRegion_ = async (id) => {
    const {result} = await api().del(['setting', 'regulation', id]);
    return result;
};

export const settingEnableOtp_ = async (data) => {
    const {result} = await api().post(
        ['setting', 'enable-otp'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const delRestrictedRegion_ = async (id) => {
    const {result} = await api().del(['setting', 'restricted-region', id]);
    return result;
};

export const createRestrictedRegion_ = async (data) => {
    const {result} = await api().post(
        ['setting', 'restricted-region'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const companyUserResetPassword_ = async (data) => {
    const {result} = await api().post(
        ['reset-password'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const systemUserResetPassword_ = async (data) => {
    const {result} = await api().post(
        ['staff', 'reset-password'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const brokerRiskSetting_ = async (data) => {
    await api().post(['broker', 'risk-setting'], cleanObjectNilValue(data));
};
