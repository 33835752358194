import React, {useState} from 'react';
import FormWrapper from './FormWrapper';
import {
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    Textarea,
} from '@chakra-ui/react';
import {upperFirst} from 'lodash';
import {HiEye} from 'react-icons/hi2';
import {HiEyeOff} from 'react-icons/hi';

const PasswordInput = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        inputProps,
        disabled,
        readOnly,
        CustomInput,
        CondationInput,
        placeholder,
    } = props;
    const value = formik.values[name];
    const InputComponent = CondationInput
        ? CondationInput(formik.values)
        : CustomInput
        ? CustomInput
        : Input;
    const [viewPassword, setViewPassword] = useState(false);
    return (
        <FormWrapper {...props}>
            <InputGroup>
                <InputComponent
                    isDisabled={disabled}
                    isReadOnly={readOnly}
                    onChange={(e) => formik.setFieldValue(name, e.target.value)}
                    value={value}
                    autoCapitalize="new-password"
                    size="sm"
                    variant="outline"
                    placeholder={placeholder || i18n('enter')}
                    type={viewPassword ? 'text' : 'password'}
                    // onBlur={() => {
                    //     formik.setFieldTouched(name, true);
                    // }}
                    {...inputProps}
                />
                <InputRightElement
                    width={'auto'}
                    height="100%"
                    size={'md'}
                    alignItems="center"
                    mr={1}
                >
                    <IconButton
                        onClick={() => setViewPassword((c) => !c)}
                        variant={'ghost'}
                        size={'xs'}
                        icon={
                            viewPassword ? (
                                <HiEye boxSize={4} />
                            ) : (
                                <HiEyeOff boxSize={4} />
                            )
                        }
                    />
                </InputRightElement>
            </InputGroup>
        </FormWrapper>
    );
};
// export default PasswordInput;
export default React.memo(PasswordInput, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.readOnly === next.readOnly &&
        prev.i18n === next.i18n
    );
});
