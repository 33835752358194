import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Box, Center, HStack} from '@chakra-ui/react';
import LanguageBtn from 'components/LanguageBtn';
import ColorModeBtn from 'components/ColorModeBtn';
import {useColorModeValueKey} from 'hooks/useColors';

const GuestLayout = (props) => {
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    return (
        <Box
            position={'relative'}
            width={'100vw'}
            // bg={useColorModeValueKey('bg')}
        >
            <Center
                alignItems={'center'}
                justifyContent={'center'}
                // position={'absolute'}
                width={'100vw'}
                height={'100vh'}
            >
                <Outlet />
            </Center>
            <HStack
                position={'absolute'}
                alignItems={'flex-end'}
                justifyContent={'flex-end'}
                right={8}
                top={4}
                spacing={2}
                w={'100vw'}
            >
                <LanguageBtn />
                <ColorModeBtn />
            </HStack>
        </Box>
    );
};

export default GuestLayout;
