// import {Image} from '@chakra-ui/react';
import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import {
    Badge,
    Box,
    Center,
    Collapse,
    DarkMode,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    Heading,
    Icon,
    IconButton,
    Image,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Spinner,
    Stack,
    Switch,
    Text,
    Tooltip,
    VStack,
    useColorMode,
    useToken,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Button,
} from '@chakra-ui/react';

import {useAuth} from 'hooks/useAuth';
import {useColorModeValueKey, useReadableTextColor} from 'hooks/useColors';
import {find, findIndex, get, isNil, trim} from 'lodash';
import React, {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {matchPath, useMatches, useNavigate} from 'react-router-dom';
import {navDrawerAdminConfig, navDrawerConfig, paths} from 'routes/path';
import {useUIStore} from 'stores/uiStore';
import tinycolor from 'tinycolor2';
import {HiMoon, HiSun, HiLogout} from 'react-icons/hi';
import CountryIcon from 'components/CountryIcon';
import {HEADER_HEIGHT} from 'theme';
import {motion} from 'framer-motion';
import { useAdminPermissionSettings } from 'hooks/adminPermission';
import {useUserSetting} from 'hooks/datahook/settings';
import {
    useAdminProfile,
    useClient,
    useProfile,
} from 'hooks/datahook/auth';
import {NavBarItem} from './NavBarItem';
import {CgArrowsExchangeAlt} from 'react-icons/cg';
import Logo from 'assets/images/GenXs.png';
import GenXLogo from 'assets/images/GenX.png';
import appConfig from 'config/app';
import DefaultLogo from 'components/DefaultLogo';

const MotionText = motion(Text);
const MotionStack = motion(Stack);
const MotionImg = motion('img');

// Customize if reuqired
export const AppLogoArea = memo((props) => {
    const {title = 'Security', px = 3, logo, isBrokerLogo} = props;
    const [sideBarOpen] = useUIStore((state) => [state.sideBarOpen]);
    const {t} = useTranslation('app');
    const textColor = useColorModeValueKey('text');
    // const {data: whiteLabelSetting} = usePlatformWhiteLabelSetting();
    const displayLargeLogo = GenXLogo;
    const displaySmallLogo = Logo;
    const jumpToWebsite = () => {
        window.open('https://gen-x.tech/', '_blank');
    }

    const judgeEnableClick = () => {
        if (appConfig.isPlatformAdmin) return false;
        return false;
    }

    return (
        <HStack spacing={2} width={'100%'} height={HEADER_HEIGHT} px={px}>
            {appConfig.isPlatformAdmin ? (
                <>
                    {!sideBarOpen && 
                    <Image
                        src={Logo}
                        height={sideBarOpen ? '50px' : '40px'}
                        width={sideBarOpen ? '50px' : '40px'}
                    />
                    }
                    {sideBarOpen && (
                        <Stack alignItems={'flex-end'} space={1}>
                            <img src={GenXLogo} />
                        </Stack>
                    )}
                </>
            ) : (
                <>
                    {sideBarOpen ? (
                        displayLargeLogo ? (
                            <Image
                                src={displayLargeLogo}
                                maxHeight={'50px'}
                                maxWidth={'170px'}
                                onClick={jumpToWebsite}
                                cursor={judgeEnableClick() ? 'pointer' : null}
                            />
                        ) : (
                            <DefaultLogo />
                        )
                    ) : displaySmallLogo ? (
                        <Image
                            src={displaySmallLogo}
                            maxHeight={'40px'}
                            maxWidth={'40px'}
                            onClick={jumpToWebsite}
                            cursor={judgeEnableClick() ? 'pointer' : null}
                        />
                    ) : (
                        <DefaultLogo small />
                    )}
                </>
            )}
        </HStack>
    );
});

export const DrawerItem = memo((props) => {
    const {
        item,
        currentPath,
        onClick,
        isMenu,
        open,
        kycVerified,
        isSubItem,
        collapsed = false,
        isHorizontal,
    } = props;
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const matches = useMatches();
    const isSelected = !isNil(
        find(matches, (o) => trim(o.pathname, '/') == trim(item.path, '/')),
    );
    const isDisabled =
        item.isDisabled ||
        (item.kycValid ? (kycVerified ? false : true) : false);
    const sideBarBg = useToken(
        'colors',
        useColorModeValueKey('sideBarBg'),
    );
    const disabledColor = useReadableTextColor(sideBarBg, 'gray');
    const availableColor = useReadableTextColor(sideBarBg);
    const activedColor = useColorModeValueKey('primary');
    const activedTextColor = useReadableTextColor(
        useToken('colors', useColorModeValueKey('primary')),
    );
    const primaryHover = useColorModeValueKey('primaryHover');
    const primaryPressed = useColorModeValueKey('primaryPressed');
    const primaryColorValue = useToken('colors', activedColor);
    const naviItemHoverColor = tinycolor(primaryColorValue)
        .setAlpha(0.1)
        .toRgbString();
    const naviItemFocusColor = tinycolor(primaryColorValue)
        .setAlpha(0.2)
        .toRgbString();
    // const naviItemActiveColor = tinycolor(primaryColorValue)
    //     .setAlpha(0.9)
    //     .toRgbString();
    const MenuIcon = open ? ChevronDownIcon : ChevronUpIcon;
    const [setShowMobileDrawer] = useUIStore((state) => [
        state.setShowMobileDrawer,
    ]);
    return (
        <Box
            px={isHorizontal ? 1 : 3}
            rounded={'md'}
            flex={1}
            opacity={isDisabled ? 0.7 : 1}
        >
            <Tooltip
                openDelay={300}
                hasArrow
                label={t(item.labelKey)}
                placement="right"
            >
                <HStack
                    px={3}
                    py={2}
                    rounded={'md'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    _hover={{
                        bg: isSelected ? primaryHover : naviItemHoverColor,
                        _active: {
                            bg: isSelected
                                ? primaryPressed
                                : naviItemFocusColor,
                        },
                    }}
                    bgColor={isSelected ? primaryColorValue : 'transparent'}
                    cursor={isDisabled ? 'not-allowed' : 'pointer'}
                    onClick={
                        isDisabled
                            ? null
                            : onClick
                            ? onClick
                            : () => {
                                  if (item.path) {
                                      if (item.external) {
                                          window.open(item.path, '_blank');
                                      } else {
                                          navigate(item.path);
                                      }
                                      setShowMobileDrawer(false);
                                  } else if (item.onClick) {
                                      item.onClick();
                                  }
                              }
                    }
                    spacing={isHorizontal ? 1 : 2}
                >
                    <HStack
                        alignItems={'center'}
                        spacing={isHorizontal ? 1 : 3}
                    >
                        <Center pl={collapsed?1:0}>
                            {isSubItem && !collapsed ? (
                                <Box ml={4} />
                            ) : item.customIcon ? (
                                item.customIcon
                            ) : isHorizontal ? null : (
                                item.Icon && (
                                    <Icon
                                        as={item.Icon}
                                        boxSize={4}
                                        color={
                                            isSelected
                                                ? activedTextColor
                                                : availableColor
                                        }
                                        // mb={0.5}
                                    />
                                )
                            )}
                        </Center>
                        <MotionText
                            color={
                                isDisabled
                                    ? disabledColor
                                    : isSelected
                                    ? activedTextColor
                                    : availableColor
                            }
                            userSelect="none"
                            fontSize={isSubItem ? 'xs' : 'sm'}
                            fontWeight={isSubItem ? '400' : '400'}
                            noOfLines={1}
                            isTruncated={true}
                            animate={{
                                opacity: collapsed && !isSubItem ? 0 : 1,
                            }}
                            initial={false}
                        >
                            {t(item.labelKey)}
                        </MotionText>
                        {item.new && !collapsed && (
                            <Badge
                                fontWeight={'400'}
                                variant={'outline'}
                                colorScheme="linkedin"
                            >
                                {t('new')}
                            </Badge>
                        )}
                    </HStack>
                    {isMenu && !collapsed && (
                        <MenuIcon
                            boxSize={5}
                            color={
                                isDisabled
                                    ? disabledColor
                                    : isSelected
                                    ? activedTextColor
                                    : availableColor
                            }
                        />
                    )}
                </HStack>
            </Tooltip>
        </Box>
    );
});

export const DrawerMenuItem = memo((props) => {
    const {item, currentPath, kycVerified, collapsed = false} = props;
    // console.log(currentPath);
    const matches = useMatches();
    const isSelected = !isNil(find(matches, (o) => o.pathname === item.path));
    const [open, setOpen] = useState(isSelected);
    if (collapsed) {
        return (
            <Popover placement="right-start">
                {({isOpen, onClose}) => (
                    <>
                        <PopoverTrigger>
                            <Box>
                                <DrawerItem
                                    currentPath={currentPath}
                                    item={item}
                                    isMenu
                                    onClick={() => {}}
                                    open={isOpen}
                                    kycVerified={kycVerified}
                                    collapsed={collapsed}
                                />
                            </Box>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent w={'200px'} placement="right-start">
                                <PopoverArrow />
                                <Stack spacing={2} py={2}>
                                    {item.paths
                                        .filter(
                                            (o) => o.hasPermission !== false,
                                        )
                                        .map((item) => {
                                            return (
                                                <DrawerItem
                                                    key={item.value}
                                                    item={item}
                                                    currentPath={currentPath}
                                                    kycVerified={kycVerified}
                                                    isSubItem
                                                    collapsed={collapsed}
                                                />
                                            );
                                        })}
                                </Stack>
                            </PopoverContent>
                        </Portal>
                    </>
                )}
            </Popover>
        );
    }
    return (
        <Stack spacing={1}>
            <DrawerItem
                currentPath={currentPath}
                item={item}
                onClick={() => setOpen((c) => !c)}
                isMenu
                open={open}
                kycVerified={kycVerified}
                collapsed={collapsed}
            />
            <Collapse in={open} animateOpacity>
                <Stack spacing={1}>
                    {item.paths
                        .filter((o) => o.hasPermission !== false)
                        .map((item) => {
                            return (
                                <DrawerItem
                                    key={item.value}
                                    item={item}
                                    currentPath={currentPath}
                                    kycVerified={kycVerified}
                                    isSubItem
                                    collapsed={collapsed}
                                />
                            );
                        })}
                </Stack>
            </Collapse>
        </Stack>
    );
});

export const MobileNavDrawer = (props) => {
    const [showMobileDrawer, setShowMobileDrawer] = useUIStore((state) => [
        state.showMobileDrawer,
        state.setShowMobileDrawer,
    ]);
    const bgColor = useColorModeValueKey('sideBarBg');
    const onClose = () => setShowMobileDrawer(false);
    return (
        <Drawer
            isOpen={showMobileDrawer}
            placement="left"
            onClose={onClose}
            size={'full'}
        >
            <DrawerOverlay />
            <DrawerContent
                px={0}
                bg={bgColor}
                transition={'background-color 200ms linear'}
            >
                <DrawerCloseButton color={useColorModeValueKey('text')} />
                <DrawerHeader>
                    <Text color={useColorModeValueKey('text')}>
                        {'Gen-X'}
                    </Text>
                </DrawerHeader>
                <DrawerBody px={0}>
                    <NavDrawer isInMobile />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export const NavDrawer = memo(({isInMobile = false}) => {
    const [sideBarOpen] = useUIStore((state) => [state.sideBarOpen]);
    const matches = useMatches();
    const currentPath = get(matches, '[1].pathname');
    const {isUser, isAdminPage, roles} = useAuth();
    const [liveMode] = useUIStore((state) => [state.liveMode]);
    const {isCompany, kycRequired, kycPending, kycVerified, isIB, amlInvalid} =
        (isUser ? useClient : useAdminProfile)();
    const {data: setting} = useUserSetting();
    const {colorMode, toggleColorMode} = useColorMode();
    const {t, i18n} = useTranslation('app');
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const languageList = [
        {
            label: 'englishConsist',
            value: 'en-US',
            county: 'US',
        },
        {
            label: 'chineseConsist',
            value: 'zh-CN',
            county: 'CN',
        },
        {
            label: 'chineseTWConsist',
            value: 'zh-TW',
            county: 'CN',
        },
        // {
        //     label: t('japaneseConsist'),
        //     value: 'ja-JP',
        //     county: 'JP',
        // },
    ];
    const matched = find(languageList, (o) => o.value === i18n.language);
    const config = [
        ...(isUser
            ? navDrawerConfig({
                  isIB,
                  apiEnabled: Boolean(get(setting, 'allowDevelop')),
                  isCompany,
                  amlInvalid,
              })
            : isAdminPage
            ? navDrawerAdminConfig({isInMobile, getPermission})
            : []),
        ...(isInMobile
            ? [
                  {
                      labelKey: 'language',
                      value: 'language',
                      isMenu: true,
                      customIcon: (
                          <CountryIcon country={matched.county} size={16} />
                      ),
                      subMenu: true,
                      paths: languageList.map((item) => ({
                          labelKey: item.label,
                          value: item.value,
                          onClick: () => i18n.changeLanguage(item.value),
                      })),
                  },
                  {
                      labelKey: 'logout',
                      value: 'logout',
                      path: paths.logout,
                      Icon: HiLogout,
                      kycValid: false,
                  },
              ]
            : []),
    ];
    return (
        <Stack position={'relative'} overflow={'auto'}>
            {false ? (
                <Center>
                    <Spinner color={useColorModeValueKey('gray')} />
                </Center>
            ) : (
                <Stack px={0} spacing={1} position="relative">
                    {config
                        .filter((o) => o.hasPermission !== false)
                        .map((item) => {
                            if (item.subMenu) {
                                return (
                                    <DrawerMenuItem
                                        key={item.value}
                                        item={item}
                                        currentPath={currentPath}
                                        kycVerified={kycVerified}
                                        collapsed={
                                            isInMobile ? false : !sideBarOpen
                                        }
                                    />
                                );
                            } else {
                                return (
                                    <DrawerItem
                                        key={item.value}
                                        item={item}
                                        currentPath={currentPath}
                                        kycVerified={kycVerified}
                                        collapsed={
                                            isInMobile ? false : !sideBarOpen
                                        }
                                    />
                                );
                            }
                        })}
                </Stack>
            )}
            {process.env.REACT_APP_DEV_ENV && sideBarOpen && (
                <Stack mt={12} px={6}>
                    <Text color={'white'}>
                        Build ver: {process.env.REACT_APP_BUILD_VERSION}
                    </Text>
                </Stack>
            )}
        </Stack>
    );
});

export const NavBar = (props) => {
    const { logo} = props;
    const [sideBarOpen, setSideBarOpen] = useUIStore((state) => [
        state.sideBarOpen,
        state.setSideBarOpen,
    ]);
    const {isUser, isPlatformAdminPage} = useAuth();
    // const {kycVerified} = (isUser ? useProfile : useAdminProfile)();
    return (
        <MotionStack
            animate={{
                width: sideBarOpen ? '200px' : '64px',
                minWidth: sideBarOpen ? '200px' : '64px',
            }}
            initial={false}
            zIndex={3}
            shadow={'sideBar'}
            position={'relative'}
            transition={'width 200ms linear, min-width 200ms linear'}
        >
            <Stack
                bg={useColorModeValueKey('sideBarBg')}
                transition={'background-color 200ms linear'}
                spacing={3}
                flex={1}
                pt={1}
                overflow={'hidden'}
            >
                <AppLogoArea
                    collapsed={sideBarOpen}
                    logo={logo}
                    isBrokerLogo={ isUser}
                />
                {isUser && (
                    <Stack spacing={3} pb={sideBarOpen?0:2}>
                        <NavBarItem />
                        {/* {kycVerified && <NavBarQuickAccess />} */}
                    </Stack>
                )}
                <NavDrawer />
                <IconButton
                    position={'absolute'}
                    top={5}
                    right={-3}
                    zIndex={3}
                    size="xs"
                    shadow={'lg'}
                    rounded={'full'}
                    icon={<Icon boxSize={'4'} as={CgArrowsExchangeAlt} />}
                    onClick={() => setSideBarOpen(!sideBarOpen)}
                />
            </Stack>
        </MotionStack>
    );
};

export const AdminDrawerMenuItem = memo((props) => {
    const {item, currentPath, kycVerified, isHorizontal} = props;
    return (
        <Popover placement="bottom-end" trigger="hover">
            {({isOpen, onClose}) => (
                <>
                    <PopoverTrigger>
                        <Box>
                            <DrawerItem
                                currentPath={currentPath}
                                item={item}
                                isMenu
                                onClick={() => {}}
                                open={isOpen}
                                kycVerified={kycVerified}
                                collapsed={false}
                                isHorizontal={isHorizontal}
                            />
                        </Box>
                    </PopoverTrigger>
                    <Portal>
                        <PopoverContent w={'200px'} boxShadow={'none'}>
                            <PopoverArrow />
                            <Stack spacing={2} py={2}>
                                {item.paths
                                    .filter((o) => o.hasPermission !== false)
                                    .map((item) => {
                                        return (
                                            <DrawerItem
                                                key={item.value}
                                                item={item}
                                                currentPath={currentPath}
                                                kycVerified={kycVerified}
                                                isSubItem
                                                collapsed={true}
                                                isHorizontal={isHorizontal}
                                            />
                                        );
                                    })}
                            </Stack>
                        </PopoverContent>
                    </Portal>
                </>
            )}
        </Popover>
    );
});

export const AdminNavDrawer = memo(({isInMobile = false}) => {
    const [sideBarOpen] = useUIStore((state) => [state.sideBarOpen]);
    const matches = useMatches();
    const currentPath = get(matches, '[1].pathname');
    const {roles} = useAuth();
    const {isCompany, kycVerified, isIB} = useAdminProfile();
    const {data: setting} = useUserSetting();
    const {t, i18n} = useTranslation('app');
    const {getPermission} = useAdminPermissionSettings({
        roles,
    });
    const languageList = [
        {
            label: 'englishConsist',
            value: 'en-US',
            county: 'US',
        },
        {
            label: 'chineseConsist',
            value: 'zh-CN',
            county: 'CN',
        },
        {
            label: 'chineseTWConsist',
            value: 'zh-TW',
            county: 'CN',
        },
        // {
        //     label: t('japaneseConsist'),
        //     value: 'ja-JP',
        //     county: 'JP',
        // },
    ];
    const matched = find(languageList, (o) => o.value === i18n.language);
    const config = [
        ...navDrawerAdminConfig({isInMobile, getPermission}),
        ...(isInMobile
            ? [
                  {
                      labelKey: 'language',
                      value: 'language',
                      isMenu: true,
                      customIcon: (
                          <CountryIcon country={matched.county} size={16} />
                      ),
                      subMenu: true,
                      paths: languageList.map((item) => ({
                          labelKey: item.label,
                          value: item.value,
                          onClick: () => i18n.changeLanguage(item.value),
                      })),
                  },
                  {
                      labelKey: 'logout',
                      value: 'logout',
                      path: paths.logout,
                      Icon: HiLogout,
                      kycValid: false,
                  },
              ]
            : []),
    ];
    // console.log(config);
    return (
        <HStack overflow={'auto'} w={'100vw'}>
            <HStack px={3} spacing={0}>
                {config
                    .filter((o) => o.hasPermission !== false)
                    .map((item) => {
                        if (item.subMenu) {
                            return (
                                <AdminDrawerMenuItem
                                    key={item.value}
                                    item={item}
                                    currentPath={currentPath}
                                    kycVerified={kycVerified}
                                    collapsed={
                                        isInMobile ? false : !sideBarOpen
                                    }
                                    isHorizontal
                                />
                            );
                        } else {
                            return (
                                <DrawerItem
                                    key={item.value}
                                    item={item}
                                    currentPath={currentPath}
                                    kycVerified={kycVerified}
                                    collapsed={
                                        isInMobile ? false : !sideBarOpen
                                    }
                                    isHorizontal
                                />
                            );
                        }
                    })}
            </HStack>
            {process.env.REACT_APP_DEV_ENV && sideBarOpen && (
                <Stack mt={12} px={6}>
                    <Text color={'white'}>
                        Build ver: {process.env.REACT_APP_BUILD_VERSION}
                    </Text>
                </Stack>
            )}
        </HStack>
    );
});

export const AdminNavBar = () => {
    return (
        <HStack
            overflow={'scroll'}
            zIndex={2}
            width={'100%'}
            height={HEADER_HEIGHT}
            bg={useColorModeValueKey('navBg')}
            boxShadow={'md'}
        >
            <AdminNavDrawer />
        </HStack>
    );
};
