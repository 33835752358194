import {  useMccCode, usePersonTitle } from 'hooks/datahook/common';
import React from 'react';
import Selector from './Selector';

const MccCodeSelector = (props) => {
    const {value, onChangeValue, ...other} = props;
    const {localedTitle} = useMccCode();
    return (
        <Selector
            value={value}
            onChangeValue={onChangeValue}
            options={localedTitle}
            {...other}
        />
    );
};

export default MccCodeSelector;
