import {get} from 'lodash';

export const processCurrency = (rawData) => {
    const isLocal =
        get(rawData, 'type') === 'L' || get(rawData, 'type') === 'IL';
    const isInternational =
        get(rawData, 'type') === 'I' || get(rawData, 'type') === 'IL';
    const canCraeteAccount = isInternational || isLocal;
    return {
        id: get(rawData, 'id'),
        allowConversion: Boolean(get(rawData, 'allowConversion')),
        code: get(rawData, 'code'),
        decimal: get(rawData, 'decimalPlaces'),
        psp: get(rawData, 'psp'),
        regionName: get(rawData, 'regionName'),
        symbol: get(rawData, 'symbol'),
        tier: get(rawData, 'tier'),
        type: get(rawData, 'type'),
        country:
            get(rawData, 'entity') === 'EU_EZ' ? 'EU' : get(rawData, 'entity'),
        isInternational,
        isLocal,
        canCraeteAccount,
        localPsp: get(rawData, 'localPsp'),
        internationalPsp: get(rawData, 'internationalPsp'),
        tradeTime: {
            ...get(rawData, 'tradeTime'),
            // cutoffTime: `${get(rawData, 'tradeTime.cutoffTime')?.split(':')?.[0]}:${get(rawData, 'tradeTime.cutoffTime')?.split(':')?.[1]}` ,
            // openTime: `${get(rawData, 'tradeTime.openTime')?.split(':')?.[0]}:${get(rawData, 'tradeTime.openTime')?.split(':')?.[1]}`,
            cutoffTime: get(rawData, 'tradeTime.cutoffTime'),
            openTime: get(rawData, 'tradeTime.openTime'),
        },
        applyInTransfer: get(rawData, 'tradeTime.applyIn', [])?.includes(
            'transfer',
        ),
        applyInConversion: get(rawData, 'tradeTime.applyIn', [])?.includes(
            'conversion',
        ),
        applyInPayment: get(rawData, 'tradeTime.applyIn', [])?.includes(
            'payment',
        ),
    };
};
