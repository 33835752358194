// import {Image} from '@chakra-ui/react';
import {ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons';
import {
    Badge,
    Box,
    Collapse,
    DarkMode,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    Heading,
    Icon,
    Image,
    Link,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Stack,
    Switch,
    Text,
    Tooltip,
    VStack,
    useColorMode,
    useToken,
} from '@chakra-ui/react';
import Logo from 'assets/images/Logo.png';
import {useAdminProfile, useProfile} from 'hooks/datahook/auth';
import {useUserSetting} from 'hooks/datahook/settings';
import {useAuth} from 'hooks/useAuth';
import {useColorModeValueKey} from 'hooks/useColors';
import {find, findIndex, get, isNil} from 'lodash';
import React, {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {matchPath, useMatches, useNavigate} from 'react-router-dom';
import {
    authPaths,
    navDrawerAdminConfig,
    navDrawerConfig,
    paths,
} from 'routes/path';
import {useUIStore} from 'stores/uiStore';
import tinycolor from 'tinycolor2';
import {HiMoon, HiSun, HiLogout} from 'react-icons/hi';
import CountryIcon from 'components/CountryIcon';
import {HEADER_HEIGHT, designSystemColors} from 'theme';
import {motion} from 'framer-motion';

const MotionText = motion(Text);
const MotionStack = motion(Stack);

// Customize if reuqired
export const AppLogoArea = memo((props) => {
    const {title = 'Gen X'} = props;
    // const {t} = useTranslation('app')
    // return (
    //     <Image boxSize="100px" objectFit="cover" src={Logo} alt="Dan Abramov" />
    // );
    return (
        <HStack spacing={2} width={'100%'} height={HEADER_HEIGHT} px={4}>
            <img src={Logo} style={{height: '40px', objectFit: 'contain'}} />
            {/* <HStack alignItems={'flex-end'}>
                <Heading
                    size="md"
                    bgGradient="linear(to-r, light.primary.400, light.primary.600)"
                    backgroundClip="text"
                    fontWeight={'500'}
                >
                    {title}
                </Heading>
            </HStack> */}
        </HStack>
    );
});

export const DrawerItem = memo((props) => {
    const {
        item,
        currentPath,
        onClick,
        isMenu,
        open,
        kycVerified,
        isSubItem,
        collapsed = false,
    } = props;
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const matches = useMatches();
    const isSelected = !isNil(find(matches, (o) => o.pathname === item.path));
    const isDisabled = item.kycValid ? (kycVerified ? false : true) : false;
    const disabledColor = useColorModeValueKey('gray');
    const availableColor = useColorModeValueKey('sideBarText');
    const activedColor = useColorModeValueKey('primary');
    const activedTextColor = useColorModeValueKey('text');
    const primaryColorValue = useToken('colors', activedColor);
    const naviItemHoverColor = tinycolor(primaryColorValue)
        .setAlpha(0.1)
        .toRgbString();
    const naviItemFocusColor = tinycolor(primaryColorValue)
        .setAlpha(0.2)
        .toRgbString();
    const naviItemActiveColor = tinycolor(primaryColorValue)
        .setAlpha(0.9)
        .toRgbString();
    const MenuIcon = open ? ChevronDownIcon : ChevronUpIcon;
    const [setShowMobileDrawer] = useUIStore((state) => [
        state.setShowMobileDrawer,
    ]);
    return (
        <Box px={3} rounded={'md'} flex={1}>
            <Tooltip
                openDelay={300}
                hasArrow
                label={t(item.labelKey)}
                placement="right"
            >
                <HStack
                    px={3}
                    py={2}
                    rounded={'md'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    _hover={{
                        bg: isSelected
                            ? 'dark.primary.600'
                            : naviItemHoverColor,
                        _active: {
                            bg: isSelected
                                ? 'dark.primary.700'
                                : naviItemFocusColor,
                        },
                    }}
                    bgColor={isSelected ? primaryColorValue : 'transparent'}
                    cursor={isDisabled ? 'not-allowed' : 'pointer'}
                    onClick={
                        isDisabled
                            ? null
                            : onClick
                            ? onClick
                            : () => {
                                  if (item.path) {
                                      navigate(item.path);
                                      setShowMobileDrawer(false);
                                  } else if (item.onClick) {
                                      item.onClick();
                                  }
                              }
                    }
                >
                    <HStack alignItems={'center'} spacing={3}>
                        {isSubItem && !collapsed ? (
                            <Box ml={4} />
                        ) : item.customIcon ? (
                            item.customIcon
                        ) : (
                            item.Icon && (
                                <Icon
                                    as={item.Icon}
                                    boxSize={4}
                                    color={
                                        isDisabled
                                            ? disabledColor
                                            : isSelected
                                            ? activedTextColor
                                            : availableColor
                                    }
                                    mb={0.5}
                                />
                            )
                        )}
                        <MotionText
                            color={
                                isDisabled
                                    ? disabledColor
                                    : isSelected
                                    ? activedTextColor
                                    : availableColor
                            }
                            userSelect="none"
                            fontSize={isSubItem ? 'sm' : 'md'}
                            fontWeight={isSubItem ? '300' : '400'}
                            noOfLines={1}
                            isTruncated={true}
                            animate={{
                                opacity: collapsed && !isSubItem ? 0 : 1,
                            }}
                            initial={false}
                        >
                            {t(item.labelKey)}
                        </MotionText>
                        {item.new && !collapsed && (
                            <Badge
                                fontWeight={'400'}
                                variant={'outline'}
                                colorScheme="linkedin"
                            >
                                {t('new')}
                            </Badge>
                        )}
                    </HStack>
                    {isMenu && !collapsed && (
                        <MenuIcon
                            boxSize={5}
                            color={
                                isDisabled
                                    ? disabledColor
                                    : isSelected
                                    ? activedTextColor
                                    : availableColor
                            }
                        />
                    )}
                </HStack>
            </Tooltip>
        </Box>
    );
});

export const DrawerMenuItem = memo((props) => {
    const {item, currentPath, kycVerified, collapsed = false} = props;
    // console.log(currentPath);
    const matches = useMatches();
    const isSelected = !isNil(find(matches, (o) => o.pathname === item.path));
    const [open, setOpen] = useState(isSelected);
    if (collapsed) {
        return (
            <Popover placement="right-start">
                {({isOpen, onClose}) => (
                    <>
                        <PopoverTrigger>
                            <Box>
                                <DrawerItem
                                    currentPath={currentPath}
                                    item={item}
                                    isMenu
                                    onClick={() => {}}
                                    open={isOpen}
                                    kycVerified={kycVerified}
                                    collapsed={collapsed}
                                />
                            </Box>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent w={'200px'} placement="right-start">
                                <PopoverArrow />
                                <Stack spacing={2} py={2}>
                                    {item.paths.map((item) => {
                                        return (
                                            <DrawerItem
                                                key={item.value}
                                                item={item}
                                                currentPath={currentPath}
                                                kycVerified={kycVerified}
                                                isSubItem
                                                collapsed={collapsed}
                                            />
                                        );
                                    })}
                                </Stack>
                            </PopoverContent>
                        </Portal>
                    </>
                )}
            </Popover>
        );
    }
    return (
        <Stack spacing={1}>
            <DrawerItem
                currentPath={currentPath}
                item={item}
                onClick={() => setOpen((c) => !c)}
                isMenu
                open={open}
                kycVerified={kycVerified}
                collapsed={collapsed}
            />
            <Collapse in={open} animateOpacity>
                <Stack spacing={1}>
                    {item.paths.map((item) => {
                        return (
                            <DrawerItem
                                key={item.value}
                                item={item}
                                currentPath={currentPath}
                                kycVerified={kycVerified}
                                isSubItem
                                collapsed={collapsed}
                            />
                        );
                    })}
                </Stack>
            </Collapse>
        </Stack>
    );
});

export const MobileNavDrawer = (props) => {
    const [showMobileDrawer, setShowMobileDrawer] = useUIStore((state) => [
        state.showMobileDrawer,
        state.setShowMobileDrawer,
    ]);
    const bgColor = useColorModeValueKey('sideBarBg');
    const onClose = () => setShowMobileDrawer(false);
    return (
        <Drawer
            isOpen={showMobileDrawer}
            placement="left"
            onClose={onClose}
            size={'full'}
        >
            <DrawerOverlay />
            <DrawerContent
                px={0}
                bg={bgColor}
                transition={'background-color 200ms linear'}
            >
                <DrawerCloseButton color={useColorModeValueKey('text')} />
                <DrawerHeader>
                    <Text color={useColorModeValueKey('text')}>Gen X</Text>
                </DrawerHeader>
                <DrawerBody px={0}>
                    <NavDrawer isInMobile />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export const NavDrawer = memo(({isInMobile = false}) => {
    const [sideBarOpen] = useUIStore((state) => [state.sideBarOpen]);
    const primary = useColorModeValueKey('primary');
    const matches = useMatches();
    const currentPath = get(matches, '[1].pathname');
    const {isUser, isSale, isDealer, isAdmin, logout} = useAuth();
    const {isCompany, kycRequired, kycPending, kycVerified} = (
        isUser ? useProfile : useAdminProfile
    )();
    const {data: setting} = useUserSetting();
    const {colorMode, toggleColorMode} = useColorMode();
    const {t, i18n} = useTranslation('app');
    const languageList = [
        {
            label: 'englishConsist',
            value: 'en-US',
            county: 'US',
        },
        {
            label: 'chineseConsist',
            value: 'zh-CN',
            county: 'CN',
        },
        {
            label: 'chineseTWConsist',
            value: 'zh-TW',
            county: 'CN',
        },
    ];
    const matched = find(languageList, (o) => o.value === i18n.language);
    const config = [
        ...(isUser
            ? navDrawerConfig(
                  isCompany,
                  kycRequired || kycPending,
                  isInMobile,
                  Boolean(get(setting, 'allowDevelop')),
              )
            : navDrawerAdminConfig()),
        ...(isInMobile
            ? [
                  {
                      labelKey: colorMode === 'light' ? 'dayMode' : 'nightMode',
                      value: 'darkMode',
                      onClick: toggleColorMode,
                      Icon: colorMode === 'light' ? HiMoon : HiSun,
                  },
                  {
                      labelKey: 'language',
                      value: 'language',
                      isMenu: true,
                      customIcon: (
                          <CountryIcon country={matched.county} size={16} />
                      ),
                      subMenu: true,
                      paths: languageList.map((item) => ({
                          labelKey: item.label,
                          value: item.value,
                          onClick: () => i18n.changeLanguage(item.value),
                      })),
                  },
                  {
                      labelKey: 'logout',
                      value: 'logout',
                      path: authPaths.logout,
                      Icon: HiLogout,
                      kycValid: false,
                  },
              ]
            : []),
    ];
    return (
        <Stack position={'relative'} overflow={'auto'}>
            <Stack px={0} spacing={1} position="relative">
                {config.map((item) => {
                    if (item.subMenu) {
                        return (
                            <DrawerMenuItem
                                key={item.value}
                                item={item}
                                currentPath={currentPath}
                                kycVerified={kycVerified}
                                collapsed={isInMobile ? false : !sideBarOpen}
                            />
                        );
                    } else {
                        return (
                            <DrawerItem
                                key={item.value}
                                item={item}
                                currentPath={currentPath}
                                kycVerified={kycVerified}
                                collapsed={isInMobile ? false : !sideBarOpen}
                            />
                        );
                    }
                })}
            </Stack>
            {process.env.REACT_APP_DEV_ENV && sideBarOpen && (
                <Stack mt={12} px={6}>
                    <Text color={'white'}>
                        Build ver: {process.env.REACT_APP_BUILD_VERSION}
                    </Text>
                </Stack>
            )}
        </Stack>
    );
});
