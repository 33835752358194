import {get} from 'lodash';

export const processSystemEvent = (rawData) => {
    return {
        id: get(rawData, 'code'),
        name: get(rawData, 'name'),
        code: get(rawData, 'code'),
        description: '',
        params:
            typeof get(rawData, 'params') === 'string'
                ? JSON.parse(get(rawData, 'params', '[]'))
                : get(rawData, 'params'),
        rawData,
    };
};

export const processEmailTemplate = (rawData) => {
    console.log(rawData)
    return {
        id: get(rawData, 'id'),
        createdAt: get(rawData, 'createdAt'),
        templateName: get(rawData, 'code'),
        template: get(rawData, 'htmlUrl'),
        rawData,
    };
};


export const processAdminEmailSetting = (rawData) => {
    const subjects = {};
    const templates = {};
    const templateDetail = {}
    console.log(rawData, 'rawData')
    get(rawData, ':contents', []).forEach((element) => {
        subjects[element.locale] = element.code;
        templates[element.locale] = element.content;
        templateDetail[element.locale] = element.htmlUrl
    });
    return {
        id: get(rawData, 'id'),
        createdAt: get(rawData, 'createdAt'),
        name: get(rawData, 'code'),
        event: get(rawData, 'event'),
        enable: get(rawData, 'enabled')?true:false,
        eventDetail: processSystemEvent(get(rawData, ':event')),
        template: get(rawData, 'emailTemplate'),
        templateDetail,
        templates,
        subjects,
    };
};