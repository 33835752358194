import React, {useEffect, useState} from 'react';
import FormWrapper from './FormWrapper';
import MultipleSelector from 'components/MultipleSelector';

const MultipleOptionSelector = (props)=>{

    const {
        formik,
        i18n,
        index,
        name,
        disabled,
        readOnly,
        options = [],
        inputProps,
        helper,
        label,
    } = props;
    const value = formik.values[name] || [];

    return (
        <FormWrapper {...props}>
            <MultipleSelector
                value={value}
                onChangeValue={(value) => formik.setFieldValue(name, value)}
                options={options}
                isDisabled={disabled}
            />
        </FormWrapper>
    );
}

export default React.memo(MultipleOptionSelector, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.readOnly === next.readOnly &&
        prev.i18n === next.i18n
    );
});