import {get} from 'lodash';
import {processAddress} from './common';
import {processEstimate} from './estimate';
import {processContact} from './contact';
import {max} from 'date-fns';
import {isPlatformAdminRole} from '../hooks/useAuth';


export const processPerson = (rawData) => {
    const phoneCode = get(rawData, 'phoneCode') || '';
    const phoneNo = get(rawData, 'phoneNo') || '';
    const isAdminRole =
        isPlatformAdminRole(get(rawData, ':roles') || []) 

    return {
        id: get(rawData, 'id'),
        title: get(rawData, 'title', ''),
        email: get(rawData, 'email', ''),
        firstname: get(rawData, 'firstname', ''),
        lastname: get(rawData, 'lastname', ''),
        middlename: get(rawData, 'middlename', ''),
        legalName: get(rawData, 'legalName', ''),
        name: get(rawData, 'name'),

        firstNameNative: get(rawData, 'nativeLanguageFirstname', ''),
        middleNameNative: get(rawData, 'nativeLanguageMiddlename', ''),
        lastNameNative: get(rawData, 'nativeLanguageLastname', ''),
        occupation: get(rawData, 'occupation', ''),
        referral: get(rawData, 'referral', ''),
        dob: get(rawData, 'dob'),
        primaryDocumentFrontSide: get(rawData, 'frontPhotoOfPrimary', ''),
        primaryDocumentBackSide: get(rawData, 'backPhotoOfPrimary', ''),
        secondaryIdentificationDocument: get(rawData, 'photoOfSecondary', ''),
        additionalPhotoIdentification: get(rawData, 'photoOfAdditional', ''),
        countryOfResidence: get(rawData, ':address.country', ''),
        phoneCode,
        phoneNo,
        phone: `${phoneCode}@${phoneNo}`,
        mobile: get(rawData, 'mobile'),
        mccCode: get(rawData, 'mccCode'),
        ...processAddress(get(rawData, ':address'), 'individual'),

        region: get(rawData, 'region'),
        kycStatus: get(rawData, 'kycStatus'),
        position: get(rawData, 'position'),
        reason: get(rawData, 'reason'),
        company: get(rawData, 'company'),
        estimate: get(rawData, 'estimate'),
        estimateDetail: processEstimate(get(rawData, ':estimate')),
        kycExpireDate: get(rawData, 'kycExpireDate'),
        kycRenewExpireDate: get(rawData, 'kycRenewExpireDate'),
        kycStatusVerifiedTimestamp: get(rawData, 'kycStatusVerifiedTimestamp'),
        kycStatusSuspendedTimestamp: get(
            rawData,
            'kycStatusSuspendedTimestamp',
        ),
        kycStatusReturnedTimestamp: get(rawData, 'kycStatusReturnedTimestamp'),
        kycStatusRenewTimestamp: get(rawData, 'kycStatusRenewTimestamp'),
        kycStatusRenewPendingTimestamp: get(
            rawData,
            'kycStatusRenewPendingTimestamp',
        ),
        kycStatusPendingTimestamp: get(rawData, 'kycStatusPendingTimestamp'),
        kycStatusDraftTimestamp: get(rawData, 'kycStatusDraftTimestamp'),
        kycStatusDeclinedTimestamp: get(rawData, 'kycStatusDeclinedTimestamp'),
        kycLastUpdatedTimestamp:
            get(rawData, 'kycStatusVerifiedTimestamp') ||
            get(rawData, 'kycStatusSuspendedTimestamp') ||
            get(rawData, 'kycStatusReturnedTimestamp') ||
            get(rawData, 'kycStatusRenewTimestamp') ||
            get(rawData, 'kycStatusRenewPendingTimestamp') ||
            get(rawData, 'kycStatusPendingTimestamp') ||
            get(rawData, 'kycStatusDraftTimestamp') ||
            get(rawData, 'kycStatusDeclinedTimestamp')
                ? max(
                      [
                          get(rawData, 'kycStatusVerifiedTimestamp'),
                          get(rawData, 'kycStatusSuspendedTimestamp'),
                          get(rawData, 'kycStatusReturnedTimestamp'),
                          get(rawData, 'kycStatusRenewTimestamp'),
                          get(rawData, 'kycStatusRenewPendingTimestamp'),
                          get(rawData, 'kycStatusPendingTimestamp'),
                          get(rawData, 'kycStatusDraftTimestamp'),
                          get(rawData, 'kycStatusDeclinedTimestamp'),
                      ]
                          .filter((d) => d)
                          .map((d) => new Date(d)),
                  ).toISOString()
                : null,
        contact: get(rawData, 'contact'),
        contactDetail: processContact(
            Object.assign({}, get(rawData, ':contact'), {
                ':address': get(rawData, ':address'),
            }),
        ),
        broker: get(rawData, 'broker'),
        steps: get(rawData, ':steps', []),
        client: get(rawData, 'client'),

        isLR: Boolean(get(rawData, 'isLR')),
        isAP: Boolean(get(rawData, 'isAP')),

        amlStatus: get(rawData, 'amlStatus'),
        amlStatusValidTimestamp: get(rawData, 'amlStatusValidTimestamp'),
        amlStatusInvalidTimestamp: get(rawData, 'amlStatusInvalidTimestamp'),
        amlStatusUnverifiedTimestamp: get(
            rawData,
            'amlStatusUnverifiedTimestamp',
        ),
        amlStatusPendingTimestamp: get(rawData, 'amlStatusPendingTimestamp'),
        amlLastUpdatedTimestamp:
            get(rawData, 'amlStatusValidTimestamp') ||
            get(rawData, 'amlStatusInvalidTimestamp') ||
            get(rawData, 'amlStatusUnverifiedTimestamp') ||
            get(rawData, 'amlStatusPendingTimestamp')
                ? max(
                      [
                          get(rawData, 'amlStatusValidTimestamp'),
                          get(rawData, 'amlStatusInvalidTimestamp'),
                          get(rawData, 'amlStatusUnverifiedTimestamp'),
                          get(rawData, 'amlStatusPendingTimestamp'),
                      ]
                          .filter((d) => d)
                          .map((d) => new Date(d)),
                  ).toISOString()
                : null,
        roles: get(rawData, ':roles'),
        isAdminRole,
        rawData,
    };
};
