import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {find, isNil} from 'lodash';
import {useColorModeValueKey} from 'hooks/useColors';
import {
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputRightElement,
    InputLeftElement as ChakraInputLeftElement,
    Spinner,
    Stack,
    Popover,
    PopoverContent,
    PopoverBody,
    PopoverCloseButton,
    PopoverAnchor,
    Box,
    Center,
    Text,
    Button,
    useColorModeValue,
} from '@chakra-ui/react';
import {ChevronDownIcon, CloseIcon} from '@chakra-ui/icons';
import {useMeasure} from 'react-use';

const Selector = (props) => {
    const {
        value,
        onChangeValue,
        placeholder,
        options,
        isLoading,
        flex=1,
        customRenderItem,
        customRenderInputRightElement,
        customRenderInputLeftElement,
        isDisabled,
        InputLeftElement,
        allowNoMatch = false,
        allowClear=true,
        ...other
    } = props;
    const {t} = useTranslation('app');
    const grayColor = useColorModeValueKey('gray');
    const textColor = useColorModeValueKey('text');
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const inputRef = useRef(false);
    const [inputLayoutRef, {width}] = useMeasure();
    const seachedOptions = useMemo(() => {
        if (search) {
            return options.filter((option) =>
                option.label.toLowerCase().includes(search.toLowerCase()),
            );
        }
        return options;
    }, [search, isOpen, options]);

    const matchedItem = find(options, {value});

    const showLeftElement =
        InputLeftElement || (customRenderInputLeftElement && matchedItem);
    return (
        <Stack flex={flex}>
            <Popover
                placement="bottom-start"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                isLazy
                closeOnBlur={false}
                autoFocus={false}
            >
                <PopoverAnchor>
                    <InputGroup size={'sm'} ref={inputLayoutRef}>
                        {showLeftElement && (
                            <ChakraInputLeftElement>
                                <HStack spacing={1} alignItems={'center'}>
                                    {InputLeftElement}
                                    {customRenderInputLeftElement
                                        ? customRenderInputLeftElement(
                                              matchedItem,
                                          )
                                        : null}
                                </HStack>
                            </ChakraInputLeftElement>
                        )}
                        <Input
                            value={search}
                            ref={inputRef}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                if (e.target.value?.length >= 3) {
                                    setIsOpen(true);
                                }
                            }}
                            variant={'outline'}
                            placeholder={
                                matchedItem?.label || placeholder || t('search')
                            }
                            _placeholder={{
                                color: matchedItem ? textColor : grayColor,
                            }}
                            flex={1}
                            isDisabled={isDisabled}
                            onFocus={() => {
                                if (search?.length >= 2) {
                                    setIsOpen(true);
                                }
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    // if ((search?.length || 0) === 0) {
                                    //     onChangeValue(null);
                                    //     console.log(1);
                                    //     setSearch('');
                                    // } else
                                    if (seachedOptions.length == 1) {
                                        setSearch('');
                                        onChangeValue(seachedOptions[0].value);
                                    } else if (
                                        allowNoMatch &&
                                        seachedOptions.length == 0
                                    ) {
                                        onChangeValue(search);
                                    } else if (seachedOptions.length == 0) {
                                        setTimeout(() => {
                                            setSearch('');
                                        }, 100);
                                    }
                                    setIsOpen(false);
                                }, 50);
                            }}
                            {...other}
                        />
                        <InputRightElement width={'auto'} mr={1}>
                            <HStack
                                alignItems={'center'}
                                justifyContent={'center'}
                                spacing={1}
                            >
                                {customRenderInputRightElement
                                    ? customRenderInputRightElement(matchedItem)
                                    : null}
                                {matchedItem && allowClear && (
                                    <IconButton
                                        variant={'ghost'}
                                        isDisabled={isDisabled}
                                        onClick={() => onChangeValue(null)}
                                        size="xs"
                                        icon={
                                            <CloseIcon
                                                boxSize={'10px'}
                                                color={grayColor}
                                            />
                                        }
                                    />
                                )}
                                {isLoading ? (
                                    <Spinner
                                        size="sm"
                                        color={useColorModeValueKey('primary')}
                                    />
                                ) : (
                                    <IconButton
                                        isDisabled={isDisabled}
                                        onClick={() => setIsOpen((c) => !c)}
                                        size="xs"
                                        variant={'ghost'}
                                        icon={
                                            <ChevronDownIcon
                                                fontSize={'20px'}
                                            />
                                        }
                                    />
                                )}
                            </HStack>
                        </InputRightElement>
                    </InputGroup>
                </PopoverAnchor>
                <PopoverContent
                    width={width}
                    overflow={'hidden'}
                    boxShadow={'lg'}
                    borderColor={useColorModeValueKey('border')}
                >
                    <PopoverBody
                        px={0}
                        py={0}
                        bg={useColorModeValueKey('brighterBg')}
                        transition={'all 200ms linear'}
                    >
                        <Stack maxH={'300px'} overflow={'scroll'} spacing={0}>
                            {seachedOptions.length > 0 ? (
                                seachedOptions.map((option) => (
                                    <Stack
                                        cursor={'pointer'}
                                        onClick={() => {
                                            onChangeValue(option.value);
                                            setSearch('');
                                            setIsOpen(false);
                                        }}
                                        key={option.value}
                                        px={3}
                                        py={2}
                                        _hover={{
                                            bg: useColorModeValueKey(
                                                'menuHover',
                                            ),
                                            _active: {
                                                bg: useColorModeValueKey(
                                                    'menuActive',
                                                ),
                                            },
                                        }}
                                        disabled={option.disabled}
                                    >
                                        <Stack key={option.value}>
                                            {customRenderItem ? (
                                                customRenderItem(option)
                                            ) : (
                                                <Text
                                                    fontSize={'sm'}
                                                    color={
                                                        option.disabled
                                                            ? grayColor
                                                            : undefined
                                                    }
                                                >
                                                    {option.label}
                                                </Text>
                                            )}
                                        </Stack>
                                    </Stack>
                                ))
                            ) : (
                                <Center px={3} py={2}>
                                    <Text color={grayColor}>
                                        {t('noResult')}
                                    </Text>
                                </Center>
                            )}
                        </Stack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Stack>
    );
};

export default Selector;
