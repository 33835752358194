import {useRef} from 'react';
import {usePopover, Overlay, DismissButton} from '@react-aria/overlays';
import {Box, useColorModeValue} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';

export const AriaPopover = (props) => {
    const ref = useRef(null);
    const {state, children} = props;

    const {popoverProps, underlayProps} = usePopover(
        {
            ...props,
            popoverRef: ref,
        },
        state,
    );

    // Add a hidden <DismissButton> component at the end of the popover
    // to allow screen reader users to dismiss the popup easily.
    return (
        <Overlay>
            <div {...underlayProps} style={{display: 'fixed', inset: 0}} />
            <Box
                {...popoverProps}
                ref={ref}
                background={useColorModeValueKey('brighterBg')}
                border="0.5px solid"
                borderColor={useColorModeValueKey('border')}
                rounded={'md'}
                position="absolute"
                zIndex="2"
                top="100%"
                boxShadow="lg"
                marginTop="2"
                padding="4"
                outline="none"
                overflow="auto"
            >
                <DismissButton onDismiss={state.close} />
                {children}
                <DismissButton onDismiss={state.close} />
            </Box>
        </Overlay>
    );
};
