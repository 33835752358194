import React from 'react';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from '@chakra-ui/react';
import {ChevronRightIcon} from '@chakra-ui/icons';
import {useColorModeValueKey} from 'hooks/useColors';
import {useMatches, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useUIStore} from 'stores/uiStore';

const CustomBreadcrumb = () => {
    const gray = useColorModeValueKey('gray');
    const navigate = useNavigate();
    const matches = useMatches();
    const lastBreadcrumbText = useUIStore((state) => state.lastBreadcrumbText);
    const {t} = useTranslation('app');
    const crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) =>
            match.handle.crumb({
                data: match.data,
                params: match.params,
                i18n: t,
            }),
        );

    return (
        <Breadcrumb
            spacing="4px"
            separator={<ChevronRightIcon color={gray} />}
            fontSize={'xs'}
            color={gray}
        >
            {crumbs.map((item, index) => (
                <BreadcrumbItem key={index}>
                    <BreadcrumbLink
                        // href={item.path}
                        onClick={() => navigate(item.path)}
                        isCurrentPage={index === crumbs.length - 1}
                    >
                        {index == crumbs.length - 1 && lastBreadcrumbText
                            ? lastBreadcrumbText
                            : item.label}
                    </BreadcrumbLink>
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
};

export default CustomBreadcrumb;
