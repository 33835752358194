import {Center, Text} from '@chakra-ui/react';
import {useColorModeValueKey} from 'hooks/useColors';
import React from 'react';

const DefaultLogo = (props) => {
    const {small = false} = props;
    return (
        <Center
            rounded={'md'}
            borderWidth={1}
            borderColor={useColorModeValueKey('border')}
            p={1}
            w={'100%'}
            h={'100%'}
            maxH={small ? '40px' : '50px'}
            maxW={small ? '40px' : '200px'}
        >
            <Text fontSize={small ? 'sm' : 'lg'}>PH</Text>
        </Center>
    );
};

export default DefaultLogo;
