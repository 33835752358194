import {isNil} from 'lodash';
import React, {useEffect} from 'react';
import FormWrapper from './FormWrapper';
import Selector from 'components/CitiesSelector';
import { useUpdateEffect } from 'ahooks';

const CitiesSelector = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        required = false,
        disabled,
        stateName,
        countryName,
        readOnly,
        helper,
    } = props;
    const value = formik.values[name];
    const state = formik.values[stateName] || null;
    const country = formik.values[countryName] || null;
    useUpdateEffect(() => {
        //clear when country changed
        formik.setFieldValue(name, '');
    }, [state, countryName]);
    return (
        <FormWrapper {...props}>
            <Selector
                value={value}
                onChangeValue={(value) => formik.setFieldValue(name, value)}
                isDisabled={disabled || isNil(state)}
                state={state}
                country={country}
                placeholder={
                    isNil(state) ? i18n('pleaseSelectStateFirst') : null
                }
            />
        </FormWrapper>
    );
};

// export default CitiesSelector;

export default React.memo(CitiesSelector, (prev, next) => {
    const name = prev.name;
    const stateName = prev.stateName;
    const country = prev.country;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.values[stateName] === next.formik.values[stateName] &&
        prev.formik.values[country] === next.formik.values[country] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.i18n === next.i18n
    );
});
