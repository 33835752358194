import CurrencySelector from 'components/CurrencySelector';
import {add, isNil, split} from 'lodash';
import React from 'react';
import FormWrapper from './FormWrapper';
import { HiXMark } from 'react-icons/hi2';
import { HStack, IconButton, Stack, Text } from '@chakra-ui/react';

const Chip = (props) => {
    const {value, onDelete} = props;
    return (
        <HStack alignItems={'center'} borderWidth={1} rounded={'md'} borderColor="gray.500" px={2} py={0.5}>
            <Text fontSize={'sm'}>{value}</Text>
            <IconButton size='xs' onClick={onDelete}>
                <HiXMark />
            </IconButton>
        </HStack>
    );
};

const EstimateCurrenciesInput = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        label,
        title,
        serverValidator,
        inputProps,
        textArea = false,
        disabled,
        readOnly,
    } = props;
    // console.log(from, to);
    const v = formik.values[name];
    const addedV = v==''||isNil(v)?[]: split(v, ',')
    const onDelete = (v)=>{
        const newV = addedV.filter((item)=>item!==v);
        formik.setFieldValue(name, newV.join(','));
    }
    return (
        <FormWrapper {...props}>
            <Stack spacing={2}>
                <HStack spacing={2}>
                    {addedV.map((v,index) => (
                        <Chip index={index} value={v} onDelete={() => onDelete(v)} />
                    ))}
                </HStack>
                <Stack>
                    <CurrencySelector
                        flex={1}
                        isDisabled={disabled}
                        value={null}
                        onValueChange={(value) => {
                            if(v){
                                formik.setFieldValue(name, `${v},${value}`);
                            }else{
                                formik.setFieldValue(name, value);
                            }
                        }}
                        excluedCurrencies={['AUD.L',...addedV]}
                    />
                </Stack>
            </Stack>
        </FormWrapper>
    );
};
export default EstimateCurrenciesInput;
// export default React.memo(TransferNeedsInput, (prev, next) => {
//     const name = prev.name;
//     return (
//         prev.formik.values[name] === next.formik.values[name] &&
//         prev.formik.errors[name] === next.formik.errors[name] &&
//         prev.formik.touched[name] === next.formik.touched[name] &&
//         prev.disabled === next.disabled &&
//         prev.readOnly === next.readOnly
//     );
// });
