import appSettings from 'config/app';
import {get} from 'lodash';
import HttpClient from 'utils/HttpClient';

const client = new HttpClient(appSettings.apiEndpoint);
export const authClient = new HttpClient(appSettings.authApiEndpoint);
export const authAdminClient = new HttpClient(appSettings.adminAuthApiEndpoint);
export const adminClient = new HttpClient(appSettings.adminApiEndpoint);
export const saleClient = new HttpClient(appSettings.saleApiEndpoint);
export const explorerClient = new HttpClient(appSettings.explorerApiEndpoint);

export const isPlatformAdminRole = (roles) => {
    return (
        roles.includes('PLATFORM_ADMIN') ||
        roles.includes('PLATFORM_DEALER') ||
        roles.includes('PLATFORM_SALE') ||
        roles.includes('PLATFORM_TREASURY') ||
        roles.includes('PLATFORM_SUPPORT') ||
        roles.includes('PLATFORM_FINANCE') ||
        roles.includes('PLATFORM_MARKETING') ||
        roles.includes('PLATFORM_RISK_AND_COMPLIANCE') ||
        roles.includes('PLATFORM_READONLY')
    );
};

export const isUserRole = (roles) => {
    return (
        roles.includes('CLIENT_USER') ||
        roles.includes('CLIENT_COMPANY_ADMIN') ||
        roles.includes('CLIENT_COMPANY_OPERATOR') ||
        roles.includes('CLIENT_COMPANY_CONTACT') ||
        roles.includes('CLIENT_COMPANY_READONLY')
    );
};

if (localStorage.getItem(`${process.env.REACT_APP_STORE_PREFIX}-authStore`)) {
    const state = JSON.parse(
        localStorage.getItem(`${process.env.REACT_APP_STORE_PREFIX}-authStore`),
    )?.state;
    const token = state.token;
    const isAdmin = isPlatformAdminRole(get(state, 'user.roles', []))
    const isUser = isUserRole(get(state, 'user.roles', []))
    if (token) {
        authClient.onSending = (req) => {
            req.set('Authorization', `Bearer ${token}`);
        };
        if (isUser) {
            authClient.onSending = (req) => {
                req.set('Authorization', `Bearer ${token}`);
            };
            client.onSending = (req) => {
                req.set('Authorization', `Bearer ${token}`);
            };
            explorerClient.onSending = (req) => {
                req.set('Authorization', `Bearer ${token}`);
            };
        }
        if (isAdmin) {
            authAdminClient.onSending = (req) => {
                req.set('Authorization', `Bearer ${token}`);
            };
            adminClient.onSending = (req) => {
                req.set('Authorization', `Bearer ${token}`);
            };
        }
        // if (isSale) {
        //     saleClient.onSending = (req) => {
        //         req.set('Authorization', `Bearer ${token}`);
        //     };
        //     authClient.onSending = (req) => {
        //         req.set('Authorization', `Bearer ${token}`);
        //     };
        // }
    }
}

export default client;
