import {
    useSWRPaginationTemplate,
    useSWRTemplate,
} from 'hooks/dataHooksTemplate';
import {useAuth} from 'hooks/useAuth';
import {get} from 'lodash';
import {cleanObjectNilValue} from 'utils/clean';
import {api} from './common';
import {processMessage} from 'data/message';

export const useMessage = (options = {}) => {
    const {request = true, type} = options;
    const {id} = useAuth();
    const {mutate: refreshMessgeCount} = useUnreadMessageCount();
    const {mutate: refreshCurrentCount} = useUnreadMessageCountType();

    const swr = useSWRPaginationTemplate({
        key: id && request ? ['message', id, type] : null,
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _id, _type] = _key;

            const {result} = await api().get(
                ['message'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    type: _type,
                }),
            );

            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items').map(processMessage),
                },
            };
        },
        defaultValue: [],
        // pageSize:30,
    });
    return {
        ...swr,
        markReadFlag_: async (id) => {
            swr.mutate((data) => {
                console.log(data);
                return {
                    ...data,
                    data: data.data.map((item, i) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                readFlag: true,
                            };
                        } else {
                            return item;
                        }
                    }),
                };
            }, false);
            await markReadFlag_(id);
            refreshCurrentCount();
            refreshMessgeCount();
        },
        markReadAllFlag_: async () => {
            await markReadAllFlag_();
            swr.mutate();
            refreshCurrentCount();
            refreshMessgeCount();
        },
    };
};

export const markReadFlag_ = async (id) => {
    const {result} = await api().put(['message', id]);
};

export const markReadAllFlag_ = async () => {
    const {result} = await api().post(['message']);
};

export const useUnreadMessageCount = () => {
    return useSWRTemplate({
        key: ['unRead-message-count'],
        request: async (_key) => {
            const [_] = _key;
            const {result} = await api().get(['unRead-message-count']);
            return result;
        },
        defaultValue: 0,
    });
};
export const useUnreadMessageCountType = () => {
    const swr = useSWRTemplate({
        key: ['unRead-message-count-type'],
        request: async (_) => {
            const {result} = await api().get(['unRead-message-count-type']);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};
