import React, {useEffect} from 'react';
import FormWrapper from './FormWrapper';
import {isFunction, isNil} from 'lodash';
import BtnGroup from 'components/BtnGroup';

const ButtonGroup = (props) => {
    const {formik, i18n, index, name, disabled, accept, options} = props;
    const value = formik.values[name];
    const ops = isFunction(options) ? options(formik.values) : options;
    const currentObj = ops.find((item) => item.value === value);
    useEffect(() => {
        if (isNil(currentObj) || currentObj.disabled) {
            formik.setFieldValue(name, null);
        }
    }, [currentObj]);
    return (
        <FormWrapper {...props}>
            {ops.length > 0 ? (
                <BtnGroup
                    selected={value}
                    onSelect={(value) => formik.setFieldValue(name, value)}
                    btns={ops}
                    nullable
                    variant="filled"
                />
            ) : (
                <Text>{i18n('noAvailableOption')}</Text>
            )}
        </FormWrapper>
    );
};

export default ButtonGroup;
