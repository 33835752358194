import {useRegion} from 'hooks/datahook/common';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Selector from './Selector';
import {Box, HStack, Text} from '@chakra-ui/react';
import CountryIcon from './CountryIcon';
import {useColorModeValueKey} from 'hooks/useColors';
import {get} from 'lodash';

const CountrySelector = (props) => {
    const {value, onChangeValue, excluedCounties, ...other} = props;
    const {t} = useTranslation('app');
    const {localedCountries} = useRegion();
    const displayCountries = excluedCounties
        ? localedCountries.filter(
              (country) => !excluedCounties.includes(country.value),
          )
        : localedCountries;
    const grayColor = useColorModeValueKey('gray');
    const customRenderItem = (option) => {
        return (
            <HStack alignItems={'flex-start'} spacing={2}>
                <Box mt={1}>
                    <CountryIcon size={20} country={get(option, 'code')} />
                </Box>
                <Text
                    fontSize={'sm'}
                    color={option.disabled ? grayColor : undefined}
                >
                    {option.label}
                </Text>
            </HStack>
        );
    };
    const customRenderInputLeftElement = (option) => {
        if (option) {
            return <CountryIcon size={20} country={get(option, 'code')} />;
        } else {
            return null;
        }
    };
    return (
        <Selector
            value={value}
            onChangeValue={onChangeValue}
            options={displayCountries}
            customRenderItem={customRenderItem}
            customRenderInputLeftElement={customRenderInputLeftElement}
            {...other}
        />
    );
};

export default CountrySelector;
