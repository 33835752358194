import {companyUserPermissionSettings} from "constant/companyUserPermissions";
import {useCallback, useMemo} from "react";
import {get, isNil, keyBy, keys, some, split} from "lodash";


const processSub = (item) => {
    if (item?.sub) {
        return {
            ...item,
            sub: keyBy(item.sub.map(processSub), 'key'),
        };
    } else {
        return item;
    }
};

export const useClientPermissionSettings = (options = {}) => {
    const {roles = []} = options;
    const swr = {
        data: companyUserPermissionSettings,
        isLoading: false,
        isValidating: false,
        error: null,
    };

    const searchOptPermission = useMemo(
        () =>
            keys(get(swr, 'data', {})).reduce((prev, curr) => {
                return {
                    ...prev,
                    [curr]: keyBy(
                        get(swr, `data.${curr}`, []).map(processSub),
                        'key',
                    ),
                };
            }, {}),
        [swr.data],
    );
    return {
        ...swr,
        getPermission: useCallback(
            (key, type = 'read') => {
                return some(
                    roles.map((role) => {
                        const setting = get(searchOptPermission, role);
                        if (isNil(setting)) {
                            return false;
                        }
                        const splitKeys = split(key, '.');
                        const permission = get(
                            setting,
                            `${splitKeys.join('.sub.')}.${type}`,
                            false,
                        );
                        return permission;
                    }),
                );
            },
            [searchOptPermission],
        ),
    };
};