import * as React from "react";
import { get } from "lodash";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ImageViewer = (props) => {
    const {images,open,onClose} = props
	const [photoIndex, setPhotoIndex] = React.useState(0);
	return (
		open && (
			<Lightbox
				mainSrc={images[photoIndex]}
				nextSrc={images[(photoIndex + 1) % images.length]}
				prevSrc={
					images[(photoIndex + images.length - 1) % images.length]
				}
				onCloseRequest={onClose}
				onMovePrevRequest={() =>
					setPhotoIndex((c) => c + images.length - 1) % images.length
				}
				onMoveNextRequest={() =>
					setPhotoIndex((c) => (c + 1) % images.length)
				}
			/>
		)
	);
};

export default ImageViewer;
