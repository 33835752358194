import {get, pick, split, take, upperCase} from 'lodash';
import {processSetting} from './settings';

export const processUserProfile = (rawData) => {
    try {
        const roleData =
            get(rawData, ':roles')?.length > 1
                ? (get(rawData, ':roles') || []).find(
                      (d) => d?.role !== 'BROKER_USER',
                  )
                : (get(rawData, ':roles') || [])?.[0];
        const roles = get(rawData, ':roles', []).map((item) => item.role);

        return {
            source: get(rawData, 'source'),
            id: get(rawData, 'client'),
            clientId: get(rawData, 'client'),
            userId: get(rawData, 'id'),
            avatar: get(rawData, 'avatar'),
            email: get(rawData, 'email') || '',
            mobile: get(rawData, 'mobile') || '',
            status: get(rawData, 'status'),
            setting: processSetting(get(rawData, ':setting')),
            createdAt: get(rawData, 'createdAt'),
            name: get(rawData, 'name'),
            role: roleData?.role,
            roles: roles,
            reference: get(rawData, 'reference'),
            rawData: rawData,
        };
    } catch (error) {
        console.log(error);
    }
};
