import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import { colorByMode } from 'theme/themeHelper';

const baseStyle = defineStyle((props) => {
    return {
        color: colorByMode('text', props.colorMode),
    };
});


const headingTheme = defineStyleConfig({
    baseStyle,
});
export default headingTheme;
