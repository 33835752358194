import {isEmpty, isNil, isObject, split} from 'lodash';
import {last} from 'lodash';
import generalClient from 'superagent';
import {useTranslation} from 'react-i18next';
import {
    useSWRTemplate,
    useSWRPaginationTemplate,
} from 'hooks/dataHooksTemplate';
import {sortBy} from 'lodash';
import {get} from 'lodash';
import client, {
    adminClient,
    saleClient,
    authClient,
} from 'helper/httpClient';
import {processCurrency} from 'data/curreny';
import {cleanObjectNilValue} from 'utils/clean';
import {useUIStore} from 'stores/uiStore';
import {
    isPlatformAdminRole,
    isUserRole,
    useAuth,
} from '../useAuth';
import {convertTZ} from 'utils/formator';
import {processCrypto} from 'data/crypto';

export const api = () => {
    const store = localStorage.getItem(
        `${process.env.REACT_APP_STORE_PREFIX}-authStore`,
    );
    if (store) {
        const state = JSON.parse(store);
        const isAdminUser = isPlatformAdminRole(state?.state?.user?.roles || []);
        const isUser = isUserRole(state?.state?.user?.roles || [])
        // const isSALE = (state?.state?.user?.roles || []).includes('SALE');
        return isUser
            ? client
            : isAdminUser
            ? adminClient
            : client;
    } else {
        return client;
    }
};

export const getCurrentUserType = () => {
    const store = localStorage.getItem(
        `${process.env.REACT_APP_STORE_PREFIX}-authStore`,
    );
    if (store) {
        const state = JSON.parse(store);
        const isAdmin = isPlatformAdminRole(state?.state?.user.roles || []);
        const isUser = isUserRole(state?.state?.user.roles || []);
        return {
            isAdmin,
            isUser,
        };
    } else {
        return {
            isUser:true,
        };
    }
};

export const useUploader = () => {
    return async (file) => {
        // return 'https://cdn.pixabay.com/photo/2016/05/05/02/37/sunset-1373171_1280.jpg';
        const extension = last(split(file.name, '.'));
        // console.log('file:', file, extension);
        const {
            result: {url, type, accessUrl},
        } = await api().post(['commons', 'upload-file'], {
            extension,
        });

        await generalClient
            .put(url)
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Credentials', 'true')
            .set('x-ms-blob-type', 'BlockBlob')
            // .withCredentials()
            .set('Content-Type', type)
            .send(file);

        return accessUrl;
    };
};

export const useRegion = (options = {}) => {
    const {request = true} = options;
    const {t: countriesI18n} = useTranslation('countries');
    const swr = useSWRTemplate({
        key: request ? ['region'] : null,
        request: async (_) => {
            const {result} = await api().get(['country']);
            return result;
        },
        defaultValue: [],
    });
    const localedCountries = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: countriesI18n(item.code),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedCountries,
        restrictedCountries: localedCountries.filter((item) =>
            Boolean(item.isRestricted),
        ),
    };
};

export const useRegionList = (options = {}) => {
    const {request = true} = options;
    const {t: countriesI18n} = useTranslation('countries');
    const swr = useSWRTemplate({
        key: request ? ['region-list'] : null,
        request: async (_) => {
            const {result} = await authClient.get(['region']);
            return result;
        },
        defaultValue: [],
    });
    const localedCountries = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: countriesI18n(item.code),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedCountries,
        restrictedCountries: localedCountries.filter((item) =>
            Boolean(item.isRestricted),
        ),
    };
};

export const usePersonTitle = (options = {}) => {
    const {} = options;
    const {t} = useTranslation('personTitle');
    const swr = useSWRTemplate({
        key: ['person-title'],
        request: async (_) => {
            const {result} = await api().get(['commons', 'person-title']);
            return result;
        },
        defaultValue: [],
    });
    const localedTitle = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: t(item.code),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedTitle,
    };
};

export const useOccupation = (options = {}) => {
    const {} = options;
    const {t} = useTranslation('app');
    const swr = useSWRTemplate({
        key: ['person-occupation'],
        request: async (_) => {
            const {result} = await api().get(['commons', 'person-occupation']);
            return result;
        },
        defaultValue: [],
    });
    const localedOccupation = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: t(item.name),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedOccupation,
    };
};

export const useCompanyIndustry = (options = {}) => {
    const {} = options;
    const {t} = useTranslation('app');
    const swr = useSWRTemplate({
        key: ['company-industry'],
        request: async (_) => {
            const {result} = await api().get(['commons', 'company-industry']);
            return result;
        },
        defaultValue: [],
    });
    const localedIndustry = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: t(item.name),
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedIndustry,
    };
};

export const usePhoneCode = (options = {}) => {
    const {} = options;
    return useSWRTemplate({
        key: ['phone-code'],
        request: async (_) => {
            const {result} = await api().get(['commons', 'phone-code']);
            return result.map((item) => ({
                value: `+${item.phoneCode}`,
                label: `+${item.phoneCode}`,
            }));
        },
        defaultValue: [],
    });
};

export const useCurrency = (options = {}) => {
    const {hasCutOffTime} = options;
    const {t: currencyT} = useTranslation('currencies');
    const swr = useSWRTemplate({
        key: ['currency', hasCutOffTime],
        request: async ([_key, _hasCutOffTime]) => {
            const {result} = await api().get(
                ['commons', 'currency-list'],
                cleanObjectNilValue({
                    hasCutOffTime: _hasCutOffTime,
                }),
            );
            return result.map(processCurrency);
        },
        defaultValue: [],
    });
    const localedCurrency = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            applicationType: item?.type,
            label: currencyT(item.code) + ` (${item.code})`,
            code: item.code,
            value: item.code,
        })),
        (o) => o.label,
    ).filter((item) => item.code !== 'AUD.L');
    return {
        ...swr,
        localedCurrency,
    };
};

export const useStates = (options = {}) => {
    const {country} = options;
    const {t} = useTranslation('app');
    const swr = useSWRTemplate({
        key: country ? ['state', country] : null,
        request: async ([_, _country]) => {
            const {result} = await api().get(['commons', 'state'], {
                countryCode: _country,
            });
            return result;
        },
        defaultValue: [],
    });
    const localedStates = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: item.name + ` (${item.isoCode})`,
            code: item.isoCode,
            value: item.isoCode,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedStates,
    };
};
export const useCities = (options = {}) => {
    const {state, country} = options;
    // const {t} = useTranslation('app');
    const swr = useSWRTemplate({
        key: state && country ? ['city', state, country] : null,
        request: async ([_, _state, _country]) => {
            const {result} = await api().get(['commons', 'city'], {
                stateCode: _state,
                countryCode: _country,
            });
            return result;
        },
        defaultValue: [],
    });
    const localedCities = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: item.name,
            code: item.name,
            value: item.name,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedCities,
    };
};

export const useCurrencyCutOffTime = (options = {}) => {
    const swr = useSWRTemplate({
        key: ['currency-cut-off-time'],
        request: async ([_]) => {
            const {result} = await api().get([
                'commons',
                'currency-cut-off-time',
            ]);
            return result;
        },
        defaultValue: [],
    });
    return swr;
};

export const usePlatformSetting = (options = {}) => {
    const {code} = options;
    const swr = useSWRTemplate({
        key: ['platform-setting', code],
        request: async ([_]) => {
            const {result} = await api().get(['platform-setting', code]);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useCurrencyRates = (options = {}) => {
    const {isUser, isBrokerAdmin} = useAuth();
    const swr = useSWRTemplate({
        key: isUser || isBrokerAdmin ? ['currency-rates'] : null,
        request: async ([_]) => {
            const {result} = await api().get(['commons', 'currency-rates']);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const useMonoovaMaximumNumber = () => {
    const swr = usePlatformSetting({code: 'ACCOUNT_MAXIMUM_NUMBER'});
    return {
        ...swr,
        data: isEmpty(get(swr, 'data')) ? 0 : Number(get(swr, 'data')),
    };
};
export const useAUDPaymentMinimumAmount = () => {
    const swr = usePlatformSetting({code: 'AUD_PAYMENT_MINIMUM_AMOUNT'});
    return {
        ...swr,
        data: isEmpty(get(swr, 'data')) ? 0 : Number(get(swr, 'data')),
    };
};
export const useMaxTradeLimitAmount = () => {
    const swr = usePlatformSetting({code: 'MAX_TRADE_ALERT_AMOUNT'});
    return {
        ...swr,
        data: isObject(get(swr, 'data')) ? 0 : Number(get(swr, 'data')),
    };
};
export const useMaxMonthlyLimitAmount = () => {
    const swr = usePlatformSetting({code: 'MAX_PAYMENT_MONTHLY_ALERT_AMOUNT'});
    return {
        ...swr,
        data: isObject(get(swr, 'data')) ? 0 : Number(get(swr, 'data')),
    };
};

export const useBrokerMaxTradeAmount = () => {
    const swr = useSWRTemplate({
        key: ['broker-max-trade-amount'],
        request: async ([_]) => {
            const {result} = await api().get(['risk', 'risk-setting']);
            return result;
        },
        defaultValue: [],
    });
    return {
        ...swr,
        maxTrade:
            get(swr, 'data').find((d) => d?.type === 'maxTrade')?.amount || 0,
        monthlyMaxPayment:
            get(swr, 'data').find((d) => d?.type === 'monthlyMaxPayment')
                ?.amount || 0,
    };
};

// export const useRiskBannedRegions = () => {
//     const swr = usePlatformSetting({code: 'BANNED_REGIONS'});
//     const data = split(isEmpty(get(swr, 'data')) ? '' : get(swr, 'data'),',')
//     return {
//         ...swr,
//         data: data,
//     };
// };
// export const useRiskJurisdictions = () => {
//     const swr = usePlatformSetting({code: 'RISK_JURISDICTIONS'});
//     const data = split(isEmpty(get(swr, 'data')) ? '' : get(swr, 'data'), ',');
//     return {
//         ...swr,
//         data: data,
//     };
// };

export const usePlatformWhiteLabelSetting = () => {
    const swr = usePlatformSetting({code: 'PLATFORM_WHITE_LABEL_SETTING'});
    return {
        ...swr,
        data: get(swr, 'data'),
    };
};


export const sendVerifyCode_ = async ({method}) => {
    const {result} = await api().post(['verify-code'], {
        method,
    });
    return result;
};

export const downloadFile_ = async (
    blobFile,
    name = 'template.xlsx',
    type = 'application/vnd.openxmlformats',
) => {
    let a = window.document.createElement('a');
    a.href = URL.createObjectURL(new Blob([blobFile], {type}));
    a.download = name;
    window.document.body.appendChild(a);
    a.click();
    window.document.body.removeChild(a);
};

export const updateCurrencyType_ = async (code, {allowConversion}) => {
    const {result} = await api().put(
        ['commons', 'currency-type', code],
        cleanObjectNilValue({
            allowConversion,
        }),
    );
    return result;
};

export const useApi = () => {
    const [liveMode] = useUIStore((state) => [state.liveMode]);
    return client;
}

export const getCutoffTime = (openTime, cutoffTime, timezone) => {
    const current = convertTZ(new Date(), timezone);
    const weekDay = current.getDay();
    const hour = current.getHours();
    if (weekDay === 0 || weekDay === 6) {
        return true;
    } else {
        if (hour < Number(openTime) || hour > Number(cutoffTime)) {
            return true;
        } else {
            return false;
        }
    }
};

export const editCutoffTime_ = async (currency, data) => {
    const {result} = await api().put(
        ['commons', 'currency-list', currency],
        cleanObjectNilValue(data),
    );
    return result;
};

export const addCutoffTime_ = async (data) => {
    const {result} = await api().post(
        ['commons', 'currency-list'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const viewFile = async (url) => {
    window.open(url, '_blank');
    // Change this to use your HTTP client
    //   fetch(url) // FETCH BLOB FROM IT
    //       .then((response) => response.blob())
    //       .then((blob) => {
    //           // RETRIEVE THE BLOB AND CREATE LOCAL URL
    //           var _url = window.URL.createObjectURL(blob);
    //           window.open(_url, '_blank').focus(); // window.open + focus
    //       })
    //       .catch((err) => {
    //           console.log(err);
    //       });
};

export const sendVerifyMobileCode_ = async (data) => {
    const {result} = await api().post(
        ['verify-mobile'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const verifyMobileCode_ = async (issueId, data) => {
    const {result} = await api().put(
        ['verify-mobile', issueId],
        cleanObjectNilValue(data),
    );
    return result;
};

export const useApiKeys = (options = {}) => {
    const {request = true} = options;
    const swr = useSWRTemplate({
        key: request ? ['common/api-key'] : null,
        request: async () => {
            const {result} = await api().get(['developer', 'api-key']);
            return result;
        },
        defaultValue: {},
    });
    return swr;
};

export const regenerationKey = async (data) => {
    const {result} = await api().post(
        ['developer', 'api-key'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const useCryptoType = (options = {}) => {
    const {withPlatformWallets = false} = options;
    const swr = useSWRTemplate({
        key: ['crypto-type', withPlatformWallets],
        request: async ([_, _withPlatformWallets]) => {
            const {result} = await api().get(['commons', 'crypto-list'], {
                withPlatformWallets: _withPlatformWallets,
            });
            return result.map(processCrypto).filter((item) => item.enable);
        },
        defaultValue: [],
    });
    return swr;
};

export const useMccCode = (options = {}) => {
    const {search} = options;
    const swr = useSWRTemplate({
        key: ['mcc-code', search],
        request: async ([_, _search]) => {
            const {result} = await api().get(['commons', 'mcc-code'], {
                search: _search,
            });
            return result;
        },
        defaultValue: [],
    });
    const localedTitle = sortBy(
        get(swr, 'data').map((item) => ({
            ...item,
            label: `${item.MCC} - ${item.desc}`,
            code: item.id,
            value: item.id,
        })),
        (o) => o.label,
    );
    return {
        ...swr,
        localedTitle,
    };
};

export const useRiskCountry = (options = {}) => {
    const {search} = options;
    const swr = useSWRPaginationTemplate({
        key: ['risk-country', search],
        request: async (_key, _pageIndex, _pageSize) => {
            const [_, _search] = _key;
            const {result} = await api().get(
                ['commons', 'country'],
                cleanObjectNilValue({
                    pageSize: _pageSize,
                    pageIndex: _pageIndex - 1,
                    search: _search ? _search : null,
                }),
            );
            return {
                result: {
                    totalItems: get(result, 'totalItems'),
                    items: get(result, 'items'),
                },
            };
        },
        defaultValue: [],
    });

    return {
        ...swr,
    };
};

export const updateMccCode_ = async (id, data) => {
    const {result} = await adminClient.put(
        ['setting', 'mcc-code', id],
        cleanObjectNilValue(data),
    );
    return result;
};

export const addMccCode_ = async (data) => {
    const {result} = await adminClient.post(
        ['setting', 'mcc-code'],
        cleanObjectNilValue(data),
    );
    return result;
};

export const updateRickCountry_ = async (id, data) => {
    const {result} = await adminClient.put(
        ['commons', 'country', id],
        cleanObjectNilValue(data),
    );
    return result;
};
