// import CRMLayout from 'layouts/CRMLayout';
import React, {lazy, Suspense} from 'react';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
    useLocation,
    useRoutes,
} from 'react-router-dom';
import {
    paths,
    PATH_AFTER_LOGIN,
    PATH_TO_REDIRECT,
    PATH_AFTER_ADMIN_LOGIN,
    adminPaths,
    authPaths,
    devPaths,
} from './path';
import GuestLayout from 'layouts/GuestLayout';
import GuestGuard from 'layouts/guard/GuestGuard';
import MainLayout from 'layouts/MainLayout';
import AuthGuard from 'layouts/guard/AuthGuard';
import LoadingScreen from 'page/LoadingScreen';
import AppLayout from 'layouts/AppLayout';
import HelpCenterLayout from 'layouts/HelpCenterLayout';
import {useAuth} from 'hooks/useAuth';
import AdminGuard from 'layouts/guard/AdminGuard';
import SettingLayout from 'layouts/SettingLayout';
import {get} from 'lodash';
import SafeLoadingComponent, { ComponentError } from 'components/SafeLoadingComponent';
import CRMLayout from 'layouts/CRMLayout';
import AdminLayout from 'layouts/AdminLayout';
import AdminPermissionGuard from 'page/guards/AdminPermissionGuard';

const getDevRoute = () => {
    return [
        {
            path: 'dev',
            children: [
                {
                    element: <Navigate to={devPaths.apis} replace />,
                    index: true,
                },
                {
                    path: 'api-explorer',
                    element: <API />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('explorer'),
                            path: devPaths.apis,
                        }),
                    },
                },
            ],
        },
    ];
};

const getAuthRoute = () => {
    return [
        {
            path: 'auth',
            element: <GuestLayout />,
            children: [
                {
                    element: <Navigate to={authPaths.login} replace />,
                    index: true,
                },
                {
                    path: 'login',
                    element: <GuestGuard>
                        <Login />
                    </GuestGuard>,
                },
                {
                    path: 'sign-up',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                {path: 'logout', element: <Logout />},
                {path: 'sign-up-verify', element: <SignUpVerify />},
                {path: 'forgot-password', element: <ForgotPassword />},
                {path: 'reset-password', element: <ResetPassword />},
            ],
        },
    ];
};

export const getAppRoute = () => {
    return [
        {
            path: 'app',
            element: (
                <AuthGuard>
                    <CRMLayout />
                </AuthGuard>
            ),
            children: [
                {
                    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
                    index: true,
                },
                {
                    path: 'dashboard',
                    element: <Dashboard />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('dashboard'),
                            path: paths.dashboard,
                        }),
                    },
                },
                {
                    path: 'accounts',
                    element: <ClientAccounts />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('accounts'),
                            path: paths.accounts,
                        }),
                    },
                },
                {
                    path: 'beneficiaries',
                    element: <Beneficiaries />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('beneficiaries'),
                            path: paths.beneficiaries,
                        }),
                    },
                },
                {
                    path: 'kyc',
                    element: <KYC />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('kyc'),
                            path: paths.kyc,
                        }),
                    },
                },
                {
                    path: 'kyb',
                    element: <KYB />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('kyb'),
                            path: paths.kyb,
                        }),
                    },
                },
                {path: 'logout', element: <Logout />},
                {path: 'profile', element: <Profile />},
                {
                    path: 'settings',
                    element: <SettingLayout />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('settings'),
                            path: paths.settings,
                        }),
                    },
                    children: [
                        {
                            element: <Navigate to={paths.account} replace />,
                            index: true,
                        },
                        {
                            path: 'account',
                            element: <AccountPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('account'),
                                    path: paths.account,
                                }),
                            },
                        },
                        {
                            path: 'contact',
                            element: <ContactPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('contact'),
                                    path: paths.contact,
                                }),
                            },
                        },
                        {
                            path: 'security',
                            element: <SecurityPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('security'),
                                    path: paths.security,
                                }),
                            },
                        },
                        {
                            path: 'notifications',
                            element: <NotificationPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('notifications'),
                                    path: paths.notificationSetting,
                                }),
                            },
                        },
                        {
                            path: 'systemSettings',
                            element: <SystemSettingsPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('systemSettings'),
                                    path: paths.systemSettings,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'referral',
                    children: [
                        {
                            path: 'referees',
                            element: <Referral />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('referees'),
                                    path: paths.referees,
                                }),
                            },
                        },
                        {
                            path: 'commission',
                            element: <Commission />,
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('commission'),
                                    path: paths.referralCommission,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'apis',
                    children: [
                        {
                            element: <Navigate to={devPaths.apis} replace />,
                            index: true,
                        },
                        {
                            path: 'api-log',
                            element: <ClientApiLogs />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('apiLog'),
                                    path: paths.apiLog,
                                }),
                            },
                        },
                        {
                            path: 'api-key',
                            element: <APIKey />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('apiKey'),
                                    path: paths.apiKey,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'help-center',
                    element: <HelpCenterLayout />,
                    children: [
                        {
                            element: <Navigate to={paths.support} replace />,
                            index: true,
                        },
                        {
                            path: 'support',
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('support'),
                                    path: paths.support,
                                }),
                            },
                            children: [
                                {
                                    element: <Support />,
                                    index: true,
                                },
                                {
                                    path: ':id',
                                    element: <SupportDetail />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('supportDetail'),
                                            path: paths.supportDetail(
                                                get(data, 'id'),
                                            ),
                                        }),
                                    },
                                },
                                {
                                    path: 'newSupport',
                                    element: <SupportDetail />,
                                    handle: {
                                        crumb: ({data, i18n}) => ({
                                            label: i18n('newSupport'),
                                            path: paths.newSupport,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'q-and-a',
                            element: <QAndA />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('QAndA'),
                                    path: paths.QAndA,
                                }),
                            },
                        },
                    ],
                    // handle: {
                    //     crumb: ({data, i18n}) => ({
                    //         label: i18n('helpCenter'),
                    //         path: paths.helpCenter,
                    //     }),
                    // },
                },
                {
                    path: 'components',
                    element: <Components />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('components'),
                            path: paths.components,
                        }),
                    },
                },
                {path: 'reports', element: <ClientReports />},
                {path: '404', element: <Page404 />},
                {path: '*', element: <Navigate to="/crm/404" replace />},
            ],
        },
    ];
};

export const getEdgeCaseRoute = () => {
    return [
        {
            path: '*',
            element: <GuestLayout />,
            children: [
                {path: 'maintenance', element: <Maintenance />},
                {path: '404', element: <Page404 />},
                {path: '*', element: <Navigate to="/404" replace />},
            ],
        },
        {
            path: '/',
            element: <MainLayout />,
            ErrorBoundary: ComponentError,
            children: [
                {
                    element: <Navigate to={PATH_AFTER_LOGIN} replace />,
                    index: true,
                },
                // {
                //     path: 'preparing',
                //     element: (
                //         <BrokerClientUnEdgeCaseGuard>
                //             <BrokerPreparing />
                //         </BrokerClientUnEdgeCaseGuard>
                //     ),
                // },
                // {
                //     path: 'offline',
                //     element: (
                //         <BrokerClientUnEdgeCaseGuard>
                //             <BrokerOffline />
                //         </BrokerClientUnEdgeCaseGuard>
                //     ),
                // },
                {
                    path: 'redirect',
                    element: <Redirect />,
                },
                // {
                //     path: 'maintenance',
                //     element: (
                //         <BrokerClientUnEdgeCaseGuard>
                //             <Maintenance />
                //         </BrokerClientUnEdgeCaseGuard>
                //     ),
                // },
            ],
        },
    ];
};

export const getAdminRoute = () => {
    return [
        {
            path: 'admin',
            element: (
                <AdminGuard>
                    <AdminLayout />
                </AdminGuard>
            ),
            children: [
                {
                    element: <Navigate to={PATH_AFTER_ADMIN_LOGIN} replace />,
                    index: true,
                },
                {
                    path: 'dashboard',
                    element: <AdminDashboard />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('dashboard'),
                            path: adminPaths.dashboard,
                        }),
                    },
                },
                // {
                //     path: 'clients',
                //     handle: {
                //         crumb: ({data, i18n}) => ({
                //             label: i18n('clients'),
                //             path: adminPaths.clients,
                //         }),
                //     },
                //     children: [
                //         {
                //             element: <AdminClients />,
                //             index: true,
                //         },
                //         {
                //             path: ':id',
                //             element: <AdminClientDetail />,
                //             handle: {
                //                 crumb: ({params, i18n}) => ({
                //                     label: i18n('clientDetail'),
                //                     path: adminPaths.clientDetail(
                //                         get(params, 'id'),
                //                     ),
                //                 }),
                //             },
                //         },
                //     ],
                // },
                {
                    path: 'clients',
                    element: (
                        <AdminPermissionGuard permissionKey={'clientsHome'} />
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('clients'),
                            path: adminPaths.clientsHome,
                        }),
                    },
                    children: [
                        {
                            element: (
                                <Navigate to={adminPaths.clients} replace />
                            ),
                            index: true,
                        },
                        {
                            path: 'overview',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.overview'}
                                >
                                    <AdminClientOverview />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('overview'),
                                    path: adminPaths.clientOverview,
                                }),
                            },
                        },
                        {
                            path: 'client-list',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.clients'}
                                />
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('clients'),
                                    path: adminPaths.clients,
                                }),
                            },
                            children: [
                                {
                                    element: <AdminClients />,
                                    index: true,
                                },
                                {
                                    path: ':id',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'clientsHome.clients.clientDetail'
                                            }
                                        >
                                            <AdminClientDetail />
                                        </AdminPermissionGuard>
                                    ),
                                    handle: {
                                        crumb: ({data, params, i18n}) => ({
                                            label: params.id,
                                            path: adminPaths.clients,
                                        }),
                                    },
                                },
                            ],
                        },
                        {
                            path: 'prospects',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'clientsHome.clientsProspect'
                                    }
                                >
                                    <AdminProspect />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('prospects'),
                                    path: adminPaths.clientsProspects,
                                }),
                            },
                        },
                        {
                            path: 'ib-admin',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.ibAdmin'}
                                >
                                    <IbAdmin />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('ibAdmin'),
                                    path: adminPaths.ibAdmin,
                                }),
                            },
                        },
                        {
                            path: 'notes',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.notes'}
                                >
                                    <AdminClientNotes />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('notes'),
                                    path: adminPaths.clientNotes,
                                }),
                            },
                        },
                        {
                            path: 'interactions',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'clientsHome.interactions'}
                                >
                                    <AdminClientInteractions />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('interactions'),
                                    path: adminPaths.interactions,
                                }),
                            },
                        },
                        // {
                        //     path: 'request-logs',
                        //     element: (
                        //         <AdminPermissionGuard
                        //             permissionKey={'clientsHome.requestLogs'}
                        //         >
                        //             <ApiLogs />
                        //         </AdminPermissionGuard>
                        //     ),
                        // },
                    ],
                },
                {path: 'profile', element: <Profile />},
                {
                    path: 'settings',
                    element: <SettingLayout />,
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('settings'),
                            path: adminPaths.settings,
                        }),
                    },
                    children: [
                        {
                            element: (
                                <Navigate to={adminPaths.account} replace />
                            ),
                            index: true,
                        },
                        {
                            path: 'account',
                            element: <AccountPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('account'),
                                    path: adminPaths.account,
                                }),
                            },
                        },
                        {
                            path: 'contact',
                            element: <ContactPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('contact'),
                                    path: adminPaths.contact,
                                }),
                            },
                        },
                        {
                            path: 'security',
                            element: <SecurityPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('security'),
                                    path: adminPaths.security,
                                }),
                            },
                        },
                        {
                            path: 'notifications',
                            element: <NotificationPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('notifications'),
                                    path: adminPaths.notificationSetting,
                                }),
                            },
                        },
                        {
                            path: 'systemSettings',
                            element: <SystemSettingsPanel />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('systemSettings'),
                                    path: adminPaths.systemSettings,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'tickets',
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('tickets'),
                            path: adminPaths.tickets,
                        }),
                    },
                    children: [
                        {
                            element: <AdminTicketList />,
                            index: true,
                        },
                        {
                            path: ':id',
                            element: <AdminTicketDetail />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('ticketDetail'),
                                    path: adminPaths.ticketDetail(
                                        get(data, 'id'),
                                    ),
                                }),
                            },
                        },
                        {
                            path: 'newTicket',
                            element: <AdminTicketDetail />,
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('newTicket'),
                                    path: adminPaths.newTicket,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'transactions',
                    element: (
                        <AdminPermissionGuard permissionKey={'transactions'} />
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('transactions'),
                            path: adminPaths.transactionsHome,
                        }),
                    },
                    children: [
                        {
                            element: (
                                <Navigate
                                    to={adminPaths.transactions}
                                    replace
                                />
                            ),
                            index: true,
                        },
                        {
                            path: 'transactions',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'transactions.transactions'}
                                >
                                    <AdminTransactionPanel />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('transactions'),
                                    path: adminPaths.transactions,
                                }),
                            },
                        },
                        {
                            path: 'ledgers',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'transactions.ledgers'}
                                >
                                    <AdminLedgePanel />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('ledgers'),
                                    path: adminPaths.ledgers,
                                }),
                            },
                        },
                        {
                            path: 'commissions',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'transactions.commissions'}
                                >
                                    <AdminClientsCommissionPanel />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('commissions'),
                                    path: adminPaths.commissions,
                                }),
                            },
                        },
                    ],
                },
                {
                    path: 'platform-settings',
                    element: (
                        <AdminPermissionGuard
                            permissionKey={'platformSettings'}
                        />
                    ),
                    handle: {
                        crumb: ({data, i18n}) => ({
                            label: i18n('platformSettings'),
                            path: adminPaths.platformSettings,
                        }),
                    },
                    children: [
                        {
                            element: (
                                <Navigate to={adminPaths.currencies} replace />
                            ),
                            index: true,
                        },
                        {
                            path: 'currencies',
                            element: <AdminCurrencies />,
                            handle: {
                                crumb: ({params, i18n}) => ({
                                    label: i18n('currencies'),
                                    path: adminPaths.currencies,
                                }),
                            },
                        },
                        {
                            path: 'access-control',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.accessControl'
                                    }
                                >
                                    <AdminAccessControl />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('accessControl'),
                                    path: adminPaths.accessControl,
                                }),
                            },
                        },
                        {
                            path: 'general-settings',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.generalSettings'
                                    }
                                >
                                    <GeneralSettings />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('generalSettings'),
                                    path: adminPaths.generalSettings,
                                }),
                            },
                        },
                        {
                            path: 'blackWhitelist',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={
                                        'platformSettings.blackWhitelist'
                                    }
                                >
                                    <BlackWhiteList />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('blackWhitelist'),
                                    path: adminPaths.blackWhitelist,
                                }),
                            },
                        },
                        {
                            path: 'languages',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'platformSettings.languages'}
                                >
                                    <Languages />
                                </AdminPermissionGuard>
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('languages'),
                                    path: adminPaths.languages,
                                }),
                            },
                        },
                        {
                            path: 'faq',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'platformSettings.faq'}
                                />
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('faq'),
                                    path: adminPaths.faq,
                                }),
                            },
                            children: [
                                // {
                                //     element: <AdminFaqHome />,
                                //     index: true,
                                // },
                                {
                                    element: <AdminFaqPage />,
                                    index: true,
                                },
                                {
                                    path: 'newFaq',
                                    element: <AdminNewFaq />,
                                },
                                {
                                    path: 'editFaq/:id',
                                    element: <AdminEditFaq />,
                                },
                            ],
                        },
                        {
                            path: 'templates',
                            element: (
                                <AdminPermissionGuard
                                    permissionKey={'platformSettings.templates'}
                                />
                            ),
                            handle: {
                                crumb: ({data, i18n}) => ({
                                    label: i18n('templates'),
                                    path: adminPaths.templates,
                                }),
                            },
                            children: [
                                {
                                    element: <AdminTemplates />,
                                    index: true,
                                },
                                {
                                    path: 'messages',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.templates.messages'
                                            }
                                        />
                                    ),
                                    children: [
                                        {
                                            element: <AdminMessages />,
                                            index: true,
                                        },
                                        {
                                            path: 'newMessage',
                                            element: (
                                                <AdminPermissionGuard
                                                    permissionKey={
                                                        'platformSettings.templates.messages'
                                                    }
                                                >
                                                    <AdminNewMessage />
                                                </AdminPermissionGuard>
                                            ),
                                        },
                                        {
                                            path: 'editMessage/:id',
                                            element: <AdminEditMessage />,
                                        },
                                    ],
                                },
                                {
                                    path: 'emails',
                                    element: (
                                        <AdminPermissionGuard
                                            permissionKey={
                                                'platformSettings.templates.email'
                                            }
                                        />
                                    ),
                                    children: [
                                        {
                                            element: <AdminEmails />,
                                            index: true,
                                        },
                                        {
                                            path: 'newEmail',
                                            element: (
                                                <AdminPermissionGuard
                                                    permissionKey={
                                                        'platformSettings.templates.email'
                                                    }
                                                >
                                                    <AdminNewEmail />
                                                </AdminPermissionGuard>
                                            ),
                                        },
                                        {
                                            path: 'editEmail/:id',
                                            element: <AdminEditEmail />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {path: 'logout', element: <Logout />},
                {path: '404', element: <Page404 />},
                {path: '*', element: <Navigate to="/crm/404" replace />},
            ],
        },
    ];
};

export const RootRouter = () => {
    const authRoute = getAuthRoute();
    const appRoute = getAppRoute();
    const adminRoute = getAdminRoute();
    const edgeCaseRoute = getEdgeCaseRoute();
    const devRoute = getDevRoute();
    const router = createBrowserRouter(
        [
            ...authRoute,
            ...appRoute,
            ...adminRoute,
            ...devRoute,
            ...edgeCaseRoute,
        ],
        // {basename: '/client'},
    );

    return <RouterProvider router={router} />;
};

export default RootRouter;

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <SafeLoadingComponent>
                <Component {...props} />
            </SafeLoadingComponent>
        </Suspense>
    );
};

const Page404 = Loadable(lazy(() => import('page/Page404')));

const Login = Loadable(lazy(() => import('page/auth/Login')));
const Redirect = Loadable(lazy(() => import('page/Redirect')));
const Maintenance = Loadable(lazy(() => import('page/Maintenance')));

// const Register = Loadable(lazy(() => import('pages/Register')));
// const SignUpVerify = Loadable(lazy(() => import('pages/SignUpVerify')));
const ForgotPassword = Loadable(lazy(() => import('page/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('page/auth/ResetPassword')));
const Register = Loadable(lazy(() => import('page/auth/Register')));
const SignUpVerify = Loadable(lazy(() => import('page/auth/SignUpVerify')));

//app pages
const Dashboard = Loadable(lazy(() => import('page/app/Dashboard')));
const KYC = Loadable(lazy(() => import('page/app/KYC')));
const KYB = Loadable(lazy(() => import('page/app/KYB')));
const Support = Loadable(lazy(() => import('page/app/Support')));
const SupportDetail = Loadable(lazy(() => import('page/app/SupportDetail')));
const QAndA = Loadable(lazy(() => import('page/app/QAndA')));
const API = Loadable(lazy(() => import('page/app/API')));
const APILogs = Loadable(lazy(() => import('page/app/APILogs')));
const ClientApiLogs = Loadable(lazy(() => import('page/app/ClientApiLogs')));
const APIKey = Loadable(lazy(() => import('page/app/APIKey')));
const Settings = Loadable(lazy(() => import('page/app/Settings')));
const Profile = Loadable(lazy(() => import('page/app/Profile/Profile')));
const ProfileKYCReview = Loadable(
    lazy(() => import('page/app/Profile/ProfileKYCReview')),
);
const NotificationSetting = Loadable(
    lazy(() => import('page/app/Profile/Notifications')),
);
const Preference = Loadable(lazy(() => import('page/app/Profile/Preference')));
const Security = Loadable(lazy(() => import('page/app/Profile/Security')));

const Components = Loadable(lazy(() => import('page/Components')));

const Referral = Loadable(lazy(() => import('page/app/Referral/Referral')));
const Commission = Loadable(lazy(() => import('page/app/Referral/Commission')));

const Logout = Loadable(lazy(() => import('page/Logout')));

const AccountPanel = Loadable(lazy(() => import('page/app/Settings/AccountPanel')));
const ContactPanel = Loadable(lazy(() => import('page/app/Settings/ContactPanel')));
const SecurityPanel = Loadable(lazy(() => import('page/app/Settings/SecurityPanel')));
const NotificationPanel = Loadable(
    lazy(() => import('page/app/Settings/NotificationPanel')),
);
const SystemSettingsPanel = Loadable(
    lazy(() => import('page/app/Settings/SystemSettingsPanel')),
);

const ClientReports = Loadable(lazy(() => import('page/app/ClientReports')));
const ClientAccounts = Loadable(lazy(() => import('page/app/ClientAccounts')));
const Beneficiaries = Loadable(lazy(() => import('page/app/Beneficiaries')));

//admin pages
const AdminDashboard = Loadable(lazy(() => import('page/admin/Dashboard')));
const AdminProfile = Loadable(lazy(() => import('page/admin/Profile')));
const AdminClients = Loadable(lazy(() => import('page/admin/Clients/Clients')));
const AdminClientDetail = Loadable(
    lazy(() => import('page/admin/Clients/ClientDetail')),
);
const AdminClientOverview = Loadable(
    lazy(() => import('page/admin/Clients/ClientOverview')),
);
const AdminProspect = Loadable(lazy(() => import('page/admin/Prospect/Prospect')));

const AdminCurrencies = Loadable(
    lazy(() => import('page/admin/PlatformSettings/Currencies')),
);
const AdminPlatformMetaData = Loadable(
    lazy(() => import('page/admin/PlatformSettings/PlatformMetaData')),
);

//admin tickets
const AdminTicketList = Loadable(lazy(() => import('page/admin/Tickets/TicketsList')));
const AdminTicketDetail = Loadable(
    lazy(() => import('page/admin/Tickets/TicketDetail')),
);

const IbAdmin = Loadable(lazy(() => import('page/IB/IBAdmin')));
const AdminClientNotes = Loadable(
    lazy(() => import('page/admin/Clients/ClientNotes')),
);
const AdminClientInteractions = Loadable(
    lazy(() => import('page/admin/Clients/Interactions')),
);
const AdminAccessControl = Loadable(
    lazy(() => import('page/admin/Access/AccessControl')),
);

const GeneralSettings = Loadable(
    lazy(() => import('page/admin/Setting/GeneralSettings')),
);
const BlackWhiteList = Loadable(
    lazy(() => import('page/admin/Setting/BlackWhiteList')),
);

const AdminTransactionPanel = Loadable(
    lazy(() => import('page/admin/TransactionPanel')),
);

const AdminLedgePanel = Loadable(lazy(() => import('page/admin/LedgePanel')));
const AdminClientsCommissionPanel = Loadable(
    lazy(() => import('page/admin/AdminClientsCommissionPanel')),
);

const Languages = Loadable(
    lazy(() => import('page/admin/PlatformSettings/Languages')),
);

// const AdminFaqHome = Loadable(lazy(() => import('page/admin/FAQ/FapHome')));
const AdminFaqPage = Loadable(lazy(() => import('page/admin/FAQ/FaqPage')));
const AdminNewFaq = Loadable(lazy(() => import('page/admin/FAQ/NewFaq')));
const AdminEditFaq = Loadable(lazy(() => import('page/admin/FAQ/EditFaq')));

const AdminTemplates = Loadable(
    lazy(() => import('page/admin/Templates')),
);

const AdminEmails = Loadable(lazy(() => import('page/admin/Email/Emails')));
const AdminNewEmail = Loadable(
    lazy(() => import('page/admin/Email/NewEmail')),
);
const AdminEditEmail = Loadable(
    lazy(() => import('page/admin/Email/EditEmail')),
);

const AdminMessages = Loadable(
    lazy(() => import('page/admin/Message/Messages')),
);
const AdminNewMessage = Loadable(
    lazy(() => import('page/admin/Message/NewMessage')),
);
const AdminEditMessage = Loadable(
    lazy(() => import('page/admin/Message/EditMessage')),
);