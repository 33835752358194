// import CountryIcon from 'components/CountryIcon';
import {find, get} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import FormViewWrapper from './FormViewWrapper';
import { useMccCode } from 'hooks/datahook/common';
import { HStack, Text } from '@chakra-ui/react';

const MccCodePickerViewer = (props) => {
    const {data, i18n, index, name, text, options, formater} = props;
    const v = get(data, name);
    const {t: countriesT} = useTranslation('countries');
    const {localedTitle} = useMccCode();
    const founded = find(localedTitle, {value: v});
    return (
        <FormViewWrapper {...props}>
            <HStack alignItems={'center'}>
                <Text fontWeight={500}>{founded?.label || ' '}</Text>
            </HStack>
        </FormViewWrapper>
    );
};

export default MccCodePickerViewer;
