import {
    Box,
    Button,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Text,
    Tooltip,
    useBreakpointValue,
} from '@chakra-ui/react';
import {find, get} from 'lodash';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import CountryIcon from './CountryIcon';
import {ChevronDownIcon} from '@chakra-ui/icons';
import { useUserSetting,useLanguageSetting } from 'hooks/datahook/settings';
import { useIsMobile } from 'hooks/useIsMobile';
import { useAuth } from 'hooks/useAuth';

const shortNameDic = {
    'en-US': 'EN',
    'ja-JP': '日語',
    'zh-CN': '简中',
    'zh-TW': '繁中',
}

const LanguageBtn = (props) => {
    const {noShadow, small=false} = props;
    const {isPlatformAdminPage} = useAuth();
    const {t, i18n} = useTranslation('app');
    const {init} = useUserSetting();

    useEffect(() => {
        init();
    });

    const platformLanguageList = [
        {
            label: t('englishConsist'),
            value: 'en-US',
            county: 'US',
            shortName:'EN'
        },
        {
            label: t('chineseConsist'),
            value: 'zh-CN',
            county: 'CN',
            shortName:'简中'
        },
        {
            label: t('chineseTWConsist'),
            value: 'zh-TW',
            county: 'CN',
            shortName:'繁中'
        },
        // {
        //     label: t('japaneseConsist'),
        //     value: 'ja-JP',
        //     county: 'JP',
        //     shortName:'日語'
        // },
    ];




    const languageList = platformLanguageList;
    const matched = find(languageList, (o) => o?.value === i18n.language);
    return (
        <Menu>
            <Tooltip label={t('language')} hasArrow openDelay={500}>
                <MenuButton
                    size={'sm'}
                    border={'1px'}
                    borderColor={'gray.100'}
                    as={Button}
                    aria-label="Options"
                    rightIcon={small ? null : <ChevronDownIcon />}
                    // leftIcon={
                    //     small ? null : (
                    //         <CountryIcon country={matched.county} size={24} />
                    //     )
                    // }
                    boxShadow={'none'}
                    variant={'ghost'}
                    // bg={'white'}
                    px={small ? 1 : 4}
                >
                    {small ? (
                        // <CountryIcon country={matched.county} size={24} />
                        <Text>{matched?.shortName}</Text>
                    ) : (
                        matched?.label
                    )}
                </MenuButton>
            </Tooltip>
            <MenuList>
                {languageList.map((item) => (
                    <MenuItem
                        onClick={() => i18n.changeLanguage(item?.value)}
                        key={item?.value}
                        // icon={<CountryIcon country={item.county} size={24} />}
                    >
                        {item?.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default LanguageBtn;
