const endpointBase = process.env.REACT_APP_HOST_API_KEY;

const appConfig = {
    name: 'Fox Baseline',
    displayName: 'Fox Baseline',
    apiEndpoint: `${endpointBase}/client/v1`,
    adminApiEndpoint: `${endpointBase}/admin/v1`,
    saleApiEndpoint: `${endpointBase}/sale/v1`,
    authApiEndpoint: `${endpointBase}/client/auth/v1`,
    adminAuthApiEndpoint: `${endpointBase}/admin/auth/v1`,
    explorerApiEndpoint: `${endpointBase}/explorer/v1`,

    storePrefix: process.env.REACT_APP_STORE_PREFIX,
    appPermissionControl: process.env.REACT_APP_PERMISSION_CONTROL
        ? process.env.REACT_APP_PERMISSION_CONTROL.split(',')
        : ['CLIENT_USER', 'ADMIN', 'SALE', 'DEALER'],
    isPlatformAdmin: process.env.REACT_APP_IS_PLATFORM_ADMIN === 'true',
    isClient: process.env.REACT_APP_IS_CLIENT === 'true'
};
export default appConfig
