import {
    Avatar,
    Button,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    Text,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useAdminProfile, useClient, useProfile } from 'hooks/datahook/auth';
import {useAuth} from 'hooks/useAuth';
import {useColorModeValueKey} from 'hooks/useColors';
import {get} from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
    HiPower,
    HiOutlineIdentification,
    HiOutlineCog8Tooth,
    HiOutlineShieldCheck,
    HiChevronDown,
} from 'react-icons/hi2';
import {IoShield} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import {adminPaths, paths} from 'routes/path';
import { useClientPermissionSettings } from 'hooks/clientPermission';
import { useUIStore } from 'stores/uiStore';

const ProfileBtn = (props) => {
    const {isUser, logout, clientRoles} = useAuth();
    const {t} = useTranslation('app');
    const navigate = useNavigate();
    const {
        data: client,
        kycRequired,
        kycPending,
        isCompany,
    } = (isUser ? useClient : useAdminProfile)();
    const [liveMode] = useUIStore((state) => [
        state.liveMode
    ]);
    const {data: profile} = useProfile();
    // const isMobile = useBreakpointValue({
    //     base: true,
    //     md: false,
    // });

    const {getPermission} = useClientPermissionSettings({roles: clientRoles});
    const hasReadPermission = getPermission(
        'clientManagement.clientManagement',
        'read',
    ) && liveMode && client?.company;

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Avatar menu"
                rounded={'full'}
                overflow={'hidden'}
                variant={'unstyled'}
                height={'32px'}
                // width={'32px'}
            >
                <HStack>
                    <Text>{get(profile, 'email')}</Text>
                    <Icon as={HiChevronDown} />
                </HStack>
                {/* <Avatar
                    name={get(profile, 'name')}
                    src={get(profile, 'avatar')}
                    borderRadius={'full'}
                    height={'32px'}
                    width={'32px'}
                    objectFit={'cover'}
                /> */}
            </MenuButton>
            <MenuList>
                <MenuItem
                    onClick={() =>
                        navigate(
                            isUser
                                ? paths.profile
                                : adminPaths.profile,
                        )
                    }
                    // key={item.value}
                    // icon={<Avatar size='xs' src={get(profile,'avatar')}/>}
                >
                    <HStack>
                        <Avatar
                            size="sm"
                            src={get(profile, 'avatar')}
                            name={get(client, 'shortName')}
                        />
                        <Stack spacing={0}>
                            <Text fontWeight={'600'}>
                                {get(client, 'name')}
                            </Text>
                            <Text
                                fontSize={'2xs'}
                                color={useColorModeValueKey('gray')}
                            >
                                {get(client, 'email')}
                            </Text>
                        </Stack>
                    </HStack>
                </MenuItem>
                <MenuDivider />
                {isUser && (
                    <MenuItem
                        onClick={() =>
                            navigate(isCompany ? paths.kyb : paths.kyc)
                        }
                        icon={<Icon as={HiOutlineIdentification} boxSize={5} />}
                    >
                        {t(isCompany ? 'kyb' : 'kyc')}
                    </MenuItem>
                )}
                {isUser && isCompany && hasReadPermission && (
                    <MenuItem
                        onClick={() => navigate(paths.accessControl)}
                        icon={<Icon as={HiOutlineCog8Tooth} boxSize={5} />}
                    >
                        {t('accessControl')}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() =>
                        navigate(
                            isUser
                                ? paths.settings
                                : adminPaths.settings,
                        )
                    }
                    icon={<Icon as={HiOutlineCog8Tooth} boxSize={5} />}
                >
                    {t('settings')}
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        navigate(
                            isUser
                                ? paths.security
                                : adminPaths.security,
                        )
                    }
                    icon={<Icon as={HiOutlineShieldCheck} boxSize={5} />}
                >
                    {t('security')}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                    onClick={() => logout()}
                    icon={<Icon as={HiPower} boxSize={5} />}
                >
                    {t('logout')}
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default ProfileBtn;
