import PropTypes from 'prop-types';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import {useAuth} from 'hooks/useAuth';
import {adminPaths, brokerAdminPaths, edgeCasePaths, paths} from 'routes/path';
import { useAdminPermissionSettings } from 'hooks/adminPermission';
import { Center, Spinner } from '@chakra-ui/react';

// ----------------------------------------------------------------------

AdminPermissionGuard.propTypes = {
    children: PropTypes.node,
};

export default function AdminPermissionGuard({
    children,
    permissionKey,
}) {
    const {isUser, roles, isAdminPage} = useAuth();
    const {getPermission, isLoading, isValidating} =
        useAdminPermissionSettings({
            roles,
        });
    if (isLoading) {
        return (
            <Center>
                <Spinner />
            </Center>
        );
    }
    if (isUser) {
        return <Navigate to={paths.dashboard} replace />;
    }
    const hasPermission = getPermission(permissionKey);

    if (!hasPermission) {
        return (
            <Navigate
                to={
                    isAdminPage
                        ? adminPaths[403]
                        : edgeCasePaths[403]
                }
            />
        );
    }

    return <>{children ? children : <Outlet />}</>;
}
