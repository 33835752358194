import {useColorModeValueKey} from 'hooks/useColors';
import React, {memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {matchPath, Outlet, useMatches, useNavigate} from 'react-router-dom';
import {
    adminNavDrawerConfig,
    adminPaths,
    navDrawerConfig,
    paths,
} from 'routes/path';
import {camelCase, get, trim} from 'lodash';
import {isNil} from 'lodash';
import {useUIStore} from 'stores/uiStore';
import {formatDateTimeHomeHeader, formatDateTimes} from 'utils/formator';
import NotificationBtn from 'components/NotificationBtn';
import LanguageBtn from 'components/LanguageBtn';
import {split} from 'lodash';
import {last} from 'lodash';
import {useAuth} from 'hooks/useAuth';
import {useAdminProfile, useLoginLog, useProfile} from 'hooks/datahook/auth';
import {useAutoLogout} from 'hooks/useAutoLogout';
import ProfileBtn from 'components/ProfileBtn';
import { useIsMobile } from 'hooks/useIsMobile';
import {MobileNavDrawer, NavBar, AdminNavBar, AppLogoArea} from './NewNavBar';
import { Box, Divider, HStack, Heading, IconButton, Stack, Text } from '@chakra-ui/react';
import { HEADER_HEIGHT } from 'theme';
import { HiOutlineMenu } from 'react-icons/hi';
import CustomBreadcrumb from 'components/CustomBreadcrumb';
import ColorModeBtn from 'components/ColorModeBtn';


const Header = memo((props) => {
    const {} = props;
    const {logout, isAdmin, isDealer, isSale,isPlatformAdminPage} = useAuth();
    const {profile} = useAdminProfile();
    // console.log(profile);
    const {t} = useTranslation('app');
    const [headerTitle, headerDesc, showMobileDrawer, setShowMobileDrawer] =
        useUIStore((state) => [
            state.headerTitle,
            state.headerDesc,
            state.showMobileDrawer,
            state.setShowMobileDrawer,
        ]);
    const matches = useMatches();
    // console.log(matches);
    const matchPath = get(last(matches), 'pathname');
    const mainPath = last(split(trim(matchPath,'/'), '/'));

    const {data: loginLog, isLoading} = useLoginLog();
    const defaultDashboardHeaderDesc =
        isLoading || isNil(loginLog) || isNil(get(loginLog, 'createdAt'))
            ? null
            : `${t('lastLoginTime')} ${formatDateTimes(
                  get(loginLog, 'createdAt'),
              )}`;
    const grayColor = useColorModeValueKey('gray');
    const whiteColor = useColorModeValueKey('white');
    const defaultHeaderDesc =
        matchPath === adminPaths.dashboard ? defaultDashboardHeaderDesc : '  ';
    const defaultHeaderTitle =
        matchPath === adminPaths.dashboard
            ? t('greatingWithName', {
                  name:
                      get(profile, 'name') ||
                      t(isSale ? 'sales' : isDealer ? 'dealer' : 'admin'),
              })
            : t(camelCase(mainPath));
    const isMobile = useIsMobile();
    return (
        <HStack
            alignItems={'center'}
            justifyContent={'space-between'}
            px={{base: 2, md: 4}}
            py={2}
            bg={useColorModeValueKey('navBg')}
            transition={'background-color 200ms linear'}
            zIndex={2}
            shadow={'sm'}
            height={HEADER_HEIGHT}
        >
            <HStack alignItems={'center'} position="relative" flex={1}>
                {/* {!isMobile && isPlatformAdminPage && (
                    <HStack w={44}>
                        <AppLogoArea px={0} />
                    </HStack>
                )} */}
                {isMobile ? (
                    <HStack>
                        <IconButton
                            onClick={() => setShowMobileDrawer(true)}
                            icon={<HiOutlineMenu size={'24px'} />}
                            variant={'ghost'}
                        />
                        <Heading
                            fontWeight={'600'}
                            fontSize={'lg'}
                            textAlign="center"
                            flex={1}
                        >
                            {headerTitle ? headerTitle : defaultHeaderTitle}
                        </Heading>
                    </HStack>
                ) : (
                    <Stack spacing={0} alignItems={'flex-start'}>
                        <Heading fontWeight={'600'} fontSize={'lg'}>
                            {headerTitle || defaultHeaderTitle}
                        </Heading>
                        {/*{*/}
                        {/*    <Text color={grayColor} fontSize="xs">*/}
                        {/*        {headerDesc || defaultHeaderDesc}*/}
                        {/*    </Text>*/}
                        {/*    */}
                        {/*}*/}
                        <CustomBreadcrumb />
                    </Stack>
                )}
            </HStack>
            <HStack alignItems={'center'} spacing={2}>
                <NotificationBtn />
                {/* {isUser && <PlatformCurrencyBtn />} */}
                {!isMobile && (
                    <HStack alignItems={'center'} spacing={2}>
                        <LanguageBtn small />
                        <ColorModeBtn />
                    </HStack>
                )}
                {!isMobile && (
                    <Divider h={'36px'} orientation="vertical" ml={1} />
                )}
                <ProfileBtn />
                {/* <LogoutBtn/> */}
            </HStack>
        </HStack>
    );
});

const AdminLayout = () => {
    useAutoLogout();
    const grayerWhite = useColorModeValueKey('grayerWhite');
    const isMobile = useIsMobile();
    return (
        <HStack alignItems={'stretch'} h={'100vh'} w="100vw" spacing={0}>
            {isMobile ? <MobileNavDrawer /> : <NavBar />}
            {/* <Hidden till="md">
                <Stack
                    width={'240px'}
                    bg={useColorModeValueKey('white')}
                    borderRightWidth={1}
                    borderColor={useColorModeValueKey('grayerWhite')}
                    space={2}
                >
                    <AppLogoArea m={4} />
                    <NavDrawer />
                </Stack>
            </Hidden> */}
            {/* {isMobile ? <MobileNavDrawer /> : null} */}
            <Stack
                flex={1}
                bg={useColorModeValueKey('bg')}
                transition={'background-color 200ms linear'}
                spacing={0}
                display="flex"
                w="100%"
            >
                <Stack spacing={0}>
                    <Header isMobile={isMobile} />
                    {/* {!isMobile && <AdminNavBar />} */}
                </Stack>
                <Box overflow={'scroll'} flex={1} zIndex={1} w="100%">
                    <Box
                        px={{base: 4, md: 4}}
                        pt={4}
                        pb={{base: 12, md: 12}}
                        mx="auto"
                        maxW={'100%'}
                        // overflow={'scroll'}
                        h="100%"

                    >
                        <Outlet />
                        <Box h={10}/>
                    </Box>
                </Box>
            </Stack>
        </HStack>
    );
};

export default AdminLayout;
