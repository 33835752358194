import {
    clientCanEditStatus,
    clientFunctionActivedStatus,
    kycRequiredStatus,
    kycReviewingStatus,
} from 'constant/status';
import {getAddressPayload, getAddressPayloadEmptyByNull} from 'data/common';
import {getLegalRepresentativePayloadObj} from 'data/leaglRepresentative';
import {processUserProfile} from 'data/userProfile';
import client, {
    adminClient,
    authClient,
    authAdminClient,
} from 'helper/httpClient';
import {useSWRTemplate} from 'hooks/dataHooksTemplate';
import {useAuth} from 'hooks/useAuth';
import {findIndex, sortBy} from 'lodash';
import {find} from 'lodash';
import {split} from 'lodash';
import {lowerCase} from 'lodash';
import {get} from 'lodash';
import {useState} from 'react';
import {cleanObjectNilValue} from 'utils/clean';
import {formatMonthDataAndYear} from 'utils/formator';
import {api} from './common';
import {isPlatformAdminRole} from 'hooks/useAuth';
import {processClient} from 'data/client';

export const verifyCode_ = async (data) => {
    await authClient.post(['activate'], cleanObjectNilValue(data));
};

export const forgetPasswordSendEmail_ = async (email) => {
    let requestClient = authClient;
    if (process.env.REACT_APP_PERMISSION_CONTROL) {
        const permissions = process.env.REACT_APP_PERMISSION_CONTROL.split(',');
        if (
            isPlatformAdminRole(permissions)
        ) {
            requestClient = authAdminClient;
        }
    }

    await requestClient.post(['forgot'], {email});
};

export const resetpassword_ = async (data) => {
    let requestClient = authClient;
    if (process.env.REACT_APP_PERMISSION_CONTROL) {
        const permissions = process.env.REACT_APP_PERMISSION_CONTROL.split(',');
        if (
            isPlatformAdminRole(permissions)
        ) {
            requestClient = authAdminClient;
        }
    }

    await requestClient.post(['reset'], data);
};

export const resendCode_ = async ({issueId,type}) => {
    await authClient.post(['resend-code'], {issueId, type});
};

export const useProfile = (options = {}) => {
    const {} = options;
    const {id} = useAuth();
    const swrResult = useSWRTemplate({
        key: id ? ['profile', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['profile']);
            const res = processUserProfile(result);
            return res;
        },
        defaultValue: {},
    });
    const steps = get(swrResult, 'data.rawData.:steps');
    const currentStepIndex = findIndex(
        steps,
        (o) => Boolean(o.completed) === false,
    );
    const isComplete = currentStepIndex === -1;
    const stepNum = steps?.length || 0;
    const remainStepNumber = isComplete
        ? 0
        : currentStepIndex !== -1
        ? stepNum - currentStepIndex
        : 0;
    const currentStep = stepNum - remainStepNumber;

    const needRenewPassword = Boolean(
        get(swrResult, 'data.rawData.needRenewPassword'),
    );
    const kycVerified = clientFunctionActivedStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );
    const kycRequired = kycRequiredStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );
    const kycPending = kycReviewingStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );

    return {
        ...swrResult,
        stepNum,
        remainStepNumber,
        currentStep,
        profile: get(swrResult, 'data'),
        clientId: get(swrResult, 'data.clientId'),
        roles: get(swrResult, 'data.rawData.:roles'),
        steps,
        needRenewPassword,
        reference: get(swrResult, 'data.rawData.reference'),
        name: get(swrResult, 'data.rawData.name'),
        isCompany: Boolean(
            lowerCase(
                get(swrResult, 'rawData.:individual.:client.clientType'),
            ) == 'company',
        ),
        needRenewKyc: get(swrResult, 'data.kycStatus') === 'renew',
        kycVerified,
        kycPending,
        kycRequired,
        isIB: Boolean(get(swrResult, 'data.ibLevelType')),
        updateProfile: async (payload) => {
            await api().post(['profile'], payload);
            await swrResult.mutate();
        },
        clientCanEdit: clientCanEditStatus.includes(
            get(swrResult, 'data.kycStatus'),
        ),
        functionActivated:
            clientFunctionActivedStatus.includes(
                get(swrResult, 'data.kycStatus'),
            ) &&
            !needRenewPassword &&
            get(swrResult, 'data.amlStatus') !== 'invalid',
    };
};

export const useIndividualKycDetail = (options = {}) => {
    const {} = options;
    const {data, ...other} = useClient();

    return {
        ...other,
        data,
        submitPersonalInformation_: async (person, estimate) => {
            // console.log(estimate);
            const phone = get(person, 'phone', '');
            const phoneCode = split(phone, '@')?.[0] || '';
            const phoneNo = split(phone, '@')?.[1] || '';

            // const transferNeeds = get(estimate, 'transferNeeds', '');
            // const fromCurrency = split(transferNeeds, '/')?.[0] || '';
            // const toCurrency = split(transferNeeds, '/')?.[1] || '';
            const address = getAddressPayloadEmptyByNull(person, 'individual');
            const payload = {
                person: {
                    title: get(person, 'title', ''),
                    firstname: get(person, 'firstname', ''),
                    middlename: get(person, 'middlename', ''),
                    lastname: get(person, 'lastname', ''),
                    nativeLanguageFirstname: get(person, 'firstNameNative', ''),
                    nativeLanguageMiddlename: get(
                        person,
                        'middleNameNative',
                        '',
                    ),
                    nativeLanguageLastname: get(person, 'lastNameNative', ''),
                    // TODO issue32
                    phoneCode,
                    phoneNo,
                    referrer: get(person, 'referrer', ''),
                    region: get(address, 'country', ''),
                    occupation: get(person, 'occupation', ''),
                    mccCode: get(person, 'mccCode', ''),
                    dob: get(person, 'dob', '')
                        ? formatMonthDataAndYear(get(person, 'dob', ''))
                        : null,
                },
                estimate: {
                    // fromCurrency,
                    // toCurrency,
                    // amount: Number(get(estimate, 'transferAmount', '')),
                    amount: Number(get(estimate, 'transferAmount', '')),
                    declaredCurrency: get(estimate, 'declaredCurrency', ''),
                    currencies: get(estimate, 'estimateCurrencies', ''),
                },
                address: address,
            };
            await client.post(['personal', 'kyc', 'PI'], payload);
            await other.mutate();
        },
        submitUploadDocument_: async (value) => {
            const payload = {
                frontPhotoOfPrimary: get(value, 'primaryDocumentFrontSide'),
                backPhotoOfPrimary: get(value, 'primaryDocumentBackSide'),
                photoOfSecondary: get(value, 'secondaryIdentificationDocument'),
                photoOfAdditional:
                    get(value, 'additionalPhotoIdentification') || null,
            };
            await client.post(['personal', 'kyc', 'UD'], payload);
            await other.mutate();
        },
        submitReviewApplication_: async () => {
            await client.post(['personal', 'kyc', 'RA']);
            await other.mutate();
        },
        submitApplication_: async (value) => {
            await client.post(['personal', 'kyc', 'SA'], {
                accountTerms: true,
                complaintsProcedures: true,
                privacyPolicy: true,
                signature: get(value, 'signature'),
            });
            await other.mutate();
        },
    };
};

export const useCompanyKycDetail = (options = {}) => {
    const {} = options;
    const {data, ...other} = useClient();
    return {
        ...other,
        data,
        submitBasicInformation_: async (company, estimate) => {
            // const transferNeeds = get(estimate, 'transferNeeds', '');
            // const fromCurrency = split(transferNeeds, '/')?.[0] || '';
            // const toCurrency = split(transferNeeds, '/')?.[1] || '';
            const phone = get(company, 'phone', '');
            const phoneCode = split(phone, '@')?.[0] || '';
            const phoneNo = split(phone, '@')?.[1] || '';
            const payload = {
                company: {
                    name: get(company, 'name', ''),
                    businessNo: get(company, 'businessNo', ''),
                    industry: get(company, 'industry') || null,
                    industryName: get(company, 'industryName', ''),
                    email: get(company, 'email', ''),
                    phoneNo: phoneNo,
                    phoneCode: phoneCode,
                    officialWebsite: get(company, 'businessURL', ''),
                    mccCode: get(company, 'mccCode', ''),
                    region: get(company, 'region'),
                },
                estimate: {
                    // fromCurrency,
                    // toCurrency,
                    amount: Number(get(estimate, 'transferAmount', '')),
                    declaredCurrency: get(estimate, 'declaredCurrency', ''),
                    currencies: get(estimate, 'estimateCurrencies', ''),
                },
                address: getAddressPayloadEmptyByNull(company, 'company'),
                documents: get(company, 'companyDocuments', []),
            };
            await api().post(['company', 'kyb', 'CI'], payload);
            await other.mutate();
        },
        submitLegalRepresentative_: async (values = []) => {
            const payload = {
                representatives: values.map(getLegalRepresentativePayloadObj),
            };
            await api().post(['company', 'kyb', 'LR'], payload);
            await other.mutate();
        },
        submitAuthorisedPerson_: async ({id, newValue}) => {
            const payload = id
                ? {authorisedPersonId: id}
                : {
                      authorisedPerson:
                          getLegalRepresentativePayloadObj(newValue),
                  };
            await api().post(['company', 'kyb', 'AP'], payload);
            await other.mutate();
        },
        submitReviewApplication_: async () => {
            await api().post(['company', 'kyb', 'RA']);
            await other.mutate();
        },
        submitApplication_: async (value) => {
            await api().post(['company', 'kyb', 'SA'], {
                accountTerms: true,
                complaintsProcedures: true,
                privacyPolicy: true,
                signature: get(value, 'signature'),
            });
            await other.mutate();
        },
    };
};

export const useAdminProfile = (options = {}) => {
    const {} = options;
    const {id} = useAuth();
    const swrResult = useSWRTemplate({
        key: id ? ['admin/profile', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['profile', _id]);
            const res = processUserProfile(result);
            console.log(res);
            return res;
        },
        defaultValue: {},
    });

    return {
        ...swrResult,
        profile: get(swrResult, 'data'),
        roles: get(swrResult, 'data.rawData.:roles'),
        name: get(swrResult, 'data.rawData.name'),
        updateProfile: async (payload) => {
            await api().post(['profile'], payload);
            await swrResult.mutate();
        },
    };
};

export const useLoginLog = (options = {}) => {
    const {} = options;
    const {id} = useAuth();
    const swrResult = useSWRTemplate({
        key: id ? ['loginLog', id] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['login-log']);
            return result;
        },
        defaultValue: {},
    });
    return swrResult;
};

export const useClient = (options = {}) => {
    const {request = true} = options;
    const {clientId} = useProfile();
    const swrResult = useSWRTemplate({
        key: clientId && request ? ['client', clientId] : null,
        request: async ([_, _id]) => {
            const {result} = await api().get(['client']);
            return processClient(result);
        },
        defaultValue: {},
    });

    const steps = sortBy(get(swrResult, 'data.steps'), (o) => o.order);
    const currentStepIndex = findIndex(
        steps,
        (o) => Boolean(o.completed) === false,
    );
    const isComplete = currentStepIndex === -1;
    const stepNum = steps?.length || 0;
    const remainStepNumber = isComplete
        ? 0
        : currentStepIndex !== -1
        ? stepNum - currentStepIndex
        : 0;
    const currentStep = stepNum - remainStepNumber;

    const needRenewPassword = Boolean(
        get(swrResult, 'data.rawData.needRenewPassword'),
    );
    const kycVerified = clientFunctionActivedStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );
    const kycRequired = kycRequiredStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );
    const kycPending = kycReviewingStatus.includes(
        get(swrResult, 'data.kycStatus'),
    );
    return {
        ...swrResult,
        stepNum,
        remainStepNumber,
        currentStep,
        data: get(swrResult, 'data'),
        clientId: get(swrResult, 'data.clientId'),
        roles: get(swrResult, 'data.roles'),
        amlInvalid: get(swrResult, 'data.amlStatus') === 'invalid',
        steps,
        needRenewPassword,
        reference: get(swrResult, 'data.reference'),
        name: get(swrResult, 'data.name'),
        isCompany: get(swrResult, 'data.isCompany'),
        needRenewKyc: get(swrResult, 'data.kycStatus') === 'renew',
        kycVerified,
        kycPending,
        kycRequired,
        isIB: Boolean(get(swrResult, 'data.ibLevelType')),
        updateClient: async (payload) => {
            //TODO: need craete
            await httpClient.post(['client'], payload);
            await swrResult.mutate();
        },
        clientCanEdit: clientCanEditStatus.includes(
            get(swrResult, 'data.kycStatus'),
        ),
        functionActivated:
            clientFunctionActivedStatus.includes(
                get(swrResult, 'data.kycStatus'),
            ) &&
            !needRenewPassword &&
            get(swrResult, 'data.amlStatus') !== 'invalid',
    };
};

export const useReviewClient = (options = {}) => {
    const {} = options;
    return {
        data: {
            shortName: 'Review',
            name: 'Review Client',
            reference: 'I123456789',
            kycStatus: 'pending',
        },
        isCompany: false,
        kycVerified: true,
        isIB: false,
        isLoading: false,
        remainStepNumber: 2,
        stepNum: 4,
        currentStep: 2,
    };
};
export const useReviewProfile = (options = {}) => {
    const {} = options;
    return {
        data: {
            avatar: 'https://img.freepik.com/free-psd/3d-illustration-human-avatar-profile_23-2150671142.jpg',
        },
    };
};
