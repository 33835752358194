import {defineStyle, defineStyleConfig} from '@chakra-ui/react';
import { colorByMode } from 'theme/themeHelper';

const baseStyle = defineStyle((props) => {
    return {
        color: colorByMode('text', props.colorMode),
    };
});

const title = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        fontSize: 'xl',
        fontWeight: '500',
    };
});

const subTitle = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        fontSize: 'lg',
        fontWeight: '500',
    };
});

const normal = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        fontSize: 'md',
        fontWeight: '400',
    };
});

const content = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        fontSize: 'sm',
        fontWeight: '300',
        lineHeight: '1.2',
    };
});

const helperTitle = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        fontSize: 'xs',
        fontWeight: '400',
        lineHeight: '1.2',
    };
});

const helper = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        fontSize: 'xs',
        fontWeight: '400',
        lineHeight: '1.2',
        color: colorByMode('gray', colorMode),
    };
});

const caption = defineStyle((props) => {
    const {colorScheme: c, colorMode} = props;
    return {
        fontSize: '2xs',
        fontWeight: '400',
        lineHeight: '1.2',
        color: colorByMode('gray', colorMode),
    };
});

const textTheme = defineStyleConfig({
    baseStyle,
    variants: {
        title,
        subTitle,
        normal,
        content,
        helper,
        caption,
        helperTitle,
    },
    defaultProps: {
        variant: 'normal',
    },
});
export default textTheme;
