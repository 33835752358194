import {get} from 'lodash';

export const processAddress = (rawData, prefix) => {
    return {
        [prefix + 'line1']: get(rawData, 'line1') || '',
        [prefix + 'Suburb']: get(rawData, 'city') || '',
        [prefix + 'State']: get(rawData, 'state') || '',
        [prefix + 'Country']: get(rawData, 'country') || '',
        [prefix + 'Postcode']: get(rawData, 'postcode') || '',
    };
};

export const getAddressPayload = (formikValues, prefix) => {
    return {
        line1: formikValues[prefix + 'line1'],
        city: formikValues[prefix + 'Suburb'],
        state: formikValues[prefix + 'State'],
        country: formikValues[prefix + 'Country'],
        postcode: formikValues[prefix + 'Postcode'],
    };
};

export const getAddressPayloadEmptyByNull = (formikValues, prefix) => {
    return {
        line1: formikValues[prefix + 'line1']===''?null:formikValues[prefix + 'line1'],
        city: formikValues[prefix + 'Suburb'] === '' ? null : formikValues[prefix + 'Suburb'],
        state: formikValues[prefix + 'State'] === '' ? null : formikValues[prefix + 'State'],
        country: formikValues[prefix + 'Country'] === '' ? null : formikValues[prefix + 'Country'],
        postcode: formikValues[prefix + 'Postcode'] === '' ? null : formikValues[prefix + 'Postcode'],
    };
};