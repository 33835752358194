import enApp from './en-US/app.json'
import enCountries from './en-US/countries.json'
import enCurrencies from './en-US/currencies.json'
import enPersonTitle from './en-US/personTitle.json'
import zhApp from './zh-CN/app.json'
import zhCountries from './zh-CN/countries.json';
import zhCurrencies from './zh-CN/currencies.json';
import zhPersonTitle from './zh-CN/personTitle.json';

import zhTWApp from './zh-TW/app.json'
import zhTWCountries from './zh-TW/countries.json';
import zhTWCurrencies from './zh-TW/currencies.json';
import zhTWPersonTitle from './zh-TW/personTitle.json';
export default {
    'en-US': {
        app: enApp,
        countries: enCountries,
        currencies: enCurrencies,
        personTitle: enPersonTitle,
    },
    'zh-CN': {
        app: zhApp,
        countries: zhCountries,
        currencies: zhCurrencies,
        personTitle: zhPersonTitle,
    },
    'zh-TW': {
        app: zhTWApp,
        countries: zhTWCountries,
        currencies: zhTWCurrencies,
        personTitle: zhTWPersonTitle,
    },
};
