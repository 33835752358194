import {get} from 'lodash';
import {processSystemEvent} from './email';

export const processMessage = (rawData) => {
    return {
        id: get(rawData, 'id'),
        type: get(rawData, 'type'),
        title: get(rawData, 'title'),
        message: get(rawData, 'message'),
        createdAt: get(rawData, 'createdAt'),
        readFlag: Boolean(get(rawData, 'readFlag')),
        link: get(rawData, 'link'),
        params: get(rawData, 'params'),
        user: get(rawData, 'user'),
        toAdmin: get(rawData, 'toAdmin'),
    };
};

export const processAdminMessageSetting = (rawData) => {
    const messageTitles = {};
    const contents = {};
    get(rawData, ':contents', []).forEach((element) => {
        messageTitles[element.locale] = element.title;
        contents[element.locale] = element.message;
    });
    return {
        ...rawData,
        id: get(rawData, 'id'),
        createdAt: get(rawData, 'createdAt'),
        messageName: get(rawData, 'name'),
        type: get(rawData, 'type'),
        event: get(rawData, 'event'),
        eventDetail: processSystemEvent(get(rawData, ':event')),
        contents,
        messageTitles,
    };
};
