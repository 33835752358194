import { get } from "lodash";

export const processCrypto = (rawData) => {
    return {
        // id: get(rawData, 'id'), not id
        crypto: get(rawData, 'cryptoCurrency'),
        code: get(rawData, 'code'),
        contractAddress: get(rawData, 'contractAddress'),
        cryptoNetwork: get(rawData, 'cryptoNetwork'),
        cryptoNetworkDetail: processNetwork(get(rawData, ':cryptoNetwork')),
        enable: get(rawData, 'enable'),
        isToken: get(rawData, 'isToken'),
        platformWallets: get(rawData, 'platformWallets'),
        platformWalletsDetail:get(rawData, ':platformWallets'),
        protocol: get(rawData, 'protocol'),
        rawData,
    };
}

export const processNetwork = (rawData)=>{
    return {
        id: get(rawData, 'id'),
        network: get(rawData, 'network'),
        networkId: get(rawData, 'networkId'),
        networkName: get(rawData, 'networkName'),
        testNet: get(rawData, 'testNet'),
        rawData,
    };
}