import {useCities} from 'hooks/datahook/common';
import React from 'react';
import {useTranslation} from 'react-i18next';
import Selector from './Selector';

const CitiesSelector = (props) => {
    const {value, onChangeValue, state, country, ...other} = props;
    const {localedCities, isLoading} = useCities({state, country});
    return (
        <Selector
            value={value}
            onChangeValue={onChangeValue}
            options={localedCities}
            isLoading={isLoading}
            allowNoMatch
            {...other}
        />
    );
};

export default CitiesSelector;
