// import DateSelector from 'components/common/DateSelector';
import {parseISO} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {formatDate} from 'utils/formator';
import FormWrapper from './FormWrapper';
import {Box} from '@chakra-ui/react';
import Selector from 'components/DateRangeSelector';

const DateRangeSelector = (props) => {
    const {
        formik,
        i18n,
        index,
        name,
        required = false,
        disabled,
        readOnly,
        minimumDate,
        maximumDate,
        inputProps,
        helper,
        granularity = 'day',
        type,
    } = props;
    const value = {
        start:
            typeof formik.values[name].start === 'string'
                ? parseISO(formik.values[name].start)
                : formik.values[name]?.start||null,
        end:
            typeof formik.values[name].end === 'string'
                ? parseISO(formik.values[name].end)
                : formik.values[name]?.end||null,
    };
    return (
        <FormWrapper {...props}>
            <Selector
                minDate={minimumDate}
                maxDate={maximumDate}
                isDisabled={disabled}
                defaultValue={value}
                onChangeValue={(newValue) =>
                    formik.setFieldValue(name, newValue)
                }
                granularity={granularity}
                // hourCycle={24}
            />
        </FormWrapper>
    );
};

// export default DateRangeSelector;

export default React.memo(DateRangeSelector, (prev, next) => {
    const name = prev.name;
    return (
        prev.formik.values[name] === next.formik.values[name] &&
        prev.formik.errors[name] === next.formik.errors[name] &&
        prev.formik.touched[name] === next.formik.touched[name] &&
        prev.disabled === next.disabled &&
        prev.readOnly === next.readOnly &&
        prev.minimumDate === next.minimumDate &&
        prev.maximumDate === next.maximumDate &&
        prev.i18n === next.i18n
    );
});
