import {every, find, max, some} from 'lodash';
import {get} from 'lodash';
import {processAddress} from './common';
// import {porcessLegalRepresentative} from './leaglRepresentative';
import {processEstimate} from './estimate';
import {processPerson} from './person';

export const processCompany = (rawData) => {
    const processedPerson = (get(rawData, ':companyContacts') || []).map(
        processPerson,
    );
    const ap = find(processedPerson, (item) => Boolean(item.isAP)) || {};
    const companyUser = get(rawData, ':companyContacts', []).filter((d) =>
        get(d, ':user'),
    );
    const lr = get(rawData, ':companyContacts', []).filter((d) =>
        Boolean(d.isLR),
    );
    const allAmlStatus = [ap.amlStatus, ...lr.map((item) => item.amlStatus)];
    const phoneCode = get(rawData, 'phoneCode') || '';
    const phoneNo = get(rawData, 'phoneNo') || '';
    const companyIndividualAmlStatus = some(allAmlStatus, (o) => o == 'invalid')
        ? 'invalid'
        : every(allAmlStatus, (o) => o == 'valid')
        ? 'valid'
        : some(allAmlStatus, (o) => o == 'pending')
        ? 'pending'
        : 'unverified';
    return {
        id: get(rawData, 'id'),
        name: get(rawData, 'name'),
        industry: get(rawData, 'industry'),
        industryName: get(rawData, 'industryName'),
        email: get(rawData, 'email'),
        mobile: get(rawData, 'mobile'),
        phone: get(rawData, 'mobile'),
        phoneCode,
        phoneNo,
        businessURL: get(rawData, 'officialWebsite'),
        businessNo: get(rawData, 'businessNo'),
        legalRepresentative: processedPerson.filter((item) =>
            Boolean(item.isLR),
        ),
        companyContacts: processedPerson,
        legalPerson: lr,
        authorisedPerson: ap,
        allAmlStatus,
        amlStatus: get(rawData, 'amlStatus'),
        amlStatusValidTimestamp: get(rawData, 'amlStatusValidTimestamp'),
        amlStatusInvalidTimestamp: get(rawData, 'amlStatusInvalidTimestamp'),
        amlStatusUnverifiedTimestamp: get(
            rawData,
            'amlStatusUnverifiedTimestamp',
        ),
        amlStatusPendingTimestamp: get(rawData, 'amlStatusPendingTimestamp'),
        amlLastUpdatedTimestamp:
            get(rawData, 'amlStatusValidTimestamp') ||
            get(rawData, 'amlStatusInvalidTimestamp') ||
            get(rawData, 'amlStatusUnverifiedTimestamp') ||
            get(rawData, 'amlStatusPendingTimestamp')
                ? max(
                      [
                          get(rawData, 'amlStatusValidTimestamp'),
                          get(rawData, 'amlStatusInvalidTimestamp'),
                          get(rawData, 'amlStatusUnverifiedTimestamp'),
                          get(rawData, 'amlStatusPendingTimestamp'),
                      ]
                          .filter((d) => d)
                          .map((d) => new Date(d)),
                  ).toISOString()
                : null,
        overallAmlStatus:
            companyIndividualAmlStatus === 'invalid' ||
            get(rawData, 'amlStatus') === 'invalid'
                ? 'invalid'
                : companyIndividualAmlStatus === 'pending' ||
                  get(rawData, 'amlStatus') === 'pending'
                ? 'pending'
                : companyIndividualAmlStatus === 'valid' &&
                  get(rawData, 'amlStatus') === 'valid'
                ? 'valid'
                : companyIndividualAmlStatus === 'valid' ||
                  get(rawData, 'amlStatus') === 'valid'
                ? 'pending'
                : 'unverified',
        companyIndividualAmlStatus: companyIndividualAmlStatus,
        mccCode: get(rawData, 'mccCode'),
        ...processAddress(get(rawData, ':address'), 'company'),
        companyDocuments: (get(rawData, ':documents') || []).map(
            (item) => item.url,
        ),
        region: get(rawData, 'region'),
        kycType: get(rawData, 'kycType'), //'required','unRequired','shareCompany'
        kycStatus: get(rawData, 'kycStatus'),
        kycExpireDate: get(rawData, 'kycExpireDate'),
        kycRenewExpireDate: get(rawData, 'kycRenewExpireDate'),
        kycStatusVerifiedTimestamp: get(rawData, 'kycStatusVerifiedTimestamp'),
        kycStatusSuspendedTimestamp: get(
            rawData,
            'kycStatusSuspendedTimestamp',
        ),
        kycStatusReturnedTimestamp: get(rawData, 'kycStatusReturnedTimestamp'),
        kycStatusRenewTimestamp: get(rawData, 'kycStatusRenewTimestamp'),
        kycStatusRenewPendingTimestamp: get(
            rawData,
            'kycStatusRenewPendingTimestamp',
        ),
        kycStatusPendingTimestamp: get(rawData, 'kycStatusPendingTimestamp'),
        kycStatusDraftTimestamp: get(rawData, 'kycStatusDraftTimestamp'),
        kycStatusDeclinedTimestamp: get(rawData, 'kycStatusDeclinedTimestamp'),
        kycLastUpdatedTimestamp:
            get(rawData, 'kycStatusVerifiedTimestamp') ||
            get(rawData, 'kycStatusSuspendedTimestamp') ||
            get(rawData, 'kycStatusReturnedTimestamp') ||
            get(rawData, 'kycStatusRenewTimestamp') ||
            get(rawData, 'kycStatusRenewPendingTimestamp') ||
            get(rawData, 'kycStatusPendingTimestamp') ||
            get(rawData, 'kycStatusDraftTimestamp') ||
            get(rawData, 'kycStatusDeclinedTimestamp')
                ? max(
                      [
                          get(rawData, 'kycStatusVerifiedTimestamp'),
                          get(rawData, 'kycStatusSuspendedTimestamp'),
                          get(rawData, 'kycStatusReturnedTimestamp'),
                          get(rawData, 'kycStatusRenewTimestamp'),
                          get(rawData, 'kycStatusRenewPendingTimestamp'),
                          get(rawData, 'kycStatusPendingTimestamp'),
                          get(rawData, 'kycStatusDraftTimestamp'),
                          get(rawData, 'kycStatusDeclinedTimestamp'),
                      ]
                          .filter((d) => d)
                          .map((d) => new Date(d)),
                  ).toISOString()
                : null,
        reason: get(rawData, 'reason'),
        company: get(rawData, 'id'),
        estimate: get(rawData, 'estimate'),
        estimateDetail: processEstimate(get(rawData, ':estimate')),
        kycExpireDate: get(rawData, 'kycExpireDate'),
        kycRenewExpireDate: get(rawData, 'kycRenewExpireDate'),
        createdAt: get(rawData, 'createdAt'),
        broker: get(rawData, 'broker'),
        steps: get(rawData, ':steps', []),
        client: get(rawData, 'client'),
        companyUser: companyUser,
        brokerManagerDetail: get(rawData, 'brokerManager')
            ? processPerson(get(rawData, ':brokerManager'))
            : null,
        brokerManager: get(rawData, 'brokerManager'),
        // brokerSettingDetail: get(rawData, ':brokerSetting'),
        rawData,
    };
};
