import {useRef} from 'react';
import {useCalendarState, useRangeCalendarState} from 'react-stately';
import {
    useButton,
    useCalendar,
    useCalendarCell,
    useRangeCalendar,
} from 'react-aria';
import {
    createCalendar,
    isSameMonth,
    getWeeksInMonth,
    endOfMonth,
} from '@internationalized/date';
// import {CalendarGrid} from './CalendarGrid';
import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {Box, Button, Heading, Stack, Text} from '@chakra-ui/react';
import {useTranslation} from 'react-i18next';
import {useCalendarGrid} from 'react-aria';
import {useColorModeValueKey} from 'hooks/useColors';

const CalendarButton = (props) => {
    const ref = useRef();
    const {buttonProps} = useButton(props, ref);
    return (
        <Button {...buttonProps} ref={ref} size="sm">
            {props.children}
        </Button>
    );
};

const CalendarCell = ({state, date, currentMonth}) => {
    const ref = useRef();
    const {cellProps, buttonProps, isSelected, isInvalid, formattedDate} =
        useCalendarCell({date}, state, ref);

    const isOutsideMonth = !isSameMonth(currentMonth, date);

    return (
        <Box as="td" {...cellProps} textAlign="center">
            <Button
                {...buttonProps}
                ref={ref}
                hidden={isOutsideMonth}
                size={{base: 'xs', md: 'sm'}}
                colorScheme={isInvalid ? 'red' : 'gray'}
                variant={isSelected ? 'primary' : 'ghost'}
                width="100%"
                fontWeight={'normal'}
                color={useColorModeValueKey('text')}
            >
                {formattedDate}
            </Button>
        </Box>
    );
};

export function CalendarGrid({state, offset = {}}) {
    const {i18n} = useTranslation('app');
    const locale = i18n.language;
    const startDate = state.visibleRange.start.add(offset);
    const endDate = endOfMonth(startDate);
    const {gridProps, headerProps, weekDays} = useCalendarGrid(
        {
            startDate,
            endDate,
        },
        state,
    );

    // Get the number of weeks in the month so we can render the proper number of rows.
    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
    return (
        <table {...gridProps}>
            <thead {...headerProps}>
                <tr>
                    {weekDays.map((day, index) => (
                        <th key={index}>
                            <Text fontSize={{base: 'xs', md: 'sm'}}>{day}</Text>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                    <tr key={weekIndex}>
                        {state
                            .getDatesInWeek(weekIndex, startDate)
                            .map((date, i) =>
                                date ? (
                                    <CalendarCell
                                        key={i}
                                        state={state}
                                        date={date}
                                        currentMonth={startDate}
                                    />
                                ) : (
                                    <td key={i} />
                                ),
                            )}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

const Calendar = (props) => {
    const {i18n} = useTranslation('app');
    const state = useCalendarState({
        ...props,
        locale: i18n.language,
        createCalendar,
    });

    const ref = useRef();
    const {calendarProps, prevButtonProps, nextButtonProps, title} =
        useCalendar(props, state, ref);

    return (
        <div {...calendarProps} ref={ref}>
            <Box display="flex" alignItems="center" paddingBottom="4">
                <CalendarButton {...prevButtonProps}>
                    <ChevronLeftIcon w={6} h={6} />
                </CalendarButton>
                <Heading as="h2" size="sm" flex="1" textAlign="center">
                    {title}
                </Heading>
                <CalendarButton {...nextButtonProps}>
                    <ChevronRightIcon w={6} h={6} />
                </CalendarButton>
            </Box>
            <CalendarGrid state={state} />
        </div>
    );
};

export default Calendar;

export const RangeCalendar = (props) => {
    const {i18n} = useTranslation('app');
    const state = useRangeCalendarState({
        ...props,
        locale: i18n.language,
        createCalendar,
        visibleDuration: {months: 2},
    });

    const ref = useRef();
    const {calendarProps, prevButtonProps, nextButtonProps, title} =
        useRangeCalendar(props, state, ref);

    return (
        <div {...calendarProps} ref={ref}>
            <Box display="flex" alignItems="center" paddingBottom="4">
                <CalendarButton {...prevButtonProps}>
                    <ChevronLeftIcon w={6} h={6} />
                </CalendarButton>
                <Heading as="h2" size="sm" flex="1" textAlign="center">
                    {title}
                </Heading>
                <CalendarButton {...nextButtonProps}>
                    <ChevronRightIcon w={6} h={6} />
                </CalendarButton>
            </Box>
            <Stack
                direction={{base: 'column', sm: 'row'}}
                display="flex"
                gap="8"
            >
                <CalendarGrid state={state} />
                <CalendarGrid state={state} offset={{months: 1}} />
            </Stack>
        </div>
    );
};
