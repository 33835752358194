import Dashboard from 'components/Icon/Dashboard';
import {HiCash, HiOutlineCash, HiOutlineCode, HiOutlineDocumentReport} from 'react-icons/hi';
import {FiHelpCircle} from 'react-icons/fi';
import {HiOutlineCog} from 'react-icons/hi';
import {LuComponent} from 'react-icons/lu';
import {
    HiCog8Tooth,
    HiCurrencyDollar,
    HiGiftTop,
    HiHome,
    HiIdentification,
    HiOutlineCurrencyDollar,
    HiOutlineGiftTop,
    HiOutlineHome,
    HiOutlineUser,
    HiOutlineUserGroup,
    HiOutlineWallet,
    HiTicket,
    HiUser,
    HiUserGroup,
} from 'react-icons/hi2';
import Convert from 'components/Icon/Convert';
import { MdOutlineSupportAgent } from 'react-icons/md';
import Gear from 'components/BaseIcon/Gear';

// import {MdDashboard as Dashboard} from 'react-icons/md';
function path(root, sublink) {
    return `${root}${sublink}`;
}

export const ROOTS_AUTH = '/auth';
export const ROOTS_APP = '/app';
export const ROOTS_ADMIN = '/admin';

export const ROOTS_DEV = '/dev';

export const devPaths = {
    root: ROOTS_DEV,
    apis: path(ROOTS_DEV, '/api-explorer'),
};

export const edgeCasePaths = {
    403: path('', '/403'),
    redirect: path('', '/redirect'),
    maintenance: path('', '/maintenance'),
};

export const authPaths = {
    //auth
    login: path(ROOTS_AUTH, '/login'),
    forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    signUp: path(ROOTS_AUTH, '/sign-up'),
    signUpVerify: path(ROOTS_AUTH, '/sign-up-verify'),
    logout: path(ROOTS_AUTH, '/logout'),
};

export const paths = {
    root: ROOTS_APP,
    //app
    dashboard: path(ROOTS_APP, '/dashboard'),
    profile: path(ROOTS_APP, '/profile'),
    accounts: path(ROOTS_APP, '/accounts'),
    beneficiaries: path(ROOTS_APP, '/beneficiaries'),
    account: path(ROOTS_APP, '/settings/account'),
    notificationSetting: path(ROOTS_APP, '/settings/notifications'),
    systemSettings: path(ROOTS_APP, '/settings/systemSettings'),
    security: path(ROOTS_APP, '/settings/security'),
    contact: path(ROOTS_APP, '/settings/contact'),
    settings: path(ROOTS_APP, '/settings'),
    components: path(ROOTS_APP, '/components'),
    //kyc paths
    kyc: path(ROOTS_APP, '/kyc'),
    kyb: path(ROOTS_APP, '/kyb'),
    //help center
    helpCenter: path(ROOTS_APP, '/help-center'),
    support: path(ROOTS_APP, '/help-center/support'),
    supportDetail: (id) => path(ROOTS_APP, `/help-center/support/${id}`),
    newSupport: path(ROOTS_APP, '/help-center/support/newSupport'),
    QAndA: path(ROOTS_APP, '/help-center/q-and-a'),

    //api
    apiAccess: path(ROOTS_APP, '/apis'),
    apis: path(ROOTS_APP, '/apis/api-explorer'),
    apiLog: path(ROOTS_APP, '/apis/api-log'),
    apiKey: path(ROOTS_APP, '/apis/api-key'),

    //referral
    referralRoot: path(ROOTS_APP, '/referral'),
    referees: path(ROOTS_APP, '/referral/referees'),
    referralCommission: path(ROOTS_APP, '/referral/commission'),

    //report
    reports: path(ROOTS_APP, '/reports'),
};

export const adminPaths = {
    root: ROOTS_ADMIN,
    profile: path(ROOTS_ADMIN, '/profile'),
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    clientsHome: path(ROOTS_ADMIN, '/clients'),
    clients: path(ROOTS_ADMIN, '/clients/client-list'),
    clientDetail: (id) => path(ROOTS_ADMIN, `/clients/client-list/${id}`),
    clientDetailWithTab: (id, tab) =>
        path(ROOTS_ADMIN, `/clients/client-list/${id}?tab=${tab}`),
    clientOverview: path(ROOTS_ADMIN, '/clients/overview'),
    clientsProspects: path(ROOTS_ADMIN, '/clients/prospects'),
    ibAdmin: path(ROOTS_ADMIN, '/clients/ib-admin'),
    riskAlerts: path(ROOTS_ADMIN, '/clients/risk-alerts'),
    clientNotes: path(ROOTS_ADMIN, '/clients/notes'),
    interactions: path(ROOTS_ADMIN, '/clients/interactions'),
    clientWithStatus: (status) =>
        path(ROOTS_ADMIN, `/clients/client-list?status=${status}`),

    prospect: path(ROOTS_ADMIN, '/clients/prospect'),
    account: path(ROOTS_ADMIN, '/settings/account'),
    notificationSetting: path(ROOTS_ADMIN, '/settings/notifications'),
    systemSettings: path(ROOTS_ADMIN, '/settings/systemSettings'),
    security: path(ROOTS_ADMIN, '/settings/security'),
    contact: path(ROOTS_ADMIN, '/settings/contact'),
    settings: path(ROOTS_ADMIN, '/settings'),
    404: '/404',
    platformSettings: path(ROOTS_ADMIN, '/platform-settings'),
    accessControl: path(ROOTS_ADMIN, '/platform-settings/access-control'),
    generalSettings: path(ROOTS_ADMIN, '/platform-settings/general-settings'),
    blackWhitelist: path(ROOTS_ADMIN, '/platform-settings/blackWhitelist'),
    currencies: path(ROOTS_ADMIN, '/platform-settings/currencies'),
    platformMetadata: path(ROOTS_ADMIN, '/platform-settings/metadata'),
    languages: path(ROOTS_ADMIN, '/platform-settings/languages'),
    faq: path(ROOTS_ADMIN, '/platform-settings/faq'),

    newFaq: path(ROOTS_ADMIN, '/platform-settings/faq/newFaq'),
    editFaq: (id) =>
        path(ROOTS_ADMIN, `/platform-settings/faq/editFaq/${id}`),

    templates: path(ROOTS_ADMIN, '/platform-settings/templates'),
    templatesWithTab: (tab = '') =>
        path(ROOTS_ADMIN, `/platform-settings/templates/?tab=${tab}`),
    newEmail: path(ROOTS_ADMIN, '/platform-settings/templates/emails/newEmail'),
    editEmail: (id) =>
        path(ROOTS_ADMIN, `/platform-settings/templates/emails/editEmail/${id}`),
    newMessage: path(ROOTS_ADMIN, '/platform-settings/templates/messages/newMessage'),
    editMessage: (id) =>
        path(ROOTS_ADMIN, `/platform-settings/templates/messages/editMessage/${id}`),


    tickets: path(ROOTS_ADMIN, '/tickets'),
    ticketDetail: (id) => path(ROOTS_ADMIN, `/tickets/${id}`),
    newTicket: path(ROOTS_ADMIN, '/tickets/newTicket'),

    transactionsHome: path(ROOTS_ADMIN, '/transactions'),
    transactions: path(ROOTS_ADMIN, '/transactions/transactions'),
    distributions: path(ROOTS_ADMIN, '/transactions/cny-distribution'),
    commissions: path(ROOTS_ADMIN, '/transactions/commissions'),
    ledgers: path(ROOTS_ADMIN, '/transactions/ledgers'),
};

export const PATH_AFTER_LOGIN = paths.dashboard;
export const PATH_AFTER_ADMIN_LOGIN = adminPaths.dashboard;
export const PATH_TO_REDIRECT = edgeCasePaths.redirect;

export const navDrawerConfig = ({
    isIB,
    isCompany,
    amlInvalid,
    isInMobile,
    apiEnabled,
    kycShow,
}) => [
    {
        labelKey: 'dashboard',
        value: 'dashboard',
        path: paths.dashboard,
        Icon: HiOutlineHome,
        kycValid: false,
    },
    ...(kycShow
        ? [
              {
                  labelKey: isCompany ? 'kyb' : 'kyc',
                  value: isCompany ? 'kyb' : 'kyc',
                  path: isCompany ? paths.kyb : paths.kyc,
                  Icon: HiCash,
                  kycValid: false,
              },
          ]
        : []),
    // {
    //     labelKey: 'accounts',
    //     value: 'accounts',
    //     path: paths.accounts,
    //     Icon: HiOutlineWallet,
    //     kycValid: true,
    //     isDisabled: amlInvalid,
    // },
    // {
    //     labelKey: 'beneficiaries',
    //     value: 'beneficiaries',
    //     path: paths.beneficiaries,
    //     Icon: HiOutlineUser,
    //     kycValid: true,
    //     isDisabled: amlInvalid,
    // },
    // {
    //     labelKey: 'paymentIn',
    //     value: 'paymentIn',
    //     path: paths.paymentIn,
    //     Icon: HiOutlineCurrencyDollar,
    //     kycValid: true,
    //     isDisabled: amlInvalid,
    // },
    // {
    //     labelKey: 'paymentOut',
    //     value: 'paymentOut',
    //     path: paths.payments,
    //     Icon: HiOutlineCash,
    //     kycValid: true,
    //     isDisabled: amlInvalid,
    // },
    {
        labelKey: 'referral',
        value: 'referral',
        path: paths.referralRoot,
        Icon: HiOutlineGiftTop,
        kycValid: false,
        subMenu: true,
        paths: [
            {
                labelKey: 'referees',
                value: 'referees',
                path: paths.referees,
                kycValid: false,
            },
            {
                labelKey: 'commission',
                value: 'commission',
                path: paths.referralCommission,
                kycValid: false,
            },
        ],
    },
    {
        labelKey: 'reports',
        value: 'reports',
        path: paths.reports,
        Icon: HiOutlineDocumentReport,
        kycValid: false,
    },
    {
        labelKey: 'helpCenter',
        value: 'helpCenter',
        path: paths.helpCenter,
        Icon: MdOutlineSupportAgent,
        kycValid: false,
        subMenu: true,
        paths: [
            {
                labelKey: 'support',
                value: 'support',
                path: paths.support,
                kycValid: false,
                new: true,
            },
            {
                labelKey: 'QAndA',
                value: 'Q&A',
                path: paths.QAndA,
                kycValid: false,
            },
        ],
    },
    {
        labelKey: 'APIAccess',
        value: 'apis',
        path: paths.apiAccess,
        Icon: HiOutlineCode,
        kycValid: true,
        subMenu: true,
        paths: [
            {
                labelKey: 'explorer',
                value: 'explorer',
                path: devPaths.apis,
                kycValid: false,
            },
            {
                labelKey: 'apiLog',
                value: 'apiLog',
                path: paths.apiLog,
                kycValid: false,
            },
            {
                labelKey: 'apiKey',
                value: 'apiKey',
                path: paths.apiKey,
                kycValid: false,
            },
        ],
    },
    // {
    //     labelKey: 'components',
    //     value: 'components',
    //     path: paths.components,
    //     Icon: LuComponent,
    // },
    //   ]
    // : []),
];
export const navDrawerAdminConfig = ({isInMobile, getPermission}) => [
    {
        labelKey: 'dashboard',
        value: 'dashboard',
        path: adminPaths.dashboard,
        Icon: HiHome,
        kycValid: false,
    },
    {
        labelKey: 'platformSettings',
        value: 'platformSettings',
        path: adminPaths.platformSettings,
        Icon: Gear,
        hasPermission: getPermission('platformSettings'),
        subMenu: true,
        paths: [
            {
                labelKey: 'currencies',
                value: 'currencies',
                path: adminPaths.currencies,
            },
            {
                labelKey: 'accessControl',
                value: 'accessControl',
                path: adminPaths.accessControl,
                hasPermission: getPermission('platformSettings.accessControl'),
            },
            {
                labelKey: 'generalSettings',
                value: 'generalSettings',
                path: adminPaths.generalSettings,
                hasPermission: getPermission(
                    'platformSettings.generalSettings',
                ),
            },
            {
                labelKey: 'blackWhitelist',
                value: 'blackWhitelist',
                path: adminPaths.blackWhitelist,
                hasPermission: getPermission('platformSettings.blackWhitelist'),
            },
            {
                labelKey: 'languages',
                value: 'languages',
                path: adminPaths.languages,
                hasPermission: getPermission('platformSettings.languages'),
            },
            {
                labelKey: 'faq',
                value: 'faq',
                path: adminPaths.faq,
                hasPermission: getPermission('platformSettings.faq'),
            },
            {
                labelKey: 'templates',
                value: 'templates',
                path: adminPaths.templates,
                hasPermission: getPermission('platformSettings.templates'),
            },
        ],
    },
    {
        labelKey: 'clients',
        value: 'clients',
        path: adminPaths.clientsHome,
        subMenu: true,
        Icon: HiUserGroup,
        hasPermission: getPermission('clientsHome'),
        paths: [
            {
                labelKey: 'overview',
                value: 'overview',
                path: adminPaths.clientOverview,
                hasPermission: getPermission('clientsHome.overview'),
            },
            {
                labelKey: 'prospects',
                value: 'prospects',
                path: adminPaths.clientsProspects,
                hasPermission: getPermission('clientsHome.clientsProspect'),
            },
            {
                labelKey: 'clients',
                value: 'clients',
                path: adminPaths.clients,
                hasPermission: getPermission('clientsHome.clients'),
            },
            {
                labelKey: 'ibAdmin',
                value: 'ibAdmin',
                path: adminPaths.ibAdmin,
                hasPermission: getPermission('clientsHome.ibAdmin'),
            },
            {
                labelKey: 'notes',
                value: 'notes',
                path: adminPaths.clientNotes,
                hasPermission: getPermission('clientsHome.notes'),
            },
            {
                labelKey: 'interactions',
                value: 'interactions',
                path: adminPaths.interactions,
                hasPermission: getPermission('clientsHome.interactions'),
            },
        ],
    },
    {
        labelKey: 'transactions',
        value: 'transactions',
        path: adminPaths.transactionsHome,
        Icon: Convert,
        subMenu: true,
        hasPermission: getPermission('transactions'),
        paths: [
            {
                labelKey: 'transactions',
                value: 'transactions',
                path: adminPaths.transactions,
                hasPermission: getPermission('transactions.transactions'),
            },
            {
                labelKey: 'ledgers',
                value: 'ledgers',
                path: adminPaths.ledgers,
                hasPermission: getPermission('transactions.ledgers'),
            },
            {
                labelKey: 'commissions',
                value: 'commissions',
                path: adminPaths.commissions,
                hasPermission: getPermission('transactions.commissions'),
            },
        ],
    },
    {
        labelKey: 'tickets',
        value: 'tickets',
        path: adminPaths.tickets,
        Icon: HiTicket,
        kycValid: false,
    },
    // {
    //     labelKey: 'platformSettings',
    //     value: 'platformSettings',
    //     path: 'platformSettings',
    //     subMenu: true,
    //     Icon: HiCog8Tooth,
    //     path: adminPaths.platformSettings,
    //     paths: [
    //         {
    //             labelKey: 'currencies',
    //             value: 'currencies',
    //             path: adminPaths.currencies,
    //         },
    //         {
    //             labelKey: 'metadata',
    //             value: 'metadata',
    //             path: adminPaths.platformMetadata,
    //         },
    //     ],
    // },
];
