export const clientAllPermissions = [
    {
        key: 'dashboard',
        write: true,
        read: true,
        sub: [
            {
                key: 'ads',
                write: false,
                read: true,
            },
            {
                key: 'balanceTrends',
                write: true,
                read: true,
            },
            {
                key: 'transactionSummary',
                write: false,
                read: true,
            },
            {
                key: 'LatestExchangeRate',
                write: true,
                read: true,
            },
            {
                key: 'kycWizard',
                write: true,
                read: true,
            },
            {
                key: 'knowledge',
                write: false,
                read: true,
            },
        ],
    },
    {
        key: 'clientManagement',
        write: true,
        read: true,
        sub: [
            {
                key: 'registration',
                write: true,
                read: true,
            },
            {
                key: 'forgetPassword',
                write: true,
                read: true,
            },
            {
                key: 'clientManagement',
                write: true,
                read: true,
            },
            {
                key: 'clientContact',
                write: true,
                read: true,
            },
            {
                key: 'clientKyc',
                write: true,
                read: true,
            },
            {
                key: 'clientAml',
                write: true,
                read: true,
            },
            {
                key: 'clientSetting',
                write: true,
                read: true,
            },
            {
                key: 'multiLanguage',
                write: true,
                read: true,
            },
            {
                key: 'notifications',
                write: true,
                read: true,
            },
            {
                key: 'emails',
                write: true,
                read: true,
            },
            {
                key: 'loginHistory',
                write: true,
                read: true,
            },
            {
                key: 'twoFactor',
                write: true,
                read: true,
            }
        ]
    },
    {
        key: 'offerings',
        write: true,
        read: true,
        sub: [
            {
                key: 'globalPaymentCollections',
                write: true,
                read: true,
            },
            {
                key: 'cryptoTopups',
                write: true,
                read: true,
            },
            {
                key: 'conversionLiveRate',
                write: true,
                read: true,
            },
            {
                key: 'globalPayments',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'currencyAccounts',
        write: true,
        read: true,
        sub: [
            // {
            //     key: 'vaByBC',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'vaByCC',
            //     write: true,
            //     read: true,
            // },
            {
                key: 'applyCurrencyAccount',
                write: true,
                read: true,
            },
            // {
            //     key: 'bsbAccount',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'fastAccount',
            //     write: true,
            //     read: true,
            // },
            {
                key: 'accountDetails',
                write: true,
                read: true,
            },
            // {
            //     key: 'vaPaymentCollections',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'vaAdjustments',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'failedPayment',
            //     write: true,
            //     read: true,
            // }
        ],
    },
    {
        key: 'beneficiaries',
        write: true,
        read: true,
        sub: [
            {
                key: 'beneficiaryList',
                write: true,
                read: true,
            },
            {
                key: 'applyBeneficiaryAccount',
                write: true,
                read: true,
            },
            {
                key: 'editBeneficiary',
                write: true,
                read: true,
            },
            {
                key: 'hideBeneficiary',
                write: true,
                read: true,
            },
            // {
            //     key: 'withdrawalCryptoWallets',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'filterBeneficiaries',
            //     write: true,
            //     read: true,
            // },
            {
                key: 'beneficiaryBankDetails',
                write: true,
                read: true,
            }
        ],
    },
    {
        key: 'paymentIn',
        write: true,
        read: true,
        sub: [
            // {
            //     key: 'moneyCollection',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'moneyRefund',
            //     write: true,
            //     read: true,
            // },
            // {
            //     key: 'moneyAdjustments',
            //     write: true,
            //     read: true,
            // },
            {
                key: 'transactions',
                write: true,
                read: true,
            },
        ]
    },
    {
        key: 'cryptoTopUps',
        write: true,
        read: true,
        sub: [
            {
                key: 'cryptoTopUps',
                write: true,
                read: true,
            },
            {
                key: 'hdWallets ',
                write: true,
                read: true,
            },
            {
                key: 'clientRegestedWallet',
                write: true,
                read: true,
            },
            {
                key: 'customerSupport',
                write: true,
                read: true,
            },
            {
                key: 'cryptoLiveRate',
                write: true,
                read: true,
            },
            {
                key: 'transactions',
                write: true,
                read: true,
            },
        ]
    },
    {
        key: 'conversions',
        write: true,
        read: true,
        sub: [
            {
                key: 'conversion',
                write: true,
                read: true,
            },
            {
                key: 'transactions',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'transfers',
        write: true,
        read: true,
        sub: [
            {
                key: 'transfer',
                write: true,
                read: true,
            },
            {
                key: 'transactions',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'paymentOut',
        write: true,
        read: true,
        sub: [
            {
                key: 'pay',
                write: true,
                read: true,
            },
            {
                key: 'withdraw',
                write: true,
                read: true,
            },
            {
                key: 'bulkPay',
                write: true,
                read: true,
            },
            {
                key: 'transactions',
                write: true,
                read: true,
            },
            {
                key: 'cryptoWithdrawals',
                write: true,
                read: true,
            }
        ],
    },
    {
        key: 'referrals',
        write: true,
        read: true,
        sub: [
            {
                key: 'invite',
                write: true,
                read: true,
            },
            {
                key: 'applyIB',
                write: true,
                read: true,
            },
            {
                key: 'viewBrochure',
                write: true,
                read: true,
            },
            // {
            //     key: 'list',
            //     write: true,
            //     read: true,
            // },
            {
                key: 'commissions',
                write: true,
                read: true,
            },
            {
                key: 'summary',
                write: true,
                read: true,
            },
        ],
    },
    {
        key: 'reports',
        write: true,
        read: true,
        sub: [
            {
                key: 'transactions',
                write: true,
                read: true,
            },
            {
                key: 'statements',
                write: true,
                read: true,
            }
        ]
    },
    {
        key: 'development',
        write: true,
        read: true,
        sub: [
            {
                key: 'keyManagement',
                write: true,
                read: true,
            },
            {
                key: 'explororTesting',
                write: true,
                read: true,
            },
            {
                key: 'callHistory',
                write: true,
                read: true,
            },
            {
                key: 'webhookSetting',
                write: true,
                read: true,
            }
        ]
    },
    {
        key: 'help',
        write: true,
        read: true,
    },
];

export const companyUserPermissionSettings = {
    CLIENT_USER: clientAllPermissions,
    CLIENT_COMPANY_ADMIN: clientAllPermissions,
    CLIENT_COMPANY_OPERATOR: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'balanceTrends',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionSummary',
                    write: false,
                    read: true,
                },
                {
                    key: 'LatestExchangeRate',
                    write: false,
                    read: true,
                },
                {
                    key: 'knowledge',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'clientManagement',
            write: true,
            read: true,
            sub: [
                {
                    key: 'registration',
                    write: true,
                    read: true,
                },
                {
                    key: 'forgetPassword',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientManagement',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientKyc',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientAml',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientSetting',
                    write: true,
                    read: true,
                },
                {
                    key: 'multiLanguage',
                    write: true,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: true,
                    read: true,
                },
                {
                    key: 'emails',
                    write: true,
                    read: true,
                },
                {
                    key: 'loginHistory',
                    write: true,
                    read: true,
                },
                {
                    key: 'twoFactor',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'globalPaymentCollections',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoTopups',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversionLiveRate',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'currencyAccounts',
            write: true,
            read: true,
            sub: [
                // {
                //     key: 'vaByBC',
                //     write: true,
                //     read: true,
                // },
                // {
                //     key: 'vaByCC',
                //     write: true,
                //     read: true,
                // },
                {
                    key: 'applyCurrencyAccount',
                    write: true,
                    read: true,
                },
                // {
                //     key: 'bsbAccount',
                //     write: true,
                //     read: true,
                // },
                // {
                //     key: 'fastAccount',
                //     write: true,
                //     read: true,
                // },
                {
                    key: 'accountDetails',
                    write: true,
                    read: true,
                },
                // {
                //     key: 'vaPaymentCollections',
                //     write: true,
                //     read: true,
                // },
                // {
                //     key: 'vaAdjustments',
                //     write: true,
                //     read: true,
                // },
                // {
                //     key: 'failedPayment',
                //     write: true,
                //     read: true,
                // },
            ],
        },
        {
            key: 'beneficiaries',
            write: true,
            read: true,
            sub: [
                {
                    key: 'beneficiaryList',
                    write: true,
                    read: true,
                },
                {
                    key: 'applyBeneficiaryAccount',
                    write: true,
                    read: true,
                },
                {
                    key: 'editBeneficiary',
                    write: true,
                    read: true,
                },
                {
                    key: 'hideBeneficiary',
                    write: true,
                    read: true,
                },
                // {
                //     key: 'withdrawalCryptoWallets',
                //     write: true,
                //     read: true,
                // },
                // {
                //     key: 'filterBeneficiaries',
                //     write: true,
                //     read: true,
                // },
                {
                    key: 'beneficiaryBankDetails',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'paymentIn',
            write: true,
            read: true,
            sub: [
                // {
                //     key: 'moneyCollection',
                //     write: true,
                //     read: true,
                // },
                // {
                //     key: 'moneyRefund',
                //     write: true,
                //     read: true,
                // },
                // {
                //     key: 'moneyAdjustments',
                //     write: true,
                //     read: true,
                // },
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'cryptoTopUps',
            write: true,
            read: true,
            sub: [
                {
                    key: 'cryptoTopUps',
                    write: true,
                    read: true,
                },
                {
                    key: 'hdWallets ',
                    write: true,
                    read: true,
                },
                {
                    key: 'clientRegestedWallet',
                    write: true,
                    read: true,
                },
                {
                    key: 'customerSupport',
                    write: true,
                    read: true,
                },
                {
                    key: 'cryptoLiveRate',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'conversions',
            write: true,
            read: true,
            sub: [
                {
                    key: 'conversion',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'transfers',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transfer',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'paymentOut',
            write: true,
            read: true,
            sub: [
                {
                    key: 'pay',
                    write: true,
                    read: true,
                },
                {
                    key: 'withdraw',
                    write: true,
                    read: true,
                },
                {
                    key: 'bulkPay',
                    write: true,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'cryptoWithdrawals',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'referrals',
            write: true,
            read: true,
            sub: [
                {
                    key: 'invite',
                    write: true,
                    read: true,
                },
                {
                    key: 'applyIB',
                    write: true,
                    read: true,
                },
                {
                    key: 'viewBrochure',
                    write: true,
                    read: true,
                },
                // {
                //     key: 'list',
                //     write: true,
                //     read: true,
                // },
                {
                    key: 'commissions',
                    write: true,
                    read: true,
                },
                {
                    key: 'summary',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'reports',
            write: true,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: true,
                    read: true,
                },
                {
                    key: 'statements',
                    write: true,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: true,
            read: true,
            sub: [
                {
                    key: 'keyManagement',
                    write: false,
                    read: true,
                },
                {
                    key: 'explororTesting',
                    write: true,
                    read: true,
                },
                {
                    key: 'callHistory',
                    write: true,
                    read: true,
                },
                {
                    key: 'webhookSetting',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'help',
            write: false,
            read: true,
        },
    ],
    CLIENT_COMPANY_READONLY: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'balanceTrends',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionSummary',
                    write: false,
                    read: true,
                },
                {
                    key: 'LatestExchangeRate',
                    write: false,
                    read: true,
                },
                {
                    key: 'knowledge',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'clientManagement',
            write: false,
            read: true,
            sub: [
                {
                    key: 'registration',
                    write: false,
                    read: true,
                },
                {
                    key: 'forgetPassword',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientManagement',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientKyc',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientAml',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientSetting',
                    write: false,
                    read: true,
                },
                {
                    key: 'multiLanguage',
                    write: false,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: false,
                    read: true,
                },
                {
                    key: 'emails',
                    write: false,
                    read: true,
                },
                {
                    key: 'loginHistory',
                    write: false,
                    read: true,
                },
                {
                    key: 'twoFactor',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'globalPaymentCollections',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoTopups',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversionLiveRate',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'currencyAccounts',
            write: false,
            read: true,
            sub: [
                // {
                //     key: 'vaByBC',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'vaByCC',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'applyCurrencyAccount',
                    write: false,
                    read: true,
                },
                // {
                //     key: 'bsbAccount',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'fastAccount',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'accountDetails',
                    write: false,
                    read: true,
                },
                // {
                //     key: 'vaPaymentCollections',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'vaAdjustments',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'failedPayment',
                //     write: false,
                //     read: true,
                // },
            ],
        },
        {
            key: 'beneficiaries',
            write: false,
            read: true,
            sub: [
                {
                    key: 'beneficiaryList',
                    write: false,
                    read: true,
                },
                {
                    key: 'applyBeneficiaryAccount',
                    write: false,
                    read: true,
                },
                {
                    key: 'editBeneficiary',
                    write: false,
                    read: true,
                },
                {
                    key: 'hideBeneficiary',
                    write: false,
                    read: true,
                },
                // {
                //     key: 'withdrawalCryptoWallets',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'filterBeneficiaries',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'beneficiaryBankDetails',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'paymentIn',
            write: false,
            read: true,
            sub: [
                // {
                //     key: 'moneyCollection',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'moneyRefund',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'moneyAdjustments',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'cryptoTopUps',
            write: false,
            read: true,
            sub: [
                {
                    key: 'cryptoTopUps',
                    write: false,
                    read: true,
                },
                {
                    key: 'hdWallets ',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientRegestedWallet',
                    write: false,
                    read: true,
                },
                {
                    key: 'customerSupport',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoLiveRate',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'conversions',
            write: false,
            read: true,
            sub: [
                {
                    key: 'conversion',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'transfers',
            write: false,
            read: true,
            sub: [
                {
                    key: 'transfer',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'paymentOut',
            write: false,
            read: true,
            sub: [
                {
                    key: 'pay',
                    write: false,
                    read: true,
                },
                {
                    key: 'withdraw',
                    write: false,
                    read: true,
                },
                {
                    key: 'bulkPay',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoWithdrawals',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'referrals',
            write: false,
            read: true,
            sub: [
                {
                    key: 'invite',
                    write: false,
                    read: true,
                },
                {
                    key: 'applyIB',
                    write: false,
                    read: true,
                },
                {
                    key: 'viewBrochure',
                    write: false,
                    read: true,
                },
                // {
                //     key: 'list',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'commissions',
                    write: false,
                    read: true,
                },
                {
                    key: 'summary',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'reports',
            write: false,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'statements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'keyManagement',
                    write: false,
                    read: true,
                },
                {
                    key: 'explororTesting',
                    write: false,
                    read: true,
                },
                {
                    key: 'callHistory',
                    write: false,
                    read: true,
                },
                {
                    key: 'webhookSetting',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'help',
            write: false,
            read: true,
        },
    ],
    CLIENT_COMPANY_CONTACT: [
        {
            key: 'dashboard',
            write: false,
            read: true,
            sub: [
                {
                    key: 'ads',
                    write: false,
                    read: true,
                },
                {
                    key: 'balanceTrends',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactionSummary',
                    write: false,
                    read: true,
                },
                {
                    key: 'LatestExchangeRate',
                    write: false,
                    read: true,
                },
                {
                    key: 'knowledge',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'clientManagement',
            write: false,
            read: true,
            sub: [
                {
                    key: 'registration',
                    write: false,
                    read: true,
                },
                {
                    key: 'forgetPassword',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientManagement',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientKyc',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientAml',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientSetting',
                    write: false,
                    read: true,
                },
                {
                    key: 'multiLanguage',
                    write: false,
                    read: true,
                },
                {
                    key: 'notifications',
                    write: false,
                    read: true,
                },
                {
                    key: 'emails',
                    write: false,
                    read: true,
                },
                {
                    key: 'loginHistory',
                    write: false,
                    read: true,
                },
                {
                    key: 'twoFactor',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'offerings',
            write: false,
            read: true,
            sub: [
                {
                    key: 'globalPaymentCollections',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoTopups',
                    write: false,
                    read: true,
                },
                {
                    key: 'conversionLiveRate',
                    write: false,
                    read: true,
                },
                {
                    key: 'globalPayments',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'currencyAccounts',
            write: false,
            read: true,
            sub: [
                // {
                //     key: 'vaByBC',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'vaByCC',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'applyCurrencyAccount',
                    write: false,
                    read: true,
                },
                // {
                //     key: 'bsbAccount',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'fastAccount',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'accountDetails',
                    write: false,
                    read: true,
                },
                // {
                //     key: 'vaPaymentCollections',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'vaAdjustments',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'failedPayment',
                //     write: false,
                //     read: true,
                // },
            ],
        },
        {
            key: 'beneficiaries',
            write: false,
            read: true,
            sub: [
                {
                    key: 'beneficiaryList',
                    write: false,
                    read: true,
                },
                {
                    key: 'applyBeneficiaryAccount',
                    write: false,
                    read: true,
                },
                {
                    key: 'editBeneficiary',
                    write: false,
                    read: true,
                },
                {
                    key: 'hideBeneficiary',
                    write: false,
                    read: true,
                },
                // {
                //     key: 'withdrawalCryptoWallets',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'filterBeneficiaries',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'beneficiaryBankDetails',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'paymentIn',
            write: false,
            read: true,
            sub: [
                // {
                //     key: 'moneyCollection',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'moneyRefund',
                //     write: false,
                //     read: true,
                // },
                // {
                //     key: 'moneyAdjustments',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'cryptoTopUps',
            write: false,
            read: true,
            sub: [
                {
                    key: 'cryptoTopUps',
                    write: false,
                    read: true,
                },
                {
                    key: 'hdWallets ',
                    write: false,
                    read: true,
                },
                {
                    key: 'clientRegestedWallet',
                    write: false,
                    read: true,
                },
                {
                    key: 'customerSupport',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoLiveRate',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'conversions',
            write: false,
            read: true,
            sub: [
                {
                    key: 'conversion',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'transfers',
            write: false,
            read: true,
            sub: [
                {
                    key: 'transfer',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'paymentOut',
            write: false,
            read: true,
            sub: [
                {
                    key: 'pay',
                    write: false,
                    read: true,
                },
                {
                    key: 'withdraw',
                    write: false,
                    read: true,
                },
                {
                    key: 'bulkPay',
                    write: false,
                    read: true,
                },
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'cryptoWithdrawals',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'referrals',
            write: false,
            read: true,
            sub: [
                {
                    key: 'invite',
                    write: false,
                    read: true,
                },
                {
                    key: 'applyIB',
                    write: false,
                    read: true,
                },
                {
                    key: 'viewBrochure',
                    write: false,
                    read: true,
                },
                // {
                //     key: 'list',
                //     write: false,
                //     read: true,
                // },
                {
                    key: 'commissions',
                    write: false,
                    read: true,
                },
                {
                    key: 'summary',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'reports',
            write: false,
            read: true,
            sub: [
                {
                    key: 'transactions',
                    write: false,
                    read: true,
                },
                {
                    key: 'statements',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'keyManagement',
                    write: false,
                    read: true,
                },
                {
                    key: 'explororTesting',
                    write: false,
                    read: true,
                },
                {
                    key: 'callHistory',
                    write: false,
                    read: true,
                },
                {
                    key: 'webhookSetting',
                    write: false,
                    read: true,
                },
            ],
        },
        {
            key: 'help',
            write: false,
            read: true,
        },
    ],
    BROKER_COMPANY_DEVELOPER: [
        {
            key: 'development',
            write: false,
            read: true,
            sub: [
                {
                    key: 'keyManagement',
                    write: false,
                    read: true,
                },
                {
                    key: 'explororTesting',
                    write: false,
                    read: true,
                },
                {
                    key: 'callHistory',
                    write: false,
                    read: true,
                },
                {
                    key: 'webhookSetting',
                    write: false,
                    read: true,
                },
            ],
        },
    ],
};