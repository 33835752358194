// import {Drawer, Portal} from '@mui/material';
import {useColorModeValueKey} from 'hooks/useColors';

import React from 'react';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, useBreakpointValue } from '@chakra-ui/react';

const FunctionDrawer = (props) => {
    const {
        open,
        children,
        onClose,
        title,
        actions,
        headerAction,
        closeOnOverlayClick=false,
    } = props;
    const brighterBg = useColorModeValueKey('brighterBg');
    const size = useBreakpointValue({base: 'full', md: 'md'});

    const primaryColor = useColorModeValueKey('primary');

    // useEffect(() => {
    //     if (open){
    //          window.zESettings = {
    //              webWidget: {
    //                  zIndex: -1,
    //              },
    //          };
    //     }else{
    //         window.zESettings = {
    //             webWidget: {
    //                 zIndex: 10000,
    //             },
    //         };
    //     }
    // }, [open]);
    // console.log(open)
    return (
        <Drawer
            isOpen={open}
            placement="right"
            onClose={onClose}
            size={size}
            closeOnOverlayClick={closeOnOverlayClick}
            
        >
            <DrawerContent bg={brighterBg} >
                <DrawerCloseButton />
                <DrawerHeader color={primaryColor}>{title}</DrawerHeader>
                <DrawerBody>{children}</DrawerBody>
                <DrawerFooter>{actions && actions}</DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
};

export default FunctionDrawer;
