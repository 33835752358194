import {Box, Button, Divider, HStack, useToken} from '@chakra-ui/react';
import {useColorModeValueKey, useReadableTextColor} from 'hooks/useColors';
import React from 'react';

const BtnGroup = (props = {}) => {
    const {
        btns = [],
        onSelect,
        selected,
        disabled,
        nullable = false,
        variant = 'solid',
        fontSize,
        size = 'sm',
        ...others
    } = props;

    const textColor = useColorModeValueKey('text');
    const whiteColor = useColorModeValueKey('brighterBg');
    const borderColor = useColorModeValueKey('border');
    const primaryColor = useColorModeValueKey('primary');
    const selectedTextColor = useReadableTextColor(
        useToken('colors', primaryColor),
    );
    return (
        <HStack
            borderColor={borderColor}
            borderWidth={1}
            borderStyle="solid"
            rounded={'md'}
            overflow="hidden"
            divider={<Divider orientation="vertical" />}
            spacing={0}
            {...others}
        >
            {btns.map((item) => (
                <Button
                    rounded={'none'}
                    variant={'unstyled'}
                    size={size}
                    px={3}
                    key={item.value}
                    // h={size === 'sm' ? '32px' : '40px'}
                    onClick={() => {
                        onSelect(
                            nullable && item.value === selected
                                ? null
                                : item.value,
                        );
                    }}
                    flex={1}
                    isDisabled={disabled || item.disabled}
                    bg={
                        selected === item.value
                            ? primaryColor
                            : variant === 'filled'
                            ? 'white'
                            : whiteColor
                    }
                    color={
                        selected === item.value ? selectedTextColor : textColor
                    }
                >
                    {item.label}
                </Button>
            ))}
        </HStack>
    );
};

export default BtnGroup;
