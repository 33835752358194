import React, { useEffect } from 'react';
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Code,
    Grid,
    ColorModeScript,
} from '@chakra-ui/react';
import {ColorModeSwitcher} from './ColorModeSwitcher';
import {Logo} from './Logo';
import theme from './theme';
import Wizard from './page/Wizard';
import RootRouter from 'routes';
import {FormProvider} from 'components/FormGenerator/ContextProvider';
import {
    formComponents,
    viewComponents,
} from 'components/FormGenerator/FormGeno';
import { useAuth } from 'hooks/useAuth';
import { useInIframe } from 'hooks/useInIFrame';
import { useUIStore } from 'stores/uiStore';
import { SWRConfig } from 'swr';
import {RNConfig} from './swr/config';

function App() {
    const {logout, id} = useAuth();
    const inIframe = useInIframe();
    const uiState = useUIStore((state) => state);
    useEffect(() => {
        if (inIframe && id) {
            logout();
        }
    }, [inIframe]);
    return (
        <>
            {/* <ColorModeScript
                initialColorMode={theme.initialColorMode}
            /> */}
            <ChakraProvider theme={theme}>
                {/* <SWRConfig value={{...RNConfig({logout, uiState})}}> */}
                    <FormProvider
                        formComponents={formComponents}
                        viewComponents={viewComponents}
                    >
                        <RootRouter />
                    </FormProvider>
                {/* </SWRConfig> */}
            </ChakraProvider>
        </>
    );
}

export default App;
