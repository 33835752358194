import React from 'react';
import Brochure, {printDocument} from './Brochure';
import FunctionDrawer from './FunctionDrawer';
import {useTranslation} from 'react-i18next';
import {useCopyToClipboard} from 'react-use';
import { useAuth } from 'hooks/useAuth';
import { get } from 'lodash';
import useCustomToast from 'hooks/useCustomToast';
import { Button, HStack } from '@chakra-ui/react';

const BrochureDrawer = (props) => {
    const {open, onClose, profile} = props;
    const {t} = useTranslation('app');
    const {isUser} = useAuth();
    const [state, copyToClipboard] = useCopyToClipboard();
    const toast = useCustomToast();
    const link = `${
        isUser ? window.location.origin : `https://www.google.com`
    }?code=${get(profile, 'referralReference')}`;

    const onCopyLink = () => {
        copyToClipboard(link);
        toast.show({title: t('copied')});
    };
    
    return (
        <FunctionDrawer
            open={open}
            onClose={onClose}
            title={t('brochure')}
            actions={
                <HStack alignItems={'center'} justifyContent={'space-between'}>
                    <Button
                        size="sm"
                        onClick={() => printDocument({id: 'brochure'})}
                        variant={'primary'}
                    >
                        {t('download')}
                    </Button>
                    <Button size="sm" onClick={onCopyLink} variant={'primary'}>
                        {t('copyLink')}
                    </Button>
                </HStack>
            }
        >
            <Brochure profile={profile} />
        </FunctionDrawer>
    );
};

export default BrochureDrawer;
