import parseISO from 'date-fns/parseISO';
import {lowerCase, startsWith} from 'lodash';
import {get, pick, split, take, upperCase} from 'lodash';
import {processAddress} from './common';
import {processCompany} from './company';
import {processPerson} from './person';
import {processSales} from './sales';

export const processClient = (rawData) => {
    try {
        const individualDetail = get(rawData, ':individual')
            ? processPerson(get(rawData, ':individual'))
            : null;
        const companyDetail = get(rawData, ':company')
            ? processCompany(get(rawData, ':company'))
            : null;
        const isCompany = Boolean(
            lowerCase(get(rawData, 'clientType')) == 'company',
        );

        const name =
            (isCompany
                ? get(companyDetail, 'name', '')
                : get(individualDetail, 'legalName', '')) ||
            get(rawData, 'name') ||
            get(rawData, 'legalName');
        const shortName = upperCase(
            take(split(name, ' '), 2)
                .map((item) => take(item, 1))
                .join(''),
        );
        const detail = isCompany ? companyDetail : individualDetail;
        const allEmailsOfClient = isCompany
            ? get(companyDetail, 'companyUser', []).map((item) => item.email)
            : [get(individualDetail, 'email')];
        const displayEmail = isCompany
            ? get(companyDetail,'email')
            : get(individualDetail, 'email');
        // const displayEmail = isCompany
        //     ? take(allEmailsOfClient, 3).join(', ') +
        //       (allEmailsOfClient.length > 3
        //           ? `, ${allEmailsOfClient.length - 3}+`
        //           : '')
        //     : get(individualDetail, 'email');
        const brokerDetail = processCompany(get(rawData, ':broker'));
        const brokerManagerDetail = isCompany
            ? get(companyDetail, 'brokerManagerDetail') ||
              get(brokerDetail, 'brokerManagerDetail')
            : get(brokerDetail, 'brokerManagerDetail');
        return {
            email: displayEmail,
            id: get(rawData, 'id'), // client id
            type: get(rawData, 'clientType'),
            individual: get(rawData, 'individual'),
            terms: get(rawData, 'terms'),
            feeLevel: get(rawData, 'feeLevel'),
            regulation: get(rawData, 'regulation'),
            ibLevelType: get(rawData, 'ibLevel'),
            isIB: Boolean(get(rawData, 'ibLevel')),
            isBroker: Boolean(get(rawData, 'isBrokerClient')),
            referralReference: get(rawData, 'referralReference'),
            reference: get(rawData, 'reference'),
            createdAt: get(rawData, 'createdAt'),
            updatedAt: get(rawData, 'updatedAt'),
            country: get(detail, 'region'),
            kycStatus: get(detail, 'kycStatus'),
            amlStatus: isCompany?get(detail,'overallAmlStatus'):get(detail, 'amlStatus'),
            reason: get(detail, 'reason'),
            company: get(detail, 'company'),
            estimate: get(detail, 'estimate'),
            estimateDetail: get(detail, 'estimateDetail'),
            kycExpireDate: get(detail, 'kycExpireDate'),
            kycRenewExpireDate: get(detail, 'kycRenewExpireDate'),
            sale: get(rawData, 'sale'),
            saleDetail: get(rawData, ':sale')
                ? processSales(get(rawData, ':sale'))
                : null,
            feeSetting: get(rawData, ':feeSetting'), // programly linked
            regulationName: get(rawData, ':regulation.name'),
            contactDetail: get(detail, 'contactDetail'),
            broker: get(rawData, 'broker'),
            brokerDetail: processCompany(get(rawData, ':broker')),
            person: get(rawData, ':individual.client'),
            ...processAddress(
                isCompany
                    ? get(detail, ':company.:address')
                    : get(detail, ':individual.:address'),
                isCompany ? 'company' : 'individual',
            ),
            termsDetail: {
                accountTerms: Boolean(get(rawData, ':terms.accountTerms')),
                complaintsProcedures: Boolean(
                    get(rawData, ':terms.complaintsProcedures'),
                ),
                privacyPolicy: Boolean(get(rawData, ':terms.privacyPolicy')),
                signature: get(rawData, ':terms.signature'),
            },
            isCompany,
            shortName,
            name: name,
            allEmailsOfClient,
            displayEmail,
            brokerName:
                get(rawData, ':broker.name') || get(rawData, ':company.name'),
            individualDetail: individualDetail,
            companyDetail: companyDetail,
            rawData: rawData,
            steps: get(detail, 'steps', []),
            status: get(rawData, 'status'),
            clientDetail: {
                name,
                id: get(rawData, 'id'),
                email: displayEmail,
            },
            brokerManagerDetail,
            riskLevel: get(rawData, 'riskLevel'),
            platformRiskLevel: get(rawData, 'platformRiskLevel'),
            brokerManager: get(brokerManagerDetail, 'id'),
            source: get(rawData, 'source'),
            isRegister: Boolean(get(rawData, 'source') === 'register'),
            enableCommission: Boolean(get(rawData, 'enableCommission')),
            enableCommissionStr: get(rawData, 'enableCommission'),
            // receiveEmail: get(rawData, ':individual.receiveEmail'), // need change
            // canViewUserPrivacy: Boolean(get(rawData, 'canViewUserPrivacy')), // need change
            // preference: processPreference(get(rawData, ':preference')),
        };
    } catch (error) {
        console.log(error);
    }
};
